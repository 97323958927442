import { OldRfidCardResponse } from './OldRfidCardResponse';
export interface ClientSignInResponse {
        UserId: string;
        FirstName: string;
        LastName: string;
        CompanyName: string;
        Mobile: string;
        Email: string;
        TIN: string;
        CarPlate: string;
        FirstTimeInputCard: boolean;
        HavingSubscription: boolean;
        IsDeletedCard: boolean;
        IsActiveSub: boolean;
        ToDeactivation: boolean;
        SubID: string | null;
        VisualCardNumber: string;
        EkoCard: string;
        SubscriptionType: SubscriptionTypeEnum | null;
        HasOldCards:boolean | null;
        DeprecatedCards: Array<OldRfidCardResponse> | null;
    }
    export enum SubscriptionTypeEnum {
        ADHOC = 1,
        INSTAPAYMENT = 2,
        PREPAID = 5,
        INSTAPAYMENTVOLVO = 6,
        INSTAPAYMENTPEUGEOT = 7,
        INSTAPAYMENTDS = 8,
        EKOCARD = 9
    }
