<div class="container">
    <div class="row margin-top">
        <!-- Title And Line -->
        <div class="col-12" id="changePersonalForm">
            <h1 class="title-change">{{'change_details' | translate}}</h1>
            <div class="line"></div>
        </div>
        <!-- Change Details Form -->
        <form class="row" [formGroup]="changePersonalFormGroup" *ngIf="dataUserInfos" (ngSubmit)="checkMobileNumber()">
            <!-- First Name Input -->
            <div class="col-md-6">
                <p>{{'contact_form_first_name' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="25" formControlName="firstName">
                    <mat-error *ngIf="changePersonalFormGroup.controls['firstName'].invalid">{{getErrorMessageOfFirstName() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- Last Name Input -->
            <div class="col-md-6 mobile-top">
                <p>{{'contact_form_last_name' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="25" formControlName="lastName">
                    <mat-error *ngIf="changePersonalFormGroup.controls['lastName'].invalid">{{getErrorMessageOfLastName() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- Phone number Input -->
            <div class="col-md-6 mt-4">
                <p>{{'contact_form_phone_number' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="13" formControlName="mobileNumber">
                    <mat-error *ngIf="changePersonalFormGroup.controls['mobileNumber'].invalid">{{getErrorMessageOfMobilePhoneNumber() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- Afm Input -->
            <div class="col-md-6 mt-4" *ngIf="subscriptionType != 9">
                <p>{{'step_2_afm' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" maxlength="9" matInput formControlName="afm">
                    <mat-error *ngIf="changePersonalFormGroup.controls['afm'].invalid">{{getErrorMessageOfAFM() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- Car Plate Input -->
            <!-- The anchor point to autofocus after the migration button has been pressed -->
            <div class="col-md-6 mt-4" id="anchorPoint">
                <p>{{'step_2_car_plate' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="10" formControlName="carPlate">
                    <mat-error *ngIf="changePersonalFormGroup.controls['carPlate'].invalid">{{getErrorMessageOfCarPlate() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- Buttons -->
            <div class="col-12 button-position mb-5">
                <!-- Loader -->
                <div class="loader-position" *ngIf="displayLoader">
                    <mat-spinner [diameter]="70"></mat-spinner>
                </div>
                <!-- Error Or Succses Massage -->
                <h3 class="call-massage" [ngClass]="{'greenText' : statusInfo , 'redText': !statusInfo }">
                    {{callMassageInfo | translate}}
                </h3>
                <!-- Submit -->
                <div class="btn next-btn">
                    <button 
                    type="submit"
                    [ngClass]="{'submit':changePersonalFormGroup.valid,'inactive-submit':changePersonalFormGroup.invalid}"
                    [disabled]="changePersonalFormGroup.invalid" >{{'submit_finish' | translate}}</button>
                </div>
                <!-- Cancel -->
                <div class="btn back-btn mobile-display">
                    <button type="button" class="btn back-btn" [disabled]="disableButton"
                        [routerLink]="'/account/myAcount'">{{'cancel_btn' |
                        translate}}</button>
                </div>
            </div>
        </form>
        <!---------------------- Change RFID Send Card Location  -------------------->
        <div class="col-12">
            <h1 class="title-change">{{'change_rfid_send_card_location_title' | translate}}</h1>
            <div class="line"></div>
        </div>
        <!-- Change RFID Send Card Location Form -->
        <form class="row" [formGroup]="changeRFIDSendCardLocation" *ngIf="dataUserInfos" (ngSubmit)="submitRFIDCardSendDetails()">
            <!-- RFID SEND CARD FullName Input -->
            <div class="col-md-6">
                <p>{{'change_rfid_send_card_location_fullName' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="50" formControlName="rfidCardSendLocationFullName">
                    <mat-error *ngIf="changeRFIDSendCardLocation.controls['rfidCardSendLocationFullName'].invalid">{{getErrorMessageOfFullName() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- RFID SEND CARD Address Input -->
            <div class="col-md-6 mobile-top">
                <p>{{'change_rfid_send_card_location_address' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="50" formControlName="rfidCardSendLocationAddress">
                    <mat-error *ngIf="changeRFIDSendCardLocation.controls['rfidCardSendLocationAddress'].invalid">{{getErrorMessageOfAddress() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- RFID SEND CARD AddressNumber Input -->
            <div class="col-md-6 mt-4">
                <p>{{'change_rfid_send_card_location_addressNumber' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="7" formControlName="rfidCardSendLocationAddressNumber">
                    <mat-error *ngIf="changeRFIDSendCardLocation.controls['rfidCardSendLocationAddressNumber'].invalid">{{getErrorMessageOfAddressNumber() | translate}} </mat-error>
                </mat-form-field>
            </div>
             <!-- RFID SEND CARD City Input -->
            <div class="col-md-6 mt-4">
                <p>{{'change_rfid_send_card_location_city' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" maxlength="50" matInput formControlName="rfidCardSendLocationCity">
                    <mat-error *ngIf="changeRFIDSendCardLocation.controls['rfidCardSendLocationCity'].invalid">{{getErrorMessageOfCity() | translate}} </mat-error>
                </mat-form-field>
            </div>
               <!-- RFID SEND CARD PostalCode Input -->
            <div class="col-md-6 mt-4">
                <p>{{'change_rfid_send_card_location_postalCode' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="text" matInput maxlength="5" minlength="5" formControlName="rfidCardSendLocationPostalCode">
                    <mat-error *ngIf="changeRFIDSendCardLocation.controls['rfidCardSendLocationPostalCode'].invalid">{{getErrorMessageOfPostalCode() | translate}} </mat-error>
                </mat-form-field>
            </div>
            <!-- Buttons -->
            <div class="col-12 button-position mb-5">
                <!-- Loader -->
                <div class="loader-position" *ngIf="displayLoader">
                    <mat-spinner [diameter]="70"></mat-spinner>
                </div>
                <!-- Error Or Succses Massage -->
                <h3 class="call-massage" [ngClass]="{'greenText' : statusInfo , 'redText': !statusInfo }">
                    {{callMessageRFIDDetails | translate}}
                </h3>
                <!-- Submit -->
                <div class="btn next-btn">
                    <button 
                    type="submit"
                    [ngClass]="{'submit':changeRFIDSendCardLocation.valid && changePersonalFormGroup.valid,'inactive-submit': changeRFIDSendCardLocation.invalid || changePersonalFormGroup.invalid}"
                    [disabled]="changeRFIDSendCardLocation.invalid || changePersonalFormGroup.invalid" >{{'submit_finish' | translate}}</button>
                </div>
                <!-- Cancel -->
                <div class="btn back-btn mobile-display">
                    <button class="btn back-btn" [disabled]="disableButton"
                        [routerLink]="'/account/myAcount'">{{'cancel_btn' |
                        translate}}</button>
                </div>
            </div>
        </form>

        <!---------------------- Change Password  -------------------->
        <!-- Title And Line -->
        <div class="col-12">
            <h1 class="title-change">{{'change_password' | translate}}</h1>
            <div class="line"></div>
        </div>
        <!-- Form Group -->
        <div class="row" [formGroup]="changePasswordFormGroup" *ngIf="dataUserInfos">
            <!-- Current Password Input -->
            <div class="col-md-6">
                <p>{{'current_password' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="password" matInput maxlength="20"
                        [type]="hideOld  ? 'password' : 'text'" formControlName="oldPassword">
                    <!-- Eye -->
                    <span class="eye" mat-icon-button matSuffix (click)="hideOld  = !hideOld "
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                </mat-form-field>
            </div>
            <!-- Empty Div -->
            <div class="col-md-6"></div>
            <!-- New Password Input -->
            <div class="col-md-6  mt-4">
                <p>{{'new_password' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="password" matInput maxlength="16"
                        [type]="hide ? 'password' : 'text'" formControlName="password">
                    <!-- Eye -->
                    <span class="eye" mat-icon-button matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                </mat-form-field>
                <div class="strong-pass">
                    <mat-password-strength [password]="changePasswordFormGroup.controls.password.value">
                    </mat-password-strength>
                </div>
                <!-- Password Massage Text -->
                <h6 class="password-massage" [innerHTML]="'register_massage_strong_password' | translate"></h6>
            </div>
            <!-- Confirm New Password Input -->
            <div class="col-md-6 mt-4">
                <p>{{'new_password_verification' | translate}}</p>
                <mat-form-field class="div-pos" appearance="fill">
                    <!-- Input Leabel -->
                    <input class="input-size" type="password" matInput maxlength="16"
                        [type]="hideVerification  ? 'password' : 'text'" formControlName="passwordVerification">
                    <!-- Eye -->
                    <span class="eye" mat-icon-button matSuffix (click)="hideVerification  = !hideVerification "
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hideVerification ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                </mat-form-field>
            </div>
            <!-- Buttons -->
            <div class="col-12 button-position">
                <!-- Loader -->
                <div class="loader-position">
                    <mat-spinner *ngIf="displayLoaderPassword" [diameter]="70"></mat-spinner>
                </div>
                <!-- Error Or Succses Massage -->
                <h3 class="call-massage" [ngClass]="{'greenText' : statusPassword , 'redText': !statusPassword }">
                    {{callMassagePassword | translate}}
                </h3>
                <!-- Submit -->
                <div class="btn next-btn">
                    <button class="submit" [disabled]="disableButton" (click)="checkPassword()">{{'submit_finish' |
                        translate}}</button>
                </div>
                <!-- Cancel -->
                <div class="btn back-btn mobile-display">
                    <button class="btn back-btn" [disabled]="disableButton"
                        [routerLink]="'/account/myAcount'">{{'cancel_btn' |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>