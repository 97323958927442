<div @fade *ngIf="showBanner" class="static-slider3 scroll-color">
  <div class="static-slider-content">
      <!-- Row  -->
          <div class="static-slider-content--div">    
            <!--  text-center -->
            <h1 class="title">
              <div>
                <label>{{"migration_banner_first_part" | translate}}</label>
                <!-- <label class="font-weight-bold">{{"migration_banner_second_part" | translate}}</label>,  -->
                <!-- <label class="font-weight-bold" style="cursor: pointer; margin-bottom: 0 !important;" [innerHTML]="'migration_banner_second_part' | translate"></label> -->
                <button type="button" class="btn font-weight-bold" style="cursor: pointer; margin-bottom: 0 !important; color: blue;padding: 0;" (click)="navigateToVerifyDetailsRoute()">
                  <label style="cursor: pointer;">
                    {{'migration_banner_second_part' | translate}}
                    </label>
                  </button>
              </div>
              <!-- // "migration_banner_second_part": " <label><a href='http://localhost:4200/new-rfid'>Read more here</a></label>", -->

                <!-- <span class="text-success-gradiant font-weight-bold typewrite" 
                  data-period="2000" 
                  data-type='[ "Photoshop", "Web Application", "Web Designing", "Web Development" ]'>
                </span> -->
              </h1>
              <!-- <a class="btn btn-success-gradiant btn-md text-white border-0 mt-3" href="">
                <span>Checkout My Work</span>
              </a> -->
      </div>
      <div class="position-button">        
        <button (click)="hideBanner()" style="height: inherit;" 
        class="close" 
        mat-button><img src="assets/images/x.png"/></button>
      </div>
  </div>
</div> 
