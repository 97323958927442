import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BannerFeatureGuard implements CanActivate {
  private readonly routeToBeChecked:string = "informational";
  constructor(
    public router: Router,
  ) {}
  canActivate(route:ActivatedRouteSnapshot,state:RouterStateSnapshot) :  boolean {
    const fullUrl: string = state.url;
    const lastSegment = fullUrl.split('/').pop();
    if(!lastSegment){
        return true;
    }
    if(lastSegment !== this.routeToBeChecked)
      return true;
    
    const allowBannerRouting = environment.enableBannerFeature;
    if(allowBannerRouting){
        return true;
    }
    else
    {
        this.router.navigate(['/']);
        return false;
    }
  }
}
