import {animate, AnimationMetadata, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations';

// export function getLoaderAnimation(
//   triggerName: string,
//   fadeInTiming: string | number = '200ms',
//   fadeOutTiming: string | number = '200ms',
// //   customAnimationData?: { stateName: string, timing: string }
//   ):
//   AnimationTriggerMetadata 
//   {
//   const transitions = new Array<AnimationMetadata>();
// //   if (customAnimationData !== null && customAnimationData !== undefined) {
// //     transitions.push(
// //       transition(`void => ${customAnimationData.stateName}`, [
// //         style({opacity: 0}),
// //         animate(customAnimationData.timing, style({opacity: 1}))
// //       ])
// //     );
// //   }
//   transitions.push(
//     transition(
//         // 'void => *'
//          ':enter'
//         , [
//       style({
//         opacity: 0
//         // ,transform: 'translateY(-100%)'
//         }),
//       animate(fadeInTiming, style({
//         transform: 'translateY(0%)'
//         // ,opacity: 1
//     }))
//     ]),
//     transition(
//         // '* => void',
//          ':leave',
//          [
//       style({
//         // transform: 'translateY(0%)',
//         opacity: 1
//          }),
//       animate(fadeOutTiming, style({
//         // transform: 'translateY(-100%)',
//         opacity: 0
//     }))
//     ])
//   );
//   return trigger(triggerName, [...transitions]);
// }

export function fadeAnimation(triggerName: string, options: any): AnimationTriggerMetadata {
    return trigger(triggerName, [
      transition(':enter', [
        style({ opacity: '0' ,transform: 'translateY(-100%)' }),
        animate(`${options.fadeInDuration} ease-out`, style({ opacity: '1',transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        style({ opacity: '1',transform: 'translateY(0%)' }),
        animate(`${options.fadeOutDuration} ease-out`, style({ opacity: '0' ,transform: 'translateY(-100%)'})),
      ]),
    ])
  }
  
  export function fadeInAnimation(triggerName: string, options: any): AnimationTriggerMetadata {
    return trigger(triggerName, [
      transition(':enter', [
        style({ opacity: '0' }),
        animate(`${options.fadeInDuration} ease-out`, style({ opacity: '1' })),
      ])
    ])
  }
  
  export function fadeOutAnimation(triggerName: string, options: any): AnimationTriggerMetadata {
    return trigger(triggerName, [
      transition(':leave', [
        style({ opacity: '1' }),
        animate(`${options.fadeOutDuration} ease-out`, style({ opacity: '0' })),
      ]),
    ])
  }