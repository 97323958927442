<!-- Dialog Image Bacground -->
<div mat-dialog-title>
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" [disabled]="disableButton" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
    <!-- Logo Image -->
    <div class="position-logoImage">
        <img src="assets/images/logoSignIn.png" />
    </div>
</div>
<!-- Dialog Content -->
<div mat-dialog-content class="text-center mt-5">
    <!-- Dept Text -->
    <p class="text">{{'pay_dept_text' | translate}} {{deptTotalPrice}} {{'pay_dept_text_euro' | translate}}</p>
    <!-- Buttons -->
    <button class="submit" [disabled]="disableButton" (click)="payDept()">{{'pay_dept_button' | translate}}</button>
    <button class="cancel ms-5" [disabled]="disableButton" mat-dialog-close mat-button>{{'cancel_btn' |
        translate}}</button>
    <!-- Error Massage -->
    <div class="pt-3">
        <p class="error-massage">{{errorMassage | translate}}</p>
        <p class="succses-massage">{{succsesMassage | translate}}</p>
    </div>
    <!-- Loader -->
    <div class="loader-position">
        <mat-spinner class="loader" [diameter]="70" *ngIf="displayLoader"></mat-spinner>
    </div>
</div>