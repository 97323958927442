import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/services/requestService/request.service';
import { take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MapSandbox {
  _data$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private requestService: RequestService) {}

  get _data(): Observable<any> {
    this._data$.asObservable().subscribe((data) => {
      console.log('inside-get-data', data);
    });
    return this._data$.asObservable();
  }
  getPoints(): void {
    const req = {
      url: '/main_service/api/infoinit/charging-locations',
    };
    this.requestService
      .mainGetRequest(req)
      .pipe(take(1))
      .subscribe((resp) => {
        console.log(resp._value.Data);
        if (resp && resp._value.Status && resp._value.Data) {
          this._data$.next(resp._value.Data);
        } else {
          this._data$.next(undefined);
        }
      });
    //return resp && resp._value.Status && resp._value.Data ? resp._value.Data.ChargingPoints : undefined;

    //Get All Array From Charging Points
    //this.points = resp._value.Data.ChargingPoints;
    //New Array Where Return My Object With Data
    // this.markers = this.points.map((point: any) => {
    //   return {
    //     lat: point.X,
    //     lng: point.Y,
    //     label: {
    //       text: ' ',//String(point.Fusions),
    //       color: '#ffffff',
    //       fontWeight: 'bold',
    //     },
    //     draggable: false,
    //     icon: {
    //       url: point.Active ? './assets/images/map/pinComingSoon.svg' : './assets/images/map/pinComingSoon.svg',
    //       scaledSize: {
    //         width: 40,
    //         height: 40
    //       }
    //     }
    //   }
    // }
  }

  //}
  //);
}
