import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'openingTimes'
})
export class OpeningTimesPipePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return `location_printer.${value}`;
  }

}
