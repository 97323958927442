<div class="container" *ngIf="!displayStripe && !displayEKO">
    <div class="row">
        <div class="col-12">
            <!-- Title -->
            <h1 class="steps-title">{{'steps_addNumber_sms_varification' | translate}}</h1>
            <!-- Number And Line -->
            <div class="d-flex">
                <button class="btn circle-step">3</button>
                <div class="line"></div>
            </div>
        </div>
        <!-- Form -->
        <div class="col-12 left-position" [formGroup]="phoneNumberFormGroup">
            <!--  Phone Number Input -->
            <div class="mt-4" *ngIf="!displayInputSMS">
                <p class="leabel-name">{{'step_3_phone_number' | translate}}</p>
                <mat-form-field class="leable-size" appearance="fill">
                    <input class="input-size" type="text" maxlength="13" matInput formControlName="phoneNumber">
                </mat-form-field>
            </div>
            <!-- SMS Verification Div -->
            <div class="smsDiv" *ngIf="displayInputSMS">
                <!-- Text -->
                <p class="sms-text">{{'sms_verification_text' | translate}}{{sentPhoneNumber}}</p>
                <!-- Input 1-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_1" class="input-size" type="text" maxlength="1" matInput autofocus
                        formControlName="smsVerificationNumber_1" (input)="numberInput1()">
                </mat-form-field>
                <!-- Input 2-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_2" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_2" (input)="numberInput2()"
                        (keydown)="onKeydown1($event)">
                </mat-form-field>
                <!-- Input 3-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_3" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_3" (input)="numberInput3()"
                        (keydown)="onKeydown2($event)">
                </mat-form-field>
                <!-- Input 4-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_4" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_4" (input)="numberInput4()"
                        (keydown)="onKeydown3($event)">
                </mat-form-field>
                <!-- Input 5-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_5" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_5" (input)="numberInput5()"
                        (keydown)="onKeydown4($event)">
                </mat-form-field>
                <!-- Input 6-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_6" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_6" (keydown)="onKeydown5($event)">
                </mat-form-field>
                <!-- Button -->
                <div class="mt-5">
                    <!-- Resent SMS Button -->
                    <div class="resent-position">
                        <button class="resent-button" [disabled]="disableButton"
                            (click)="sendNumber(true)">{{'sms_resent' | translate}}</button>
                    </div>
                    <!-- Send Data Button -->
                    <button class="submit mobile-submit" [disabled]="disableButton"
                        (click)="sendSmsCode()">{{'contact_form_button' |
                        translate}}</button>
                </div>
                <!-- Loader -->
                <div id="loaderDiv" class="loader-position pt-5" *ngIf="displayLoaderSms">
                    <mat-spinner class="loader" [diameter]="70"></mat-spinner>
                </div>
                <!-- Error Massage -->
                <p class="error-massage-local pt-4" *ngIf="errorMassage">{{errorMassage | translate}}</p>
            </div>
            <!-- Buttons For Send Phone Number-->
            <div [ngClass]="{'button-top' : !displayInputSMS, 'button-top-sms-div' : displayInputSMS }">
                <!-- Loader -->
                <div id="loaderDiv" class="loader-position pt-5" *ngIf="displayLoader">
                    <mat-spinner class="loader" [diameter]="70"></mat-spinner>
                </div>
                <!-- Button Next -->
                <div class="text-center" *ngIf="!displayInputSMS">
                    <!-- Error Massage -->
                    <p id="errorMassage" class="error-massage">{{errorMassage | translate}}</p>
                    <!-- Submit Button -->
                    <button class="submit" [disabled]="disableButton"
                        (click)="sendNumber(false)">{{'contact_form_button' | translate}}</button>
                </div>
                <!-- Button Back -->
                <div class="text-end mt-4">
                    <button class="back-route-button" [disabled]="disableButton" (click)="backButton()">{{'button_back'
                        | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Bank Card Component Stripe -->
<app-step4-add-bank-card *ngIf="displayStripe" [formDataResponse]="formDataResponse"></app-step4-add-bank-card>

<!-- Add EKO Card Component -->
<app-step4-add-eko-bp-card *ngIf="displayEKO" [formDataResponseEKO]="formDataResponse"></app-step4-add-eko-bp-card>