import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestService } from '../requestService/request.service';
import { APIResult } from 'src/app/interfaces/DTO/APIResult';
import { SendCardDetails } from 'src/app/interfaces/Responses/SendCardDetails';
import { ClientAuthStateResponse } from 'src/app/interfaces/Responses/ClientAuthStateResponse';
import { ClientSignInResponse } from 'src/app/interfaces/Responses/ClientSignInResponse';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: any;
  private rfidSendCardDetails:SendCardDetails | null = null;
  getLoggedInStatus = new Subject();
  
  constructor(private requestService: RequestService) { }

  //Set The Info Response
  setUser(data: ClientSignInResponse | null) {
    this.user = data;
  };

  //Get The Response User
  getUser() {
    return this.user;
  };

  setRfidSendCardDetails(object:SendCardDetails | null){
    this.rfidSendCardDetails = object;
  }
  getRfidSendCardDetails(){
    return this.rfidSendCardDetails;
  }
  // Reset User
  resetUser() {
    this.user = null;
  };

  // Check If The User Is Logged In Or Not
  isLoggedIn(): boolean {
    return this.user ? true : false;
  }

  // Function Which Is Called On Logout Or If The User Is Unauthorized
  onLogout() {
    this.resetUser();
    this.getLoggedInStatus.next();
  }

  // Get User Info
  getUserInfo() {
    return new Promise(resolve => {
      let req = {
        url: "/main_service/api/InfoInit/infos"
      };
      this.requestService.mainGetRequest(req).subscribe(resp => {
        let response:APIResult<ClientAuthStateResponse> = resp._value
        if(!response || !response.Status || !response.Data){
          this.setUser(null);
          resolve(null);
        }
        if (response.Data.ClientDetails) {
          this.setUser(response.Data.ClientDetails);
        } else {
          this.setUser(null);
        }
        resolve(resp);
      });
    });
  }

  getRFIDSendCardInformation():Promise<APIResult<SendCardDetails>>{
    return new Promise((resolve)=>{
      let endpoint = {
        url : "/main_service/api/InfoInit/infos-postal_data"
      }
      this.requestService.mainGetRequest(endpoint).subscribe((internalResponse:any)=>{
        const response:APIResult<SendCardDetails> = internalResponse._value;
        if(!response || !response.Status || !response.Data){
          this.setRfidSendCardDetails(null);
        }else{
          this.setRfidSendCardDetails(response.Data);
        }
        resolve(response);
      });
    });
  }


}