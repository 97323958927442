<div class="container">
    <div class="row">
        <div class="col-12">
            <!-- Title -->
            <h1 class="steps-title">{{'steps_addNumber_sms_varification' | translate}}</h1>
            <!-- Line -->
            <div class="d-flex">
                <div class="line"></div>
            </div>
        </div>
        <!-- Form -->
        <div class="col-12 left-position" [formGroup]="phoneNumberFormGroup">
            <!-- SMS Verification Div -->
            <div class="smsDiv">
                <!-- Text -->
                <p class="sms-text">{{'sms_verification_text' | translate}}{{newPhoneNumber}}</p>
                <!-- Input 1-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_1" class="input-size" type="text" maxlength="1" matInput autofocus
                        formControlName="smsVerificationNumber_1" (input)="numberInput1()">
                </mat-form-field>
                <!-- Input 2-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_2" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_2" (input)="numberInput2()"
                        (keydown)="onKeydown1($event)">
                </mat-form-field>
                <!-- Input 3-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_3" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_3" (input)="numberInput3()"
                        (keydown)="onKeydown2($event)">
                </mat-form-field>
                <!-- Input 4-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_4" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_4" (input)="numberInput4()"
                        (keydown)="onKeydown3($event)">
                </mat-form-field>
                <!-- Input 5-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_5" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_5" (input)="numberInput5()"
                        (keydown)="onKeydown4($event)">
                </mat-form-field>
                <!-- Input 6-->
                <mat-form-field class="sms-ver-width" appearance="fill">
                    <input id="number_6" class="input-size" type="text" maxlength="1" matInput
                        formControlName="smsVerificationNumber_6" (keydown)="onKeydown5($event)">
                </mat-form-field>
                <!-- Button -->
                <div class="mt-5">
                    <!-- Loader -->
                    <div class="loader-position mt-5" *ngIf="displayLoader">
                        <mat-spinner class="loader" [diameter]="70"></mat-spinner>
                    </div>
                    <!-- Error Massage -->
                    <p class="error-massage pt-4" *ngIf="errorMassage">{{errorMassage | translate}}</p>
                    <!-- Resent SMS Button -->
                    <div class="resent-position">
                        <button class="resent-button" [disabled]="disableButton" (click)="resendNumber()">{{'sms_resent'
                            | translate}}</button>
                    </div>
                    <!-- Send Data Button -->
                    <button class="submit mobile-submit" [disabled]="disableButton"
                        (click)="sendSmsCode()">{{'contact_form_button' |
                        translate}}</button>
                </div>
            </div>
            <!-- Button Back -->
            <div class="text-end">
                <button class="back-route-button" [disabled]="disableButton" (click)="backButton()">{{'button_back'
                    | translate}}</button>
            </div>
        </div>
    </div>
</div>