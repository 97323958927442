import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestService } from 'src/app/services/requestService/request.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.less']
})
export class ForgotPasswordDialogComponent implements OnInit {
  forgotForm: FormGroup;
  displayLoader: boolean = false;
  errorMassage: any;
  succsesMassage: any;
  disableButton: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    private loginFormBuilder: FormBuilder,
    private requestService: RequestService,
    private translate: TranslateService,
    private errorCaseService: ErrorCasesService) {
    this.forgotForm = this.loginFormBuilder.group({
      forgotEmail: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void { }

  //Check If Leabel Is Valid
  checkEmail() {
    this.errorMassage = '';
    if (!this.forgotForm.valid) {
      this.errorMassage = 'forgot_password_error';
    }
    else {
      this.displayLoader = true;
      this.disableButton = true;
      this.sendEmail();
    }
  }

  //Send Email & Language Request
  sendEmail() {
    const forgotPassRequest = {
      url: '/main_service/api/AuthClient/reset/password',
      req: {
        "Email": this.forgotForm.controls.forgotEmail.value,
        "Language": this.translate.currentLang
      }
    }
    this.requestService.mainPostRequest(forgotPassRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.displayLoader = false;
        this.disableButton = false;
        this.succsesMassage = 'forgot_password_succses_massage';
      }
      else {
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

}