<!-- Dialog Image Bacground -->
<div mat-dialog-title>
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
    <!-- Logo Image -->
    <div class="position-logoImage">
        <img src="assets/images/logoSignIn.png" />
    </div>
</div>
<!-- Dialog Content -->
<div mat-dialog-content class="text-center mt-5 mb-5">
    <!-- Text -->
    <div class="alignment-text">
        <p class="main-text">{{'pay_dept_EKO_card_main_text' | translate}}</p>
    </div>
    <!-- Buttons -->
    <div class="buttons">
        <!-- EKO Card Button -->
        <button class="submit button-range" (click)="openEkoCardDialog()">{{'pay_dept_EKO_card' | translate}}</button>
        <!-- Bank Card Button -->
        <button class="submit" (click)="navigateBankCardPage()">{{'pay_dept_EKO_bank_card' | translate}}</button>
    </div>
</div>