<!-- Dialog Image Bacground -->
<div mat-dialog-title>
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
    <!-- Logo Image -->
    <div class="position-logoImage">
        <img src="assets/images/succses.png" />
    </div>
</div>
<!-- Dialog Content -->
<div mat-dialog-content class="massage">
    <h1>{{'payment_change_success' | translate}}</h1>
</div>