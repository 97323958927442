<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <!-- Main Image -->
            <div>
                <img class="mt-5" src="assets/images/logoSignIn.png">
            </div>
            <!-- Succses And Text -->
            <div class="mt-5">
                <img class="succses-width" src="assets/images/succses.png">
                <p [innerHTML]=" welcomeText | translate"></p>
            </div>
            <!-- Button Profile -->
            <div class="mt-5">
                <button class="submit" (click)="myAcountRoute()">{{'my_profile_title' | translate}}</button>
            </div>
        </div>
    </div>
</div>