import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { PayWithEKOCardDeptDialogComponent } from '../pay-with-eko-card-dept-dialog/pay-with-eko-card-dept-dialog.component';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-select-pay-eko-card-dept-dialog',
  templateUrl: './select-pay-eko-card-dept-dialog.component.html',
  styleUrls: ['./select-pay-eko-card-dept-dialog.component.less']
})
export class SelectPayEKOCardDeptDialogComponent implements OnInit {
  screenWidth: any;

  constructor(public dialogRef: MatDialogRef<SelectPayEKOCardDeptDialogComponent>,
    public dialog: MatDialog,
    public router: Router,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  //Listener For Screen Width
  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenWidth = window.innerWidth;
  }

  //Open Pay Dept EKO Card Dialog
  openEkoCardDialog() {
    const matDialogId = this.dialog.getDialogById('payWithEKOCard');
    if (!matDialogId) {
      const dialogRef = this.dialog.open(PayWithEKOCardDeptDialogComponent, { id: 'payWithEKOCard', disableClose: true });
      dialogRef.afterClosed().subscribe(result => {
        if (result.data.success) {
          this.dialogRef.close();
        }
      });
    }
  }

  //Navigate Bank Card Page
  navigateBankCardPage() {
    this.dialogRef.close();
    this.dataService.setGurdParameter(true);
    this.router.navigate(["/account/myAcount/paymentDept"]);
  }

}