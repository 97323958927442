import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ClientChangePostalDetails } from 'src/app/interfaces/Requests/ClientChangePostalDetails';
import { RedirectionRoute } from 'src/app/interfaces/redirectionRoute';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public selectedCardStep1Param: any;
  public displayButtons: any;
  private step2componentMethodCallSource = new Subject();
  step2ComponentMethodCallSourceObservable = this.step2componentMethodCallSource.asObservable();
  public objectValues: any;
  public mobileToken: any;
  public smsFinalObject: any;
  public gurdParam: boolean = false;
  private rfidDetails:ClientChangePostalDetails | null=null;
  // migrationRedirection: boolean = false;
  private redirectionRoute:RedirectionRoute | null = null;
  constructor() { }

  //Set Selected Parameter For Card In Step-1 
  setSelectedCard(card: string) {
    this.selectedCardStep1Param = card;
  }

  //Get The Selected Parameter For Card In Step-1 
  getSelectedCard() {
    return this.selectedCardStep1Param;
  }

  //Set A Parameter From Welcome Component For Display Buttons
  setParamButtons(param: boolean) {
    if (param) {
      this.displayButtons = param;
    }
    else if (param == undefined) {
      this.displayButtons = false;
    }

  }

  //Get The Value From Parameter For Welcome Component
  getParamButtons() {
    return this.displayButtons;
  }

  //Function For Display Previous Component In Step-3
  displayStep2ComponentMethod() {
    this.step2componentMethodCallSource.next();
  }

  //Function For Set Inputs Values And Sms Verification Response Token For Change Details
  setValuesAndToken(objValues: any, tokenParam: any) {
    this.objectValues = objValues;
    this.mobileToken = tokenParam;
  }

  //Get The Object
  getObjectValues() {
    return this.objectValues;
  }

  //Get The Token
  getMobileToken() {
    return this.mobileToken;
  }

  //Set The Final Object With Final Token
  setSmsFinalObject(finalObj: any) {
    this.smsFinalObject = finalObj;
  }

  //Get Final Object With Final Token
  getSmsFinalObject() {
    return this.smsFinalObject;
  }

  //For Payment Dept Route Page
  setGurdParameter(param: boolean) {
    this.gurdParam = param;
  }

  //Get The Parameter For Payment Dept Route Page
  getGurdParameter() {
    return this.gurdParam;
  }

  setSendCardDetails(rfidDetails:ClientChangePostalDetails){
    this.rfidDetails = rfidDetails;
  }

  getRFIDDetails(){
    return this.rfidDetails;
  }
  setRedirectionRoute(val:RedirectionRoute | null){
    this.redirectionRoute = val;
  }
  getRedirectionRoute(){
    return this.redirectionRoute;
  }
}
