import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from 'src/app/services/dialogService/dialog.service';
import { SessionStorageService, BannerState } from 'src/app/services/sessionStorage.service.ts/sessionStorage.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/dataService/data.service';
import { RedirectionRoute } from 'src/app/interfaces/redirectionRoute';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})

export class HomeComponent implements OnInit{
  currentLang: any;
  pageYoffset = 0;
  idVideo: any;
  public screenWidth: any = window.innerWidth;
  move_left: boolean = false;
  scrollEnd: boolean = false;
  constructor(
    private scroll: ViewportScroller,
    private translate: TranslateService,
    public sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private router:Router,
    private dataService:DataService,
    private sessionStorageService:SessionStorageService) { }
  
  ngOnInit(): void {
    //Give The Current Lang Then Load
    this.initPage(this.translate.currentLang);
    //Give The Lang Then Change
    this.translate.onLangChange.subscribe(obj => {
      this.initPage(obj.lang);
    });
    this.sessionStorageService.determine(BannerState.OPEN);
  }

  
  //Listener For Scroll
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.pageYoffset = window.pageYOffset;

    //For Scroll End 
    if ((document.body.clientHeight + window.scrollY + 100) >= document.body.scrollHeight) {
      this.scrollEnd = true;
    } else {
      this.scrollEnd = false;
    }
  }

  //Listener For Screen Width
  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenWidth = window.innerWidth;
  }

  //Load When The Page Init And When The User Change Language
  initPage(lang: any) {
    this.currentLang = lang;
    this.move_left = lang == 'el' ? true : false;
    this.idVideo = this.sanitizer.bypassSecurityTrustResourceUrl(lang == 'en' ? 'https://www.youtube.com/embed/N_BmHnkM5YA' : 'https://www.youtube.com/embed/8gvE7mMKg80');
  }

  //Scroll Page To Top
  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  //Function To Scroll Under Anchor Div Section 
  scrollFn(anchor: string): void {
    this.scroll.scrollToAnchor(anchor)
  }
  navigateToVerifyDetailsRoute(){
    const redirectionRoute:RedirectionRoute={
        route : 'account/changePersonalInfo',
        navigationParams:{ queryParams: { link: 'anchor'} }
    };
    this.dataService.setRedirectionRoute(redirectionRoute);
    this.sessionStorageService.determine(BannerState.CLOSE);
    this.router.navigate([redirectionRoute.route],redirectionRoute.navigationParams);
  }
  //Open Register Dialog
  openRegisterDialog() {
    this.dialogService.openRegisterDialogService();
  }

}