import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.less']
})
export class WelcomeComponent implements OnInit {
  welcomeText: string = '';
  constructor(public router: Router, private dataService: DataService) { }

  ngOnInit(): void {
    const selectedCardParameter = this.dataService.getSelectedCard();
    if (selectedCardParameter === 'NormalCard' || selectedCardParameter === 'EkoBpCard') {
      this.welcomeText = 'welcome_text';
    }
    else if (selectedCardParameter === 'VolvoCard' || selectedCardParameter === 'PeugeutCard' || selectedCardParameter === 'DSCard') {
      this.welcomeText = 'welcome_text_volvo_peugeot';
    }
  }

  //Button Action To Navigate In myAcount And Set Parameter In dataService
  myAcountRoute() {
    this.dataService.setParamButtons(true);
    this.router.navigate(['/account/myAcount']);
  }


}