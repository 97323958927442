import { BannerState, SessionStorageService } from 'src/app/services/sessionStorage.service.ts/sessionStorage.service';
import { DataService } from 'src/app/services/dataService/data.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionRoute } from 'src/app/interfaces/redirectionRoute';
// Component currently unused because migration perdiod ended
@Component({
  selector: 'app-migration-info',
  templateUrl: './migration-info.component.html',
  styleUrls: ['./migration-info.component.less']
})
export class MigrationInfoComponent implements OnInit {

  navElement: HTMLElement | undefined;
  constructor(private router:Router, private dataService:DataService, private sessionStorageService:SessionStorageService ) {
    this.sessionStorageService.determine(BannerState.CLOSE);
    this.navElement = document.getElementById('navbar') as HTMLElement
  }

  ngOnInit() {
    if(this.navElement && this.navElement.classList.contains('fixed-top-color')){
      this.navElement.classList.remove('fixed-top-color');
    }
  }
  navigateToVerifyDetailsRoute(){
    const redirectionRoute:RedirectionRoute ={
      route : 'account/changePersonalInfo',
      navigationParams:{ queryParams: { link: 'anchor'} }
    } ;
    this.dataService.setRedirectionRoute({
    route : redirectionRoute.route
    });
    this.router.navigate([redirectionRoute.route],redirectionRoute.navigationParams);
  }
  navigateToProfileAnchor(){
    const redirectionRoute:RedirectionRoute ={
      route : 'account/myAcount',
      navigationParams:{ queryParams: { 'link': 'bottom'}}
    } ;
    this.dataService.setRedirectionRoute(redirectionRoute);
    this.router.navigate([redirectionRoute.route],redirectionRoute.navigationParams );
  }
  @HostListener('window:scroll', ['$event'])
  removeClass(){
    if(this.navElement && this.navElement.classList.contains('fixed-top-color')){
      this.navElement.classList.remove('fixed-top-color');
    }
  }

}
