import { Component, OnInit, Input } from '@angular/core';
import { RequestService } from 'src/app/services/requestService/request.service';
import { StripeScriptTag } from "stripe-angular"
import { TranslateService } from '@ngx-translate/core';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { UserService } from 'src/app/services/userService/user.service';
import { DataService } from 'src/app/services/dataService/data.service';
import { DialogService } from 'src/app/services/dialogService/dialog.service';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-step4-add-bank-card',
  templateUrl: './step4-add-bank-card.component.html',
  styleUrls: ['./step4-add-bank-card.component.less']
})
export class Step4AddBankCardComponent implements OnInit {
  displayLoaderFirst: boolean = true;
  displayForm: boolean = true;
  errorMassageFirst: any;
  currentLang: any;
  inputCard: any;
  nameButton: any;
  //Keys
  key: any;
  token: any;
  clientKey: any;
  //Input Declers
  stripe: any;
  cardElement: any;
  cardCvc: any;
  cardExp: any;
  cardholderName: any;
  setupForm: any;
  clientSecret: any;
  stripeStatusMessage: any;
  displayLoader: boolean = false;
  errorMassage: any;
  selectedCardFirstStep: any;
  //formDataResponse For Take The Object From The Step 2 Component
  @Input() formDataResponse: any;
  disableButton: boolean = false;
 
  constructor(
    private requestService: RequestService,
    private translate: TranslateService,
    private stripeScriptTag: StripeScriptTag,
    private errorCaseService: ErrorCasesService,
    private userService: UserService,
    private dataService: DataService,
    public router: Router,
    private scroll: ViewportScroller,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    //Get The On Change Language
    this.translate.onLangChange.subscribe(obj => {
      this.currentLang = obj.lang;
    });

    //Get The Current Language
    this.currentLang = this.translate.currentLang;

    //Get The First Time Input Card Property From Info
    this.inputCard = this.userService.getUser()?.FirstTimeInputCard;

    //Check The FirstTimeInputCard For Set The Button Language Word
    if (this.inputCard == true) {
      this.nameButton = 'submit_finish';
    }
    else if (this.inputCard == false) {
      this.nameButton = 'submit_change'
    }
    //Request For Take Keys
    this.getKeys();

    //Read Wicth Card Selected
    this.selectedCardFirstStep = this.dataService.getSelectedCard();
  }

  //Request For Take Keys From Back End
  getKeys() {
    const getKyesRequest = {
      url: '/main_service/api/ClientPaymentMethods/init-setup-insta'
    };
    this.requestService.mainPostRequest(getKyesRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.key = resp._value.Data.Key;
        this.token = resp._value.Data.Token;
        this.clientKey = resp._value.Data.ClientKey;
        this.initStripe()
      } else {
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassageFirst = error?.label;
        }
        this.displayLoaderFirst = false;
        this.displayForm = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoaderFirst = false;
      }
    });
  }

  //Create The Stripe Inputs
  initStripe() {
    // Create a Stripe client.
    this.stripe = Stripe(this.key);
    // Create An Instance Of Elements.
    var elements = this.stripe.elements();
    //1 Create A Card Number And Mount It On Page.
    this.cardElement = elements.create('cardNumber', {
      classes: {
        base: 'form-control bg',
        invalid: "error"
      },
      style: {
        base: {
          fontSize: "18px",
          color: "#1C4849"
        }
      }
    });
    this.cardElement.mount('#card-number');

    //2 Create A Card CVV And Mount It On Page.
    this.cardCvc = elements.create('cardCvc', {
      classes: {
        base: 'form-control bg',
        invalid: "error"
      },
      style: {
        base: {
          fontSize: "18px",
          color: "#1C4849"
        }
      }
    });
    this.cardCvc.mount('#card-cvc');

    //3 Create A Expiration Card Date And Mount It On Page.
    this.cardExp = elements.create('cardExpiry', {
      classes: {
        base: 'form-control bg',
        invalid: "error"
      },
      style: {
        base: {
          fontSize: "18px",
          color: "#1C4849"
        }
      }
    });
    this.cardExp.mount('#card-exp');

    //Set Id Input Leabel For Holder Name
    this.cardholderName = document.getElementById('cardholder-name');
    //Set The Id Form
    this.setupForm = document.getElementById('setup-form');
    //Set The Attribute And Set ClientKey
    this.setupForm.setAttribute('data-secret', this.clientKey);
    //The Value When The Stripe Return And We Send To Back End 
    this.clientSecret = this.setupForm.dataset.secret;
    //False Loader Then Load The Response 
    this.displayLoaderFirst = false;
    //Listener For Button Submit Call StripeReq Function
    this.setupForm.addEventListener('submit', (ev: any) => this.stripeReq(ev));
  }
  ev(ev: any) {
    throw new Error('Method not implemented.');
  }

  //Stripe Request Send Data In Stripe
  stripeReq(ev: any) {
    this.errorMassage = '';
    //Dont refresh  page
    ev.preventDefault();
    //Loader
    this.displayLoader = true;
    //Scroll To Loader Div
    this.scroll.scrollToAnchor('loaderDiv');
    //Set The Values If Are Empty
    let CardNumStatus = this.cardElement._implementation._empty;
    let CvvNumStatus = this.cardCvc._implementation._empty
    let ExpStatus = this.cardExp._implementation._empty
    let NameStatus = this.cardholderName.value
    //Check If Inpouts Leabels Are Empty & If The Inpouts Are OK
    if (!CardNumStatus && !CvvNumStatus && !ExpStatus && NameStatus !== '') {
      //Send The Data To The Stripe
      this.stripe.confirmCardSetup(
        this.clientSecret,
        {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              name: this.cardholderName.value,
            },
          },
        }//The Response Where Return The Stripe
      ).then((result: any) => {
        //If Stripe Respone Is Error
        if (result.error) {
          this.errorMassage = ' '
          //Display Error Message From Stripe Cases
          this.errorMassage = this.errorCaseStripe(result.error)
          //Function For Get Keys Again Run getKeys Request 
          this.resetStripe();
          //Loader
          this.displayLoader = false;
          //Scroll To Error Massage
          this.scroll.scrollToAnchor('errorMassage');
        }
        //If Stripe Response Is Succses
        else {
          //Check The FirstTimeInputCard And Selected Card
          if (this.inputCard && this.selectedCardFirstStep === 'NormalCard') {
            //If Have FirstTimeInputCard And Client Selected Normal card Then Call This Function
            this.sendKeys(result)
          }
          else if (this.inputCard && this.selectedCardFirstStep === 'VolvoCard' || this.selectedCardFirstStep === 'PeugeutCard' || this.selectedCardFirstStep === 'DSCard') {
            //If Have FirstTimeInputCard And Client Selected Volvo Or Peugeot Card
            this.volvoPeugeotRequest(result);
          }
          else {
            //Else Call This Function For Change Bank Card
            this.editSendKeys(result);
          }
        }
      });
    }
    //If Inpouts Leabels Are Empty Then Error Massage
    else {
      this.errorMassage = 'payment_fill_fields';
      this.scroll.scrollToAnchor('errorMassage');
      //Loader
      this.displayLoader = false;
    }
    throw new Error('Method not implemented.');
  }

  //Handle Error Cases From Stripe Response
  //Returns Translate Variable
  errorCaseStripe(error_code: any) {
    switch (error_code.code) {
      case 'incomplete_number':
        return 'stripe_incomplete_number'

      case 'invalid_number':
        return 'stripe_invalid_number'

      case 'incomplete_expiry':
        return 'stripe_incomplete_expiry'

      case 'invalid_expiry_year_past':
        return 'stripe_invalid_expiry_year_past'

      case 'invalid_expiry_month_past':
        return 'stripe_invalid_expiry_month_past'

      case 'card_declined':
        return 'stripe_card_declined'

      case 'incomplete_cvc':
        return 'stripe_incomplete_cvc'

      case 'setup_intent_authentication_failure':
        return 'stripe_setup_intent_authentication_failure'

      case 'expired_card':
        return 'expired_card'

      default:
        return error_code.message
    }
  }

  //Reset Function For Get New Keys
  resetStripe() {
    //Request For Get New Keys
    this.getKeys();
    //Clear Card Holder Name Input Leabel
    this.cardholderName.value = ''
  }

  /* If The FirstTimeInputCard Is True 
  And Selected Normal Card
  Then Call This Function 
  And Set And Send The Data From Step 2 Form */
  sendKeys(result: any) {
    this.errorMassage = '';
    this.disableButton = true;
    const sendKyesRequest = {
      url: '/main_service/api/ClientPaymentMethods/init-setup-insta-final',
      req: {
        Id: result.setupIntent.id,
        ClientSecret: result.setupIntent.client_secret,
        Created: result.setupIntent.created,
        PaymentMethod: result.setupIntent.payment_method,
        Status: result.setupIntent.status,
        PaymentMethods: result.setupIntent.payment_method_types,
        Token: this.token,
        Language: this.currentLang,
        SetupCardDetails: {
          TIN: this.formDataResponse.afm,
          CarPlate: this.formDataResponse.carPlate,
          Mobile: this.formDataResponse.phoneNumber,
          MobileVer: this.formDataResponse.smsToken,
          SendDetails: {
            FullName: this.formDataResponse.fullName,
            Address: this.formDataResponse.address,
            AddressNumber: this.formDataResponse.addressNum,
            City: this.formDataResponse.city,
            PostalCode: this.formDataResponse.postalCode
          }
        }
      }
    };
    this.requestService.mainPostRequest(sendKyesRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.displayLoader = false;
        this.disableButton = false;
        this.stripeStatusMessage = 'payment_success';
        this.scroll.scrollToAnchor('succsesMassage');
        this.router.navigate(['/account/welcome']);
      } else {
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;
        this.scroll.scrollToAnchor('errorMassage');
        this.resetStripe();
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  /* If The FirstTimeInputCard Is True 
    And Selected Is Volvo Or Peugeot
    Then Call This Function 
    And Set And Send The Data From Step 2 Form 
    And Send Rfid Card Number 
    And Rfid Token From Request Response*/
  volvoPeugeotRequest(result: any) {
    this.errorMassage = '';
    this.disableButton = true;
    const sendKyesRequest = {
      url: '/main_service/api/ClientPaymentMethods/init-setup-insta-final',
      req: {
        Id: result.setupIntent.id,
        ClientSecret: result.setupIntent.client_secret,
        Created: result.setupIntent.created,
        PaymentMethod: result.setupIntent.payment_method,
        Status: result.setupIntent.status,
        PaymentMethods: result.setupIntent.payment_method_types,
        Token: this.token,
        Language: this.currentLang,
        CarOwnerBandle: {
          RFIDCardId: this.formDataResponse.volvoPeugeotRfIdNumber,
          CarOwnerToken: this.formDataResponse.tokenVolvoPeugeot
        },
        SetupCardDetails: {
          TIN: this.formDataResponse.afm,
          CarPlate: this.formDataResponse.carPlate,
          Mobile: this.formDataResponse.phoneNumber,
          MobileVer: this.formDataResponse.smsToken,
          SendDetails: {
            FullName: this.formDataResponse.fullName,
            Address: this.formDataResponse.address,
            AddressNumber: this.formDataResponse.addressNum,
            City: this.formDataResponse.city,
            PostalCode: this.formDataResponse.postalCode
          }
        }
      }
    };
    this.requestService.mainPostRequest(sendKyesRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.displayLoader = false;
        this.disableButton = false;
        this.stripeStatusMessage = 'payment_success';
        this.scroll.scrollToAnchor('succsesMassage');
        this.router.navigate(['/account/welcome']);
      } else {
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;
        this.scroll.scrollToAnchor('errorMassage');
        this.resetStripe();
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  /* If The FirstTimeInputCard Is True 
  Then Call This Function For Change Bank card */
  editSendKeys(result: any) {
    this.errorMassage = '';
    this.disableButton = true;
    const sendEditKyesRequest = {
      url: '/main_service/api/ClientPaymentMethods/init-setup-insta-final-edit',
      req: {
        Id: result.setupIntent.id,
        ClientSecret: result.setupIntent.client_secret,
        Created: result.setupIntent.created,
        PaymentMethod: result.setupIntent.payment_method,
        Status: result.setupIntent.status,
        PaymentMethods: result.setupIntent.payment_method_types,
        Token: this.token,
        Language: this.currentLang,
      }
    };
    this.requestService.mainPostRequest(sendEditKyesRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.displayLoader = false;
        this.disableButton = false;
        this.dialogService.openSuccesChangeCardDialogService();
      } else {
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;
        this.scroll.scrollToAnchor('errorMassage');
        this.resetStripe();
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  //Open Start Over Dialog
  openStartOverDialog(event: Event) {
    event.preventDefault();
    this.dialogService.openStartOverDialogService();
  }

}