import { UserService } from '../services/userService/user.service';

export function appInitializer(userService: UserService) {
  return () =>
    new Promise<void>((resolve) => {
      userService.getUserInfo().then((resp) => {
        userService.getRFIDSendCardInformation().then((resp) => {
          resolve();
        });
      });
    });
}

export function getRFIDSendCardInfoInitializer(userService: UserService) {
  return () => new Promise<void>((resolve) => {userService.getRFIDSendCardInformation().then((resp) => {resolve()})});
}
