import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MapSandbox } from './../map/map.sandbox';
import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewportScroller } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';
import {DisplayPrinterLocation,IChargingPoint,CustomOpeningTimes,AvailableChargerTypeDetails,OpeningTime,DisplayCustomOpeningTime,IDisplayPrinterLocation} from './locationPrinter.interfaces/locationPrinter.interfaces';
import { LocationWeekDaysEnum } from './locationPrinter.enums/locationPrinter.weekdays.enum';

@Component({
  selector: 'app-locationPrinter',
  templateUrl: './locationPrinter.component.html',
  styleUrls: ['./locationPrinter.component.less'],
})
export class LocationPrinterComponent implements OnInit,AfterViewInit, OnDestroy {
  numberIncremental: number = 1;
  pageYoffset = 0;
  scrollEnd: boolean = false;
  chargingPoints = new Array<DisplayPrinterLocation>();
  beBaseChargingPoints = new Array<IChargingPoint>();
  dataSource: MatTableDataSource<DisplayPrinterLocation> =
    new MatTableDataSource<DisplayPrinterLocation>();
  displayedColumns: Array<string> = [
    'No',
    'Title',
    'City',
    'Address',
    'OpeningTimes',
    'Description',
    'Active',
  ];
  @ViewChild(MatSort)
  sort!: MatSort;
  private readonly $destroy: Subject<void> = new Subject<void>();
  constructor(
    private mapSandbox: MapSandbox,
    private scroll: ViewportScroller
  ) {

    this.mapSandbox._data
      .pipe(takeUntil(this.$destroy))
      .subscribe((resp: any) => {
        if (!resp) {
          this.mapSandbox.getPoints();
        } else {
          this.beBaseChargingPoints = resp.ChargingPoints;
          if (!this.beBaseChargingPoints || !this.beBaseChargingPoints.length) {
            return;
          }
          this.beBaseChargingPoints.forEach((point: IChargingPoint) => {
            if (!point) return;
            let displayPrinterLocation = {} as DisplayPrinterLocation;
            displayPrinterLocation.No = this.numberIncremental++;
            displayPrinterLocation.Title = point.Title;
            displayPrinterLocation.Address = point.Address;
            //Commented by requirement
            // displayPrinterLocation.FullTitle = point.FullTitle;
            displayPrinterLocation.Description = new Array<string>();
            displayPrinterLocation.City = point.City;
            displayPrinterLocation.DisplayCustomOpeningTimes = this.buildOpeningTimesDisplay(point.CustomOpeningTimes)
            point.AvailableChargerTypeDetails.forEach(
              (chargerTypeDetail: AvailableChargerTypeDetails) => {
                displayPrinterLocation.Description.push(
                  ` ${chargerTypeDetail.Description} `
                );
              }
            );
            displayPrinterLocation.Active = point.Active;
            this.chargingPoints.push(displayPrinterLocation);
          });
          console.log("----THIS IS THE ONE----",this.chargingPoints);
          this.dataSource = new MatTableDataSource<DisplayPrinterLocation>(
            this.chargingPoints
          );
        }
      });
  }
  ngOnInit() {}
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  buildOpeningTimesDisplay(customOpeningTime : CustomOpeningTimes): DisplayCustomOpeningTime | Array<DisplayCustomOpeningTime> {
    if(!customOpeningTime)
      return {
        OpeningTimesString : "not_provided",
        TwentyFourSeven : false,
      };
    if(customOpeningTime.IsProvided){
      if(customOpeningTime.TwentyFourSeven){
        return {
          OpeningTimesString : "twenty_four_seven",
          TwentyFourSeven : true,
        }
      }
      else{
        const returnedArray = customOpeningTime.OpeningTimes!.map((openingTime : OpeningTime):DisplayCustomOpeningTime => {
          return {
            TwentyFourSeven : false,
              OpeningTimesString : openingTime.OpeningTimesString,
              WeekDay : this.internalWeekDayEnumToStringMapped(openingTime.WeekDay)
          }
        });
        return returnedArray;
        }
      }
    return {
      OpeningTimesString : "not_provided",
      TwentyFourSeven : false,
    };
  }

  internalWeekDayEnumToStringMapped(weekDay : LocationWeekDaysEnum) : string {
    switch(weekDay){
      case LocationWeekDaysEnum.Monday:
        return "monday";
      case LocationWeekDaysEnum.Tuesday:
        return "tuesday";
      case LocationWeekDaysEnum.Wednesday:
        return "wednesday";
      case LocationWeekDaysEnum.Thursday:
        return "thursday";
      case LocationWeekDaysEnum.Friday:
        return "friday";
      case  LocationWeekDaysEnum.Saturday:
        return "saturday";
      case LocationWeekDaysEnum.Sunday:
        return "sunday";
      default:
        return "not_provided";
    }
  }

  ngOnDestroy(): void {
    this.numberIncremental = 1;
    this.chargingPoints = new Array<IDisplayPrinterLocation>();
    this.beBaseChargingPoints = new Array<IChargingPoint>();
    this.$destroy.next();
    this.$destroy.complete();
  }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.pageYoffset = window.pageYOffset;

    //For Scroll End
    if (
      document.body.clientHeight + window.scrollY + 100 >=
      document.body.scrollHeight
    ) {
      this.scrollEnd = true;
    } else {
      this.scrollEnd = false;
    }
  }

  invokeExport() {
    const w = window;
    w?.focus(); // necessary for IE >= 10
    setTimeout(function () { // necessary for Chrome
            w?.print();
            w?.close();
        }, 0);

    return true;
  }
  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }
  onSortChange(sortState: Sort) {
    console.log('sortState',sortState);
    this.dataSource.sort = this.sort;
  }
}

