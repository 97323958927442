import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { DataService } from 'src/app/services/dataService/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sms-verification',
  templateUrl: './sms-verification.component.html',
  styleUrls: ['./sms-verification.component.less']
})
export class SmsVerificationComponent implements OnInit {
  phoneNumberFormGroup: FormGroup;
  displayLoader: boolean = false;
  disableButton: boolean = false;
  newPhoneNumber: any;
  errorMassage: string = '';
  phoneNumberKeyValToken: any;
  objectValues: any;

  constructor(
    private phoneNumberFormBuilder: FormBuilder,
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService,
    private dataService: DataService,
    private router: Router) {

    //Form Group
    this.phoneNumberFormGroup = this.phoneNumberFormBuilder.group({
      smsVerificationNumber_1: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_2: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_3: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_4: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_5: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_6: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]]
    });
  }

  ngOnInit(): void {
    //Set & Get The New PhoneNumber For Display In HTML & Get All The Object Values
    if (this.dataService.getObjectValues()) {
      this.newPhoneNumber = this.dataService.getObjectValues().mobile;
      this.objectValues = this.dataService.getObjectValues();
    }

    //Get The KeyVal Token
    if (this.dataService.getMobileToken()) {
      this.phoneNumberKeyValToken = this.dataService.getMobileToken();
      //Focus The Last Input 
      setTimeout(() => {
        document.getElementById("number_1")!.focus();
      }, 100);
    }
    else if (this.dataService.getMobileToken() == undefined) {
      this.router.navigate(['/account/changePersonalInfo']);
    }
  }

  //Send Sms Code Request
  sendSmsCode() {
    this.errorMassage = "";
    if (!this.phoneNumberFormGroup.valid) {
      this.errorMassage = "sms_verification_error_digit_code";
    }
    else {
      this.displayLoader = true;
      this.disableButton = true;

      const smsVerificationCode = this.phoneNumberFormGroup.controls.smsVerificationNumber_1.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_2.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_3.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_4.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_5.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_6.value;
      const smsCodeRequest = {
        url: '/main_service/api/ClientPaymentMethods/verify-sms-final',
        req: {
          "Mobile": this.newPhoneNumber,
          "Code": smsVerificationCode,
          "Part": this.phoneNumberKeyValToken
        }
      }
      this.requestService.mainPostRequest(smsCodeRequest).subscribe((resp) => {
        if (resp._value.Status) {
          //Check If Data Is Null
          if (resp._value.Data == null) {
            this.errorMassage = 'alreadyValidated_verify_sms_false_error';
          }
          else {
            //Set Token In Object
            this.objectValues.mobileVerification = resp._value.Data;

            //Set The Object With Final Token In dataService
            this.dataService.setSmsFinalObject(this.objectValues);
            this.errorMassage = "";
            setTimeout(() => {
              this.router.navigate(['/account/changePersonalInfo']);
              this.displayLoader = false;
              this.disableButton = false;
            }, 1000);
          }
        } else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.errorMassage = error?.label;
          }
          this.displayLoader = false;
          this.disableButton = false;
          //Focus The Last Input 
          setTimeout(() => {
            document.getElementById("number_4")!.focus();
          }, 100);
        }
      }, throwError => {
        if (throwError) {
          this.displayLoader = false;
        }
      });
    }
  }

  //Resend Sms Verification Code
  resendNumber() {
    this.errorMassage = "";
    this.displayLoader = true;
    this.disableButton = true;

    const verfyPhoneNumber = {
      url: '/main_service/api/ClientPaymentMethods/verify-sms',
      req: {
        "Mobile": this.newPhoneNumber,
      }
    };
    this.requestService.mainPostRequest(verfyPhoneNumber).subscribe((resp) => {
      if (resp._value.Status) {
        if (!resp._value.Data.AlreadyValidated) {
          this.phoneNumberKeyValToken = resp._value.Data.KeyVal;
          this.phoneNumberFormGroup.reset();
          setTimeout(() => {
            document.getElementById("number_1")!.focus();
          }, 100);
          this.displayLoader = false;
          this.disableButton = false;
        }
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  //Back button For Navigate In Change Details
  backButton() {
    this.router.navigate(['/account/changePersonalInfo']);
  }

  //Listener Input 1
  numberInput1(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_1.value.length == 1) {
      document.getElementById("number_2")!.focus();
    }
  }

  //Listener Input 2
  numberInput2(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_2.value.length == 1) {
      document.getElementById("number_3")!.focus();
    }
  }

  //Listener Input 3
  numberInput3(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_3.value.length == 1) {
      document.getElementById("number_4")!.focus();
    }
  }

  //Listener Input 4
  numberInput4(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_4.value.length == 1) {
      document.getElementById("number_5")!.focus();
    }
  }

  //Listener Input 5
  numberInput5(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_5.value.length == 1) {
      document.getElementById("number_6")!.focus();
    }
  }

  //onKeyDown Listener 1
  onKeydown1(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_2.value == 0 && event.key === "Backspace") {
      document.getElementById("number_1")!.focus();
    }
  }

  //onKeyDown Listener 2
  onKeydown2(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_3.value == 0 && event.key === "Backspace") {
      document.getElementById("number_2")!.focus();
    }
  }

  //onKeyDown Listener 3
  onKeydown3(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_4.value == 0 && event.key === "Backspace") {
      document.getElementById("number_3")!.focus();
    }
  }

  //onKeyDown Listener 4
  onKeydown4(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_5.value == 0 && event.key === "Backspace") {
      document.getElementById("number_4")!.focus();
    }
  }

  //onKeyDown Listener 5
  onKeydown5(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_6.value == 0 && event.key === "Backspace") {
      document.getElementById("number_5")!.focus();
    }
  }

}