import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from 'src/app/services/requestService/request.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']
})

export class ContactComponent implements OnInit {
  submitMassage: any;
  contactFormGroup: FormGroup;
  displayLoader: boolean = false;
  public screenWidth: any = window.innerWidth;
  disableButton: boolean = false;

  constructor(
    private contactFormBuilder: FormBuilder,
    private translate: TranslateService,
    private requestService: RequestService) {
    this.contactFormGroup = this.contactFormBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(25)]],
      lastName: ['', [Validators.required, Validators.maxLength(30)]],
      phoneNumber: ['', [Validators.maxLength(15)]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      massage: ['', [Validators.required, Validators.maxLength(2000)]],
      checkTerms: ['', Validators.required],

    });
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(obj => { });
  }

  //Check Form If Is Valid
  checkForm() {
    if (!this.contactFormGroup.valid) {
      this.submitMassage = "contact_form_empty_error_message";
    }
    else {
      this.sentMassage();
    }
  }

  //Request For Send Support Massage 
  sentMassage() {
    this.displayLoader = true;
    this.disableButton = true;
    //Give The Values In The Variables
    let contactFormData = {
      name: this.contactFormGroup.controls.firstName.value,
      surName: this.contactFormGroup.controls.lastName.value,
      phone: this.contactFormGroup.controls.phoneNumber.value,
      email: this.contactFormGroup.controls.email.value,
      subject: this.contactFormGroup.controls.subject.value,
      massage: this.contactFormGroup.controls.massage.value,
      terms: this.contactFormGroup.controls.checkTerms.value
    };
    //Send Request
    const req = {
      url: '/main_service/api/infoinit/action/contact-us',
      req: {
        "Title": contactFormData.subject,
        "FirstName": contactFormData.name,
        "LastName": contactFormData.surName,
        "PhoneNumber": contactFormData.phone,
        "GuestEmail": contactFormData.email,
        "Message": contactFormData.massage,
        "AcceptedConditions": contactFormData.terms
      }
    };
    this.requestService.mainPostRequest(req).subscribe((resp) => {
      if (resp._value.Status) {
        this.contactFormGroup.patchValue({
          name: null,
          surName: null,
          phone: null,
          email: null,
          subject: null,
          massage: null
        })
        this.submitMassage = "contact_form_success_message";
        this.contactFormGroup.reset();
      } else {
        this.submitMassage = "contact_form_error_message";
      }
      this.displayLoader = false;
      this.disableButton = false;
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }


  //Read Window Size (Width) Repeat When Change Screen Width
  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenWidth = window.innerWidth;
  }

}