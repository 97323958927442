import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { ViewportScroller } from '@angular/common';
import { DataService } from 'src/app/services/dataService/data.service';
import { Router } from '@angular/router';
import { StripeScriptTag } from "stripe-angular"

@Component({
  selector: 'app-one-time-payment-eko',
  templateUrl: './one-time-payment-eko.component.html',
  styleUrls: ['./one-time-payment-eko.component.less']
})
export class OneTimePaymentEKOComponent implements OnInit {
  displayLoaderFirst: boolean = true;
  errorMassageFirst: any;
  succsesMassageFirst: any;
  displayLoader: boolean = false;
  disableButton: boolean = false;
  errorMassage: any;
  stripeStatusMessage: any;
  //Keys
  key: any;
  token: any;
  clientKey: any;
  //Input Declers
  stripe: any;
  cardElement: any;
  cardCvc: any;
  cardExp: any;
  cardholderName: any;
  setupForm: any;
  clientSecret: any;
  displayForm: boolean = true;

  constructor(
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService,
    private scroll: ViewportScroller,
    private dataService: DataService,
    public router: Router,
    private stripeScriptTag: StripeScriptTag) { }

  ngOnInit(): void {
    //To Scroll Top
    setTimeout(() => {
      this.scroll.scrollToPosition([0, 0]);
    }, 1000)

    //Check Parameter For Only Open From Dialog
    if (this.dataService.getGurdParameter()) {
      this.getKeys();
    }
    else if (!this.dataService.getGurdParameter()) {
      this.router.navigate(["/account/myAcount"]);
    }

  }

  //Request For Take Keys From Back End
  getKeys() {
    this.disableButton = true;
    const getKyesRequest = {
      url: '/main_service/api/ClientPaymentMethods/create-payment-intent'
    };
    this.requestService.mainPostRequest(getKyesRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.key = resp._value.Data.Key;
        this.token = resp._value.Data.Token;
        this.clientKey = resp._value.Data.ClientKey;
        this.initStripe();
      } else {
        if (resp._value.Error.ErrorCode == 28) {
          this.succsesMassageFirst = 'pay_debt_with_EKO_completed';
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        }
        else {
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.errorMassageFirst = error?.label;
          }
        }
        this.displayForm = false;
        this.displayLoaderFirst = false;
        this.disableButton = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoaderFirst = false;
      }
    });
  }

  //Create The Stripe Inputs
  initStripe() {
    // Create a Stripe client.
    this.stripe = Stripe(this.key);

    // Create An Instance Of Elements.
    var elements = this.stripe.elements();

    //1 Create A Card Number And Mount It On Page.
    this.cardElement = elements.create('cardNumber', {
      classes: {
        base: 'form-control bg',
        invalid: "error"
      },
      style: {
        base: {
          fontSize: "18px",
          color: "#1C4849"
        }
      }
    });
    this.cardElement.mount('#card-number');

    //2 Create A Card CVV And Mount It On Page.
    this.cardCvc = elements.create('cardCvc', {
      classes: {
        base: 'form-control bg',
        invalid: "error"
      },
      style: {
        base: {
          fontSize: "18px",
          color: "#1C4849"
        }
      }
    });
    this.cardCvc.mount('#card-cvc');

    //3 Create A Expiration Card Date And Mount It On Page.
    this.cardExp = elements.create('cardExpiry', {
      classes: {
        base: 'form-control bg',
        invalid: "error"
      },
      style: {
        base: {
          fontSize: "18px",
          color: "#1C4849"
        }
      }
    });
    this.cardExp.mount('#card-exp');

    //Set Id Input Leabel For Holder Name
    this.cardholderName = document.getElementById('cardholder-name');

    //Set The Id Form
    this.setupForm = document.getElementById('setup-form');

    //Set The Attribute And Set ClientKey
    this.setupForm.setAttribute('data-secret', this.clientKey);

    //The Value When The Stripe Return And We Send To Back End 
    this.clientSecret = this.setupForm.dataset.secret;

    //False Loader Then Load The Response 
    this.displayLoaderFirst = false;
    this.disableButton = false;

    //Listener For Button Submit Call StripeReq Function
    this.setupForm.addEventListener('submit', (ev: any) => this.stripeReq(ev));
  }
  ev(ev: any) {
    throw new Error('Method not implemented.');
  }


  //Stripe Request Send Data In Stripe
  stripeReq(ev: any) {
    //Dont refresh  page
    ev.preventDefault();

    //Loader
    this.displayLoader = true;
    this.disableButton = true;

    //Scroll To Loader Div
    this.scroll.scrollToAnchor('loaderDiv');

    //Set The Values If Are Empty
    let CardNumStatus = this.cardElement._implementation._empty;
    let CvvNumStatus = this.cardCvc._implementation._empty
    let ExpStatus = this.cardExp._implementation._empty
    let NameStatus = this.cardholderName.value

    //Check If Inpouts Leabels Are Empty & If The Inpouts Are OK
    if (!CardNumStatus && !CvvNumStatus && !ExpStatus && NameStatus !== '') {
      //Send The Data To The Stripe In confirmCardPayment() And Return a Result
      this.stripe.confirmCardPayment(
        this.clientSecret,
        {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              name: this.cardholderName.value,
            },
          },
        }).then((result: any) => {
          //If Stripe Respone Is Error
          if (result.error) {
            this.errorMassage = '';

            //Display Error Message From Stripe Cases
            this.errorMassage = this.errorCaseStripe(result.error)

            //Loader
            this.displayLoader = false;
            this.disableButton = false;

            //Scroll To Error Massage
            this.scroll.scrollToAnchor('errorMassage');

            //Reset Stripe
            this.resetStripe();
          }
          //If Stripe Response Is Succsess
          else {
            setTimeout(() => {
              this.sendResultPayment(result);
            }, 2000);
          }
        });
    }
    //If Inpouts Leabels Are Empty Then Error Massage
    else {
      this.errorMassage = 'payment_fill_fields';
      this.scroll.scrollToAnchor('errorMassage');
      //Loader
      this.displayLoader = false;
      this.disableButton = false;
    }
    //throw new Error('Method not implemented.');
  }

  //Handle Error Cases From Stripe Response
  //Returns Translate Variable
  errorCaseStripe(error_code: any) {
    switch (error_code.code) {
      case 'incomplete_number':
        return 'stripe_incomplete_number'

      case 'invalid_number':
        return 'stripe_invalid_number'

      case 'incomplete_expiry':
        return 'stripe_incomplete_expiry'

      case 'invalid_expiry_year_past':
        return 'stripe_invalid_expiry_year_past'

      case 'invalid_expiry_month_past':
        return 'stripe_invalid_expiry_month_past'

      case 'card_declined':
        return 'stripe_card_declined'

      case 'incomplete_cvc':
        return 'stripe_incomplete_cvc'

      case 'setup_intent_authentication_failure':
        return 'stripe_setup_intent_authentication_failure'

      case 'expired_card':
        return 'expired_card'

      default:
        return error_code.message
    }
  }

  //Reset Function For Get New Keys
  resetStripe() {
    //Request For Get New Keys
    this.getKeys();
    //Clear Card Holder Name Input Leabel
    this.cardholderName.value = ''
  }

  //Function For Succsess Result  
  sendResultPayment(result: any) {
    const getKyesRequest = {
      url: '/main_service/api/ClientPaymentMethods/one-time-payment-manual',
      req: {
        PaymentIntent: result.paymentIntent.id,
        Token: this.token
      }
    };
    this.requestService.mainPostRequest(getKyesRequest).subscribe((resp) => {
      if (resp._value.Status) {
        if (resp._value.Data.SuccessPayments.length > 0) {
          this.stripeStatusMessage = 'pay_debt_succses';
          this.scroll.scrollToAnchor('succsesMassage');
          setTimeout(() => {
            this.router.navigate(["/account/myAcount"]);
          }, 2000)
        }
        else if (resp._value.Data.FailedPayments.length > 0) {
          this.errorMassage = 'pay_debt_failed';
          this.scroll.scrollToAnchor('errorMassage');
          //Reset Stripe
          this.resetStripe();
        }
        else if (resp._value.Data.AlreadyInProccess.length > 0) {
          this.stripeStatusMessage = 'pay_debt_proccess';
          this.scroll.scrollToAnchor('succsesMassage');
          setTimeout(() => {
            this.router.navigate(["/account/myAcount"]);
          }, 2000)
        }
        this.displayLoader = false;
        this.disableButton = false;
      } else {
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;

        //Reset Stripe
        this.resetStripe();
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }
}