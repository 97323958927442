import { ViewportScroller } from '@angular/common';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/requestService/request.service';
import { UserService } from 'src/app/services/userService/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, timer } from 'rxjs'
import { takeWhile } from 'rxjs/operators'
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { ConfirmedValidator } from '../../../validators/ConfirmedValidator';
import { DataService } from 'src/app/services/dataService/data.service';
import { SendCardDetails } from 'src/app/interfaces/Responses/SendCardDetails';
import { ClientChangePostalDetails } from 'src/app/interfaces/Requests/ClientChangePostalDetails';
import { APIResult } from 'src/app/interfaces/DTO/APIResult';

@Component({
  selector: 'app-change-personal-info',
  templateUrl: './change-personal-info.component.html',
  styleUrls: ['./change-personal-info.component.less']
})
export class ChangePersonalInfoComponent implements OnInit,AfterViewInit,OnDestroy {
  hide = true;
  hideVerification = true;
  hideOld = true;
  strongRegexPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#\$%\^&\*])(?=.{8,})");
  changePersonalFormGroup: FormGroup;
  changePasswordFormGroup: FormGroup;
  changeRFIDSendCardLocation:FormGroup;
  dataUserInfos: any;
  userId: any;
  statusInfo: any;
  statusPassword: any;
  callMassageInfo: any;
  callMessageRFIDDetails:string = "";
  callMassagePassword: any;
  displayLoader: any;
  displayLoaderPassword: any;
  alive: boolean = true;
  disableButton: boolean = false;
  firstNameGroup: any;
  lastNameGroup: any;
  mobileNumberGroup: any;
  afmGroup: any;
  carPlateGroup: any;
  subscriptionType: any;

  internalRfidCardSendDetails:SendCardDetails | null = null;
  private _activatedRouteSub = new Subscription();
// RFID Send Card Location Fields
  // rfidCardSendLocationFullName:string;
  // rfidCardSendLocationAddress:string;
  // rfidCardSendLocationAddressNumber:string;
  // rfidCardSendLocationCity:string;
  // rfidCardSendLocationPostalCode:string;
  constructor(
    private changeDetailsFormBuilder: FormBuilder,
    private changePasswordFormBuilder: FormBuilder,
    private changeRFIDSendCardLocationFormBuilder:FormBuilder,
    private requestService: RequestService,
    private userService: UserService,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private errorCaseService: ErrorCasesService,
    private dataService: DataService,
    private scroller:ViewportScroller) {

    //Get Info Request Data
    this.dataUserInfos = this.userService.getUser();
    this.internalRfidCardSendDetails = this.userService.getRfidSendCardDetails();
    //Set The User Id From Info Requests
    this.userId = this.dataUserInfos.UserId;

    if (this.dataService.getSmsFinalObject()) {
      this.firstNameGroup = this.dataService.getSmsFinalObject().firstName;
      this.lastNameGroup = this.dataService.getSmsFinalObject().lastName;
      this.mobileNumberGroup = this.dataService.getSmsFinalObject().mobile;
      this.afmGroup = this.dataService.getSmsFinalObject().afm;
      this.carPlateGroup = this.dataService.getSmsFinalObject().carPlate;
    }
    else {
      this.firstNameGroup = this.dataUserInfos.FirstName;
      this.lastNameGroup = this.dataUserInfos.LastName;
      this.mobileNumberGroup = this.dataUserInfos.Mobile;
      this.afmGroup = this.dataUserInfos.TIN;
      this.carPlateGroup = this.dataUserInfos.CarPlate;
    }

    //Change Personal Info Form Group
    this.changePersonalFormGroup = this.changeDetailsFormBuilder.group({
      firstName: [this.firstNameGroup, [Validators.required, Validators.maxLength(25)]],
      lastName: [this.lastNameGroup, [Validators.required, Validators.maxLength(25)]],
      mobileNumber: [this.mobileNumberGroup, [Validators.required, Validators.maxLength(13), Validators.pattern("^[0-9]{10}$")]],
      afm: [this.afmGroup, [Validators.required, Validators.maxLength(9), Validators.pattern("^[0-9]{9}$")]],
      carPlate: [this.carPlateGroup, [Validators.required, Validators.maxLength(10)]],
    });
    //Change Password Form Group
    this.changePasswordFormGroup = this.changePasswordFormBuilder.group({
      oldPassword: ['', [Validators.required, Validators.maxLength(20)]],
      password: ['', [Validators.required, Validators.maxLength(16), Validators.minLength(8)]],
      passwordVerification: ['', [Validators.required, Validators.maxLength(16), Validators.minLength(8)]]
    }, {
      validator: ConfirmedValidator('password', 'passwordVerification')
    });
    // RFID Send Card Location Form Group
    this.changeRFIDSendCardLocation = this.changeRFIDSendCardLocationFormBuilder.group(
      {
        //Change with the API Values
      //  rfidCardSendLocationFullName:[ this.internalRfidCardSendDetails?.FullName || `${this.firstNameGroup} ${this.lastNameGroup}`,[Validators.required,Validators.maxLength(50),Validators.pattern("^[a-zA-Z\u0370-\u03FF]+ *[a-zA-Z\u0370-\u03FF]*$")]],// MATCHES JOHN[SPACE]DOE
        rfidCardSendLocationFullName:[ this.internalRfidCardSendDetails?.FullName || `${this.firstNameGroup} ${this.lastNameGroup}`,[Validators.required,Validators.maxLength(50),Validators.pattern("^[a-zA-Z\u0370-\u03FF]+( ?-? ?[a-zA-Z\u0370-\u03FF]*){0,}$")]],// MATCHES JOHN[SPACE]DOE
        rfidCardSendLocationAddress:[ this.internalRfidCardSendDetails?.Address || '',[Validators.required,Validators.maxLength(50)]],
        rfidCardSendLocationAddressNumber:[ this.internalRfidCardSendDetails?.AddressNumber ||'',[Validators.required,Validators.maxLength(7),Validators.pattern("^[0-9]{1,5} ?[a-zA-Z\u0370-\u03FF]?$")]],
        rfidCardSendLocationCity:[ this.internalRfidCardSendDetails?.City || '',[Validators.required,Validators.maxLength(50)]],
        rfidCardSendLocationPostalCode:[ this.internalRfidCardSendDetails?.PostalCode || '',[Validators.required,Validators.maxLength(5),Validators.minLength(5),Validators.pattern("^[0-9]{5}$")]]
      }
    );
  }
  ngOnDestroy(): void {
    this._activatedRouteSub.unsubscribe();
  }
  ngAfterViewInit(): void {
    this._activatedRouteSub = this.activatedRoute.queryParams.subscribe((params) => {
      setTimeout(() => {
        if(params && params.link && params.link === 'anchor')
          this.scroller.scrollToAnchor("anchorPoint");
      },100);
    });
  }
  
  ngOnInit(): void {
    const user = this.userService.getUser();
    if(!user)
      return;
    if (user.FirstTimeInputCard) {
      this.router.navigate(['/account/selectCard']);
    }
    else {
      //Check If Exists The Value From The getSmsFinalToken() Function
      const rfidDetails = this.dataService.getRFIDDetails();
      const smsFinalObject = this.dataService.getSmsFinalObject();
      if(smsFinalObject){
        this.updatePersonalInfo(smsFinalObject,(rfidDetails === null || rfidDetails === undefined ));
        if(rfidDetails)
          this.updateRFIDCardDetails(rfidDetails);
      }
      //Get Subscription Type
      this.subscriptionType = user?.SubscriptionType;
    }
  }



  //Check If The Mobile Number Have Change It
  async checkMobileNumber():Promise<void> {
    //Set All Values In Object
    if(this.changePersonalFormGroup.invalid){
        let error = this.errorCaseService.checkErrorCode(4);
        if (error?.label) {
          this.callMassageInfo = error?.label;
        }
        this.statusInfo = false;
        this.displayLoader = false;
        this.disableButton = false;
        return;
    }
    const inputsValues = {
      firstName: this.changePersonalFormGroup.controls.firstName.value,
      lastName: this.changePersonalFormGroup.controls.lastName.value,
      mobile: this.changePersonalFormGroup.controls.mobileNumber.value,
      mobileVerification: null,
      carPlate: this.changePersonalFormGroup.controls.carPlate.value,
      afm: this.changePersonalFormGroup.controls.afm.value,
    };

    if((!this.dataUserInfos.Mobile && this.changePersonalFormGroup.controls.mobileNumber.value) || (this.dataUserInfos.Mobile !== this.changePersonalFormGroup.controls.mobileNumber.value)){
       await this.sendMobileNumber(inputsValues);
    }
    else{
      this.updatePersonalInfo(inputsValues);
    }
  }

  async submitRFIDCardSendDetails():Promise<void>{
    if(this.changePersonalFormGroup.invalid || this.changeRFIDSendCardLocation.invalid){
      this.callMassageInfo = this.errorCaseService.checkErrorCode(4)?.label;
      this.statusInfo = false;
      this.displayLoader = false;
      this.disableButton = false;
      return;
    }
    const inputValues = {
      firstName: this.changePersonalFormGroup.controls.firstName.value,
      lastName: this.changePersonalFormGroup.controls.lastName.value,
      mobile: this.changePersonalFormGroup.controls.mobileNumber.value,
      mobileVerification: null,
      carPlate: this.changePersonalFormGroup.controls.carPlate.value,
      afm: this.changePersonalFormGroup.controls.afm.value,
    };

    const rfidDetails:ClientChangePostalDetails = {
      Id:this.userId,
      SendCardDetails :{
        Address : this.changeRFIDSendCardLocation.controls.rfidCardSendLocationAddress.value,
        AddressNumber : this.changeRFIDSendCardLocation.controls.rfidCardSendLocationAddressNumber.value,
        City : this.changeRFIDSendCardLocation.controls.rfidCardSendLocationCity.value,
        FullName : this.changeRFIDSendCardLocation.controls.rfidCardSendLocationFullName.value,
        PostalCode : this.changeRFIDSendCardLocation.controls.rfidCardSendLocationPostalCode.value
      } 
    };

    if ((!this.dataUserInfos.Mobile && this.changePersonalFormGroup.controls.mobileNumber.value) || (this.dataUserInfos.Mobile !== this.changePersonalFormGroup.controls.mobileNumber.value)) {
      await this.sendMobileNumber(inputValues,rfidDetails);
    }else{
      this.updatePersonalInfo(inputValues,false);
      this.updateRFIDCardDetails(rfidDetails);
    }
    
  }

  updateRFIDCardDetails(payload:ClientChangePostalDetails,includeRedirect:boolean = true){
    this.callMassageInfo = null;
    this.callMessageRFIDDetails = "";
    this.displayLoader = true;
    this.disableButton = true;
    const clientChangePostalDetailsRequest = {
      url: '/main_service/api/UserInfos/change-details-address-postal',
      req: payload
    };

    this.requestService.mainPostRequest(clientChangePostalDetailsRequest).subscribe(apiResponse=>{
      const response:APIResult<string> = apiResponse._value;
      console.log("response",response);
      if(!response || !response.Status || !response.Data)
      {
        let error = this.errorCaseService.checkErrorCode(response.Error && response.Error.ErrorCode ? response.Error.ErrorCode : 4);
        if (error?.label) {
          this.callMassageInfo = error?.label;
          this.callMessageRFIDDetails = error?.label;
        }
        this.statusInfo = true;
        this.displayLoader = false;
        this.disableButton = false;
      }else{
       
        this.callMessageRFIDDetails = "change_rfid_details_success_message"
        this.statusInfo = true;
        this.displayLoader = false;
        this.disableButton = false;
        if(includeRedirect){
          timer(4000).pipe(takeWhile(() => this.alive)).subscribe(_ => {
            this.router.navigate(['/account/myAcount'])
          });
        }
      }
    },error=>{
      if (error) {
        this.displayLoader = false;
      }
    });
  }
  //Send Phone Nuber For Display SMS Verification Div
  async sendMobileNumber(objectParam: any,rfidDetails:ClientChangePostalDetails | null = null) {
    this.callMassageInfo = "";
    this.callMessageRFIDDetails = "";
    this.displayLoader = true;
    this.disableButton = true;

    const verfyPhoneNumber = {
      url: '/main_service/api/ClientPaymentMethods/verify-sms',
      req: {
        "Mobile": objectParam.mobile,
      }
    };
    const resp = await this.requestService.mainPostRequest(verfyPhoneNumber).toPromise();
    if (resp._value.Status) 
    {
          if (!resp._value.Data.AlreadyValidated) {
            //Set In Function Service The Object And Token
            this.dataService.setValuesAndToken(objectParam, resp._value.Data.KeyVal);
            if(rfidDetails){
              this.dataService.setSendCardDetails(rfidDetails);
            }
            this.router.navigate(['/account/changePersonalInfo/smsVerification']);
            this.displayLoader = false;
            this.disableButton = false;
          }
    }
     else
    {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.callMassageInfo = error?.label;
            if(rfidDetails)
              this.callMessageRFIDDetails = error?.label;
          }
          this.statusInfo = false;
          this.displayLoader = false;
          this.disableButton = false;
      }
     
    // this.requestService.mainPostRequest(verfyPhoneNumber).subscribe((resp) => {
    //   if (resp._value.Status) {
    //     if (!resp._value.Data.AlreadyValidated) {
    //       //Set In Function Service The Object And Token
    //       this.dataService.setValuesAndToken(objectParam, resp._value.Data.KeyVal);
    //       this.router.navigate(['/account/changePersonalInfo/smsVerification']);
    //       this.displayLoader = false;
    //       this.disableButton = false;
    //     }
    //   } else {
    //     //Error Case
    //     let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
    //     if (error?.label) {
    //       this.callMassageInfo = error?.label;
    //     }
    //     this.statusInfo = false;
    //     this.displayLoader = false;
    //     this.disableButton = false;
    //   }
    // }, throwError => {
    //   if (throwError) {
    //     this.displayLoader = false;
    //   }
    // });

  }

  //Request Send In The Back End The New Data
  updatePersonalInfo(objectParam: any,includeRedirect:boolean = true) {
    this.callMassageInfo = "";
    this.displayLoader = true;
    this.disableButton = true;

    const updatePersonalInfoRequest = {
      url: '/main_service/api/UserInfos/change-details',
      req: {
        "Id": this.userId,
        "FirstName": objectParam.firstName,
        "LastName": objectParam.lastName,
        "Mobile": objectParam.mobile,
        "MobileVer": objectParam.mobileVerification,
        "CarPlate": objectParam.carPlate,
        "TIN": objectParam.afm,
        "Email": this.dataUserInfos.Email,
      }
    };
    this.requestService.mainPostRequest(updatePersonalInfoRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.callMassageInfo = 'change_personal_info_succes_massage';
        this.statusInfo = true;
        this.displayLoader = false;
        this.disableButton = false;

        //If Change Number And It Have SMS Verif Then Set Null In dataService Functions
        if (objectParam.mobileVerification != null) {
          this.dataService.setValuesAndToken(null, null);
          this.dataService.setSmsFinalObject(null);
        }
        if(includeRedirect){
          timer(4000).pipe(takeWhile(() => this.alive)).subscribe(_ => {
            this.router.navigate(['/account/myAcount'])
          })
        }
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.callMassageInfo = error?.label;
        }
        this.statusInfo = false;
        this.displayLoader = false;
        this.disableButton = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  //Check Password If Strong
  checkPassword() {
    this.displayLoaderPassword = true;
    let strongPassowrdCheck = this.strongRegexPassword.test(this.changePasswordFormGroup.controls.password.value)
    if (strongPassowrdCheck == false) {
      this.displayLoaderPassword = false;
    }
    else {
      this.updatePersonalPassword();
    }
  }

  //Request Send In The Back End The New Password Data
  updatePersonalPassword() {
    this.disableButton = true;
    const updatePersonalInfoPasswordRequest = {
      url: '/main_service/api/UserInfos/change-main-password',
      req: {
        "Id": this.userId,
        "Password": this.changePasswordFormGroup.controls.password.value,
        "ValidationPassword": this.changePasswordFormGroup.controls.passwordVerification.value,
        "CurrentPassword": this.changePasswordFormGroup.controls.oldPassword.value
      }
    };
    this.callMassagePassword = ""
    this.requestService.mainPostRequest(updatePersonalInfoPasswordRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.callMassagePassword = 'change_password_succes_massage';
        this.statusPassword = true;
        this.displayLoaderPassword = false;
        this.disableButton = false;
      } else {
        this.statusPassword = false;
        this.displayLoaderPassword = false;
        this.disableButton = false;
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.callMassagePassword = error?.label;
        }
      }
    }, throwError => {
      if (throwError) {
        this.displayLoaderPassword = false;
      }
    });
  }

  submitRFIDSendCardLocation(){
    console.log('submitRFIDSendCardLocation');
  }
  //-------------------- Forms Management Below ------------------------
  getMobilePhoneNumberHasErrors(){
    return !!this.changePersonalFormGroup.controls.mobileNumber.errors;
  }
  getFirstNameHasErrors(){
    return !!this.changePersonalFormGroup.controls.firstName.errors;
  }
  getLastNameHasErrors(){
    return !!this.changePersonalFormGroup.controls.lastName.errors;
  }
  getAFMHasErrors(){
    return !!this.changePersonalFormGroup.controls.afm.errors;
  }
  getCarPlateHasErrors(){
    return !!this.changePersonalFormGroup.controls.carPlate.errors;
  }
  
  //GettersErrorMessagesPersonalInfo
  getErrorMessageOfMobilePhoneNumber(){
    if (this.changePersonalFormGroup.controls.mobileNumber.hasError('required')) {
      return "contact_form_phone_number_error_required";
    }
    if(this.changePersonalFormGroup.controls.mobileNumber.hasError('pattern')){
      return "contact_form_phone_number_error_pattern";
    }
    return "contact_form_error_generic";
    //return this.changePersonalFormGroup.controls.mobileNumber.hasError('email') ? 'Not a valid email' : '';
  }
  getErrorMessageOfFirstName(){
    if (this.changePersonalFormGroup.controls.firstName.hasError('required')) {
      return "contact_form_first_name_error_required";
    }
    return "contact_form_error_generic";
  }
  getErrorMessageOfLastName(){
    if (this.changePersonalFormGroup.controls.lastName.hasError('required')) {
      return "contact_form_last_name_error_required";
    }
    return "contact_form_error_generic";
  }
  getErrorMessageOfAFM(){
    if (this.changePersonalFormGroup.controls.afm.hasError('required')) {
      return "contact_form_afm_error_required";
    }
    if(this.changePersonalFormGroup.controls.afm.hasError('pattern')){
      return "contact_form_afm_error_pattern";
    }
    return "contact_form_error_generic";
  }
  getErrorMessageOfCarPlate(){
    if (this.changePersonalFormGroup.controls.carPlate.hasError('required')) {
      return "contact_form_car_plate_error_required";
    }
    return "contact_form_error_generic";
  }

  getErrorMessageOfFullName(){
    if (this.changeRFIDSendCardLocation.controls.rfidCardSendLocationFullName.hasError('required')) {
      return "rfid_card_send_form_full_name_error_required";
    }
    if(this.changeRFIDSendCardLocation.controls.rfidCardSendLocationFullName.hasError('pattern')){
      return "rfid_card_send_form_full_name_error_pattern";
    }
    return "rfid_card_send_form_error_generic";
  }   
  getErrorMessageOfAddress(){
    if (this.changeRFIDSendCardLocation.controls.rfidCardSendLocationAddress.hasError('required')) {
      return "rfid_card_send_form_address_error_required";
    }
    return "rfid_card_send_form_error_generic";
  }  
  getErrorMessageOfAddressNumber(){
    if (this.changeRFIDSendCardLocation.controls.rfidCardSendLocationAddressNumber.hasError('required')) {
      return "rfid_card_send_form_address_number_error_required";
    }
    if(this.changeRFIDSendCardLocation.controls.rfidCardSendLocationAddressNumber.hasError('pattern')){
      return "rfid_card_send_form_address_number_error_pattern";
    }
    return "rfid_card_send_form_error_generic";
  }  
  getErrorMessageOfCity(){
    if (this.changeRFIDSendCardLocation.controls.rfidCardSendLocationCity.hasError('required')) {
      return "rfid_card_send_form_city_error_required";
    }
    return "rfid_card_send_form_error_generic";
  }  
  getErrorMessageOfPostalCode(){
    if (this.changeRFIDSendCardLocation.controls.rfidCardSendLocationPostalCode.hasError('required')) {
      return "rfid_card_send_form_postal_code_error_required";
    }
    if (this.changeRFIDSendCardLocation.controls.rfidCardSendLocationPostalCode.hasError('pattern')) {
      return "rfid_card_send_form_postal_code_error_pattern";
    }
    return "rfid_card_send_form_error_generic";
  }  
}