import { Component, OnInit, HostListener } from '@angular/core';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { UserService } from 'src/app/services/userService/user.service';
import 'moment/locale/pt-br'
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-charging-records',
  templateUrl: './my-charging-records.component.html',
  styleUrls: ['./my-charging-records.component.less']
})
export class MyChargingRecordsComponent implements OnInit {
  histroyResponse: any;
  screenWidth: any;
  displayTable: boolean = false;
  displayLoader: boolean = true;
  displayEmptyMassage: boolean = false;
  errorMassage: any;
  cardEKO: boolean = false;
  marginBottomPercent: any;
  config: any;
  //public maxSize: number = 5;
  public responsive: boolean = false;
  public labels: any = {
    previousLabel: '',
    nextLabel: '',
  }

  constructor(
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService,
    private userService: UserService,
    public router: Router) {

    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.histroyResponse
    };
  }

  ngOnInit(): void {
    if (this.userService.getUser().FirstTimeInputCard) {
      this.router.navigate(['/account/selectCard']);
    }
    else {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth <= 375) {
        this.responsive = true;
      }

      //On Init Call The Function
      this.charginRecordHistory();

      //Get The Subscription Type And Check If It Is EKO Card 
      if (this.userService.getUser()?.SubscriptionType == 9) {
        this.cardEKO = true;
      }
    }
  }

  //Pagination Evnet Listenner
  pageChanged(event: any) {
    this.config.currentPage = event;
  }

  //Listener For Screen Width
  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenWidth = window.innerWidth;
  }

  //History Data Request
  charginRecordHistory() {
    const historyRequest = {
      url: '/main_service/api/UserInfos/history'
    }
    this.requestService.mainGetRequest(historyRequest).subscribe(resp => {
      if (resp._value.Status) {
        this.histroyResponse = resp._value.Data.CDRS;
        if (this.histroyResponse.length == 0) {
          this.displayLoader = false;
          this.displayEmptyMassage = true;
        }
        else if (this.histroyResponse.length >= 1) {
          this.displayTable = true;
          this.displayLoader = false;

          //Check For Set Margin Bottom Percent Considering The CRDS Length 
          const cdrsLength = this.histroyResponse.length;
          if (cdrsLength > 5) {
            this.marginBottomPercent = 5;
          }
          else if (cdrsLength == 5) {
            this.marginBottomPercent = 12;
          }
          else if (cdrsLength == 4) {
            this.marginBottomPercent = 18;
          }
          else if (cdrsLength == 3) {
            this.marginBottomPercent = 25;
          }
          else if (cdrsLength == 2) {
            this.marginBottomPercent = 30;
          }
          else if (cdrsLength == 1) {
            this.marginBottomPercent = 37;
          }
        }
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }
}