<div mat-dialog-title>
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
</div>
<!-- Dialog Content -->
<div mat-dialog-content class="size">
    <h1>{{'start_over_text_1' | translate}}</h1>
    <h1>{{'start_over_text_2' | translate}}</h1>
    <!-- Buttons -->
    <div class="buttons">
        <button class="logout-btn" (click)="yes()">{{'start_over_button_modal' | translate}}</button>
        <button class="logout-btn mar" mat-dialog-close mat-button>{{'button_cancel' | translate}}</button>
    </div>
</div>