import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Looged In Auth Guard
import { LoogedInAuthGuard } from './loogedInAuthGuard/looged-in-auth.guard';
import { LoogedInAuthGuardFirstTimeInputCard } from './loogedInAuthGuard/looged-in-auth.guard-FirstTimeInputCard';
import { LoogedInAuthGuardChangeBankCard } from './loogedInAuthGuard/looged-in-auth.guard-changeBankCard';

//Components Routes
import { HomeComponent } from './routes/home/home.component';
import { LocationComponent } from './routes/location/location.component';
import { ContactComponent } from './routes/contact/contact.component';
import { RegisterValidationComponent } from './routes/register-validation/register-validation.component';
import { ResetPasswordValidationComponent } from './routes/reset-password-validation/reset-password-validation.component';

//Account Routes
import { Step1SelectCardComponent } from './routes/profile-Routes/step1-select-card/step1-select-card.component';
import { Step2AddDataComponent } from './routes/profile-Routes/step2-add-data/step2-add-data.component';
import { Step4AddBankCardComponent } from './routes/profile-Routes/step4-add-bank-card/step4-add-bank-card.component';
import { MyAcountComponent } from './routes/profile-Routes/my-acount/my-acount.component';
import { MyChargingRecordsComponent } from './routes/profile-Routes/my-charging-records/my-charging-records.component';
import { ChangePersonalInfoComponent } from './routes/profile-Routes/change-personal-info/change-personal-info.component';
import { WelcomeComponent } from './routes/profile-Routes/welcome/welcome.component';
import { SmsVerificationComponent } from './routes/profile-Routes/change-personal-info/sms-verification/sms-verification.component';
import { OneTimePaymentEKOComponent } from './routes/profile-Routes/one-time-payment-eko/one-time-payment-eko.component';
import { LocationPrinterComponent } from './components/locationPrinter/locationPrinter.component';
import { MigrationInfoComponent } from './routes/migration-info/migration-info/migration-info.component';
import { BannerFeatureGuard } from './bannerFeatureGuard/banner-feature-guard';
// import { MigrationInfoComponent } from './routes/migration-info/migration-info/migration-info.component';

const routes: Routes = [
  /* Main Page Application */
  { path: '', component: HomeComponent, runGuardsAndResolvers: 'always' },
  { path: 'location', component: LocationComponent },
  { path:'location/export', component:LocationPrinterComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'validate-email-client/:hash/:uk', component: RegisterValidationComponent },
  { path: 'reset-password-client/:hash', component: ResetPasswordValidationComponent },
  // Route used for migration period
  // { path: 'new-rfid', component: MigrationInfoComponent},
  { path: 'informational', component: MigrationInfoComponent, canActivate:[BannerFeatureGuard]},

  /* Account */
  { path: 'account/selectCard', component: Step1SelectCardComponent , canActivate: [LoogedInAuthGuardFirstTimeInputCard]},
  { path: 'account/addData', component: Step2AddDataComponent , canActivate: [LoogedInAuthGuardFirstTimeInputCard]},
  { path: 'account/changeBankCard', component: Step4AddBankCardComponent , canActivate: [LoogedInAuthGuardChangeBankCard]},
  { path: 'account/myAcount', component: MyAcountComponent , canActivate: [LoogedInAuthGuard]},
  { path: 'account/myChargingRecords', component: MyChargingRecordsComponent , canActivate: [LoogedInAuthGuard]},
  { path: 'account/changePersonalInfo', component: ChangePersonalInfoComponent , canActivate: [LoogedInAuthGuard]},
  { path: 'account/welcome', component: WelcomeComponent , canActivate: [LoogedInAuthGuardFirstTimeInputCard]},
  { path: 'account/changePersonalInfo/smsVerification', component: SmsVerificationComponent , canActivate: [LoogedInAuthGuard]},
  { path: 'account/myAcount/paymentDept', component: OneTimePaymentEKOComponent , canActivate: [LoogedInAuthGuard]},
  { path:'**', redirectTo:''}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
