import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/userService/user.service';
import { RequestService } from 'src/app/services/requestService/request.service';
import { Router} from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.less']
})
export class LogoutDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>, 
    private userService: UserService, 
    private requestService: RequestService, 
    public router: Router) { }

  ngOnInit(): void { }

  // Logout Action Request
  logoutBtn() {
    this.requestService.mainPostRequest({
      url: '/main_service/api/AuthClient/sign-out',
    }).subscribe((resp) => {
      this.userService.onLogout();
      this.router.navigate([""]);
      this.dialogRef.close();
    });
  }

}