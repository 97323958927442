import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-snak-bar',
  templateUrl: './error-snak-bar.component.html',
  styleUrls: ['./error-snak-bar.component.less']
})
export class ErrorSnakBarComponent implements OnInit {
  currentLang: any;
  browserLang: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string, public translate: TranslateService,) { }

  ngOnInit(): void {
    this.currentLang = this.translate.store.currentLang;
    this.browserLang = navigator.language;
  }

  //Refrech Action
  reload() {
    window.location.reload();
  }

}
