import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {fadeAnimation} from './../../../animations/animations.loader';
import { BannerState, SessionStorageService } from 'src/app/services/sessionStorage.service.ts/sessionStorage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-migration-banner',
  templateUrl: './migration-banner.component.html',
  styleUrls: ['./migration-banner.component.less'],
  animations:[
    fadeAnimation('fade', {
      fadeInDuration: '1s',
      fadeOutDuration: '.5s'
    })
  ]
})
export class MigrationBannerComponent implements OnInit,OnDestroy {

  public showBanner:Boolean = true;
  private _sub = new Subscription();
  constructor(private sessionStorageService :SessionStorageService,private router:Router) { }
  ngOnDestroy(): void {
    this.showBanner = false;
    this._sub.unsubscribe();
  }

  ngOnInit() {
    this._sub =  this.sessionStorageService.openMigrationBannerSubject$
              .subscribe((data:BannerState)=>{
                this.showBanner = data === BannerState.OPEN;
              });
  }

  navigateToVerifyDetailsRoute(){
    this.sessionStorageService.determine(BannerState.CLOSE);
    // Changed route from /new-rfid to /informational
    // this.router.navigate(["/new-rfid"]);
    this.router.navigate(["/informational"]);
  }
  hideBanner(){
    this.showBanner = false;
    this.sessionStorageService.determine(BannerState.CLOSE);
  }
}
