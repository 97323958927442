<!-- Dialog Image Bacground -->
<div mat-dialog-title class="bg-image">
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
</div>
<!-- Dialog Content & Form Group -->
<div class="m-start" [formGroup]="registerFormGroup">
    <!-- First Name -->
    <div class="mt-5">
        <p class="leabel-name">{{'contact_form_first_name' | translate}}</p>
        <mat-form-field class="leable-size" appearance="fill">
            <input class="input-size" type="text" matInput maxlength="25" formControlName="firstName">
        </mat-form-field>
    </div>
    <!-- Last Name -->
    <div class="mt-5">
        <p class="leabel-name">{{'contact_form_last_name' | translate}}</p>
        <mat-form-field class="leable-size" appearance="fill">
            <input class="input-size" type="text" matInput maxlength="25" formControlName="lastName">
        </mat-form-field>
    </div>
    <!-- Email -->
    <div class="mt-5">
        <p class="leabel-name">{{'contact_form_email' | translate}}</p>
        <mat-form-field class="leable-size" appearance="fill">
            <input class="input-size" type="email" matInput formControlName="email">
        </mat-form-field>
        <!-- Text -->
        <div class="password-div">
            <p>*{{'register_link_confirmation_text' | translate}}</p>
            <div class="under-line"></div>
        </div>
    </div>
    <!-- Password -->
    <div class="mt-5 password-div">
        <p class="leabel-name">{{'register_password' | translate}}</p>
        <mat-form-field class="leable-size" appearance="fill">
            <input class="input-size" type="password" matInput maxlength="16" [type]="hide ? 'password' : 'text'"
                formControlName="password">
            <!-- Eye -->
            <span class="eye" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
        </mat-form-field>
        <!-- Strong Password -->
        <mat-password-strength class="leable-size" [password]="registerFormGroup.controls.password.value">
        </mat-password-strength>
        <!-- Password Massage Text -->
        <p class="password-info-text" [innerHTML]="'register_massage_strong_password' | translate"></p>
    </div>
    <!-- Password Verification-->
    <div class="mt-5">
        <p class="leabel-name">{{'register_password_verification' | translate}}</p>
        <mat-form-field class="leable-size" appearance="fill">
            <input class="input-size" type="password" matInput maxlength="16"
                [type]="hideVerification ? 'password' : 'text'" matInput formControlName="passwordVerification">
            <!-- Eye -->
            <span class="eye" mat-icon-button matSuffix (click)="hideVerification = !hideVerification"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hideVerification ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
        </mat-form-field>
    </div>
    <!-- Check Box 1 -->
    <div class="mt-3 d-flex">
        <mat-checkbox formControlName="newsLatter" class="box-position"></mat-checkbox>
        <p class="text-box-width" [innerHTML]="'register_check_box_news_latter' | translate"></p>
    </div>
    <!-- Check Box 2 -->
    <div class="mt-3 d-flex">
        <mat-checkbox class="pe-2" formControlName="terms"></mat-checkbox>
        <p [innerHTML]="'register_check_box_terms' | translate"></p>
    </div>
    <!-- Submit Button & Massages -->
    <div class="mt-5 button-position">
        <!-- Loader -->
        <div class="loader-position" *ngIf="displayLoader">
            <mat-spinner class="loader" [diameter]="70"></mat-spinner>
        </div>
        <!-- Massages -->
        <div>
            <p class="error-massage" *ngIf="errorMassage">{{errorMassage | translate}}</p>
            <p class="succses-massage" *ngIf="succsesMassageHead">{{succsesMassageHead | translate}}</p>
            <p class="succses-massage pb-5" *ngIf="succsesMassageHead" [innerHTML]="succsesMassageParag | translate">
            </p>
        </div>
        <!-- Submit Button -->
        <button class="submit" [disabled]="disableButton" (click)="registerCheck()">{{'register_submit' |
            translate}}</button>
    </div>
</div>