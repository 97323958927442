<div mat-dialog-title>
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" [disabled]="disableButton" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
</div>
<!-- Dialog Content -->
<div mat-dialog-content class="size">
    <h1>{{'pay_dept_with_EKO_card_main_text' | translate}}</h1>
    <!-- Buttons -->
    <div class="buttons">
        <button class="button" (click)="yes()">{{'yes' | translate}}</button>
        <button class="button mar" [disabled]="disableButton" mat-dialog-close mat-button>{{'no' | translate}}</button>
    </div>
    <div *ngIf="errorMassage || succsesMassage">
        <p class="error-massage" *ngIf="errorMassage">{{errorMassage | translate}}</p>
        <p class="succses-massage" *ngIf="succsesMassage">{{succsesMassage | translate}}</p>
    </div>
    <!-- Loader -->
    <div class="loader-position pt-4">
        <mat-spinner class="loader" [diameter]="70" *ngIf="displayLoader"></mat-spinner>
    </div>
</div>