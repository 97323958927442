<!-- All Sections -->
<div class="container-fluid">
    <!-- Section 1 -->
    <div class="row section-1">
        <div class="col text-center sec-1-content">
            <!-- Title Row -->
            <div class="row d-flex justify-content-center">
                <!-- Empty Column -->
                <div class="col-2"></div>
                <!-- Title -->
                <div class="col-6 d-flex justify-content-start">
                    <h1>{{'sec1_title' | translate}}</h1>
                </div>
                <!-- Empty Column -->
                <div class="col-3"></div>
            </div>
            <!-- Buttons Row -->
            <div class="row d-flex justify-content-center">
                <!-- Button 1 -->
                <div class="col-9 col-sm-4">
                    <button class="btn w-100" (click)="scrollFn('aboutUs')">
                        <span>{{'sec1_button_1' | translate}}</span>
                    </button>
                </div>
                <!-- Button 2 -->
                <div class="col-9 col-sm-3 ">
                    <button class="btn w-100" (click)="openRegisterDialog()">
                        <span>{{'sec1_button_2' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Section 2 -->
    <div class="row section-2" id="aboutUs">
        <div class="col-12">
            <div class="sec-2-content">
                <!-- Title -->
                <h1>{{'sec2_title' | translate}}</h1>
                <!-- Text -->
                <p [innerHTML]="'sec2_helleniq_energy_text' | translate"></p>
                <!-- Web Image -->
                <div class="web-logo">
                    <!-- Get-your-new-card-button -->
                    <!-- Commented by requirement change -->
                    <!-- <div class="new-card--container">
                        <button class="btn" (click)="navigateToVerifyDetailsRoute()">
                            <span>{{'sec2_new_card' | translate}}</span>
                        </button>
                    </div> -->
                    <div class="helleniqenergy-logo">
                        <a href="https://www.helleniqenergy.gr/" target="_blanck">
                            <img
                                src="https://www.helleniqenergy.gr/themes/custom/helpe_theme/images/HELLENiQ_Logo_Standard.svg" />
                        </a>
                    </div>
                </div>
                <!-- Mobile Image -->
                <div class="mobile-logo">
                    <div class="mobile-logo--container">
                        <a href="https://www.helleniqenergy.gr/" target="_blanck">
                            <img
                                src="https://www.helleniqenergy.gr/themes/custom/helpe_theme/images/HELLENiQ_Logo_Standard.svg" />
                        </a>
                    </div>
                    <!-- Get-your-new-card-button -->
                    <!-- Commented by requirement change -->
                    <!-- <div class="new-card--container">
                        <button class="btn" (click)="navigateToVerifyDetailsRoute()">
                            <span>{{'sec2_new_card' | translate}}</span>
                        </button>
                    </div> -->
                </div>

            </div>
        </div>
    </div>

    <!-- Section 3 Youtube Video -->
    <div class="row section-video">
        <!-- Text Content -->
        <div class="col-md-6">
            <div class="sec-3-content-1">
                <!-- Title -->
                <div class="text-title">
                    <h1 class="title-size" [innerHTML]="'sec3_title' | translate"></h1>
                </div>
                <!-- Paragraphs -->
                <div class="parag-size">
                    <ul>
                        <li>{{'sec3_parag_1' | translate}}</li>
                        <li>{{'sec3_parag_2' | translate}}</li>
                        <li>{{'sec3_parag_3' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Youtube Video -->
        <div class="col-md-6">
            <div class="video-bg">
                <iframe class="video-frame" *ngIf="idVideo" [src]="idVideo"></iframe>
            </div>
        </div>
    </div>

    <!-- Section 4 -->
    <div class="row section-3">
        <!-- Image Spot Top Under 991 -->
        <div class="col-12 text-center" *ngIf="screenWidth<=991">
            <img src="assets/images/mobile/spotTop.jpg" />
        </div>
        <!-- Title -->
        <div class="col-12 positition-title">
            <h1>{{'section4_title' | translate}}</h1>
        </div>
        <!-- Chargers Web-->
        <div class="row mar-left" *ngIf="screenWidth>=579">
            <!-- Charge-1 -->
            <div class="col-lg-4">
                <div class="charge">
                    <!-- Title -->
                    <h1 class="title-charge">AC Type2</h1>
                    <!-- Image Charge -->
                    <picture>
                        <!-- <source media="(min-width: 1740px)" srcset="assets/images/chargeType1Big.png">
                        <source media="(max-width: 576px)" srcset="assets/images/mobile/chargeType1Mobile.png"> -->
                        <img class="image-charge" src="assets/images/chargeType_1.png">
                    </picture>
                    <!-- Price Without -->
                    <h2 class="charge-price">0.45€ / kWh</h2>
                    <!-- Text Without -->
                    <h3>{{'section4_charages_without' | translate}}</h3>
                    <!-- Line -->
                    <div class="under-color-line-small bor-bo-charge1-cl"></div>
                    <!-- Price With -->
                    <h2 class="charge-price">0.40€ / kWh</h2>
                    <!-- Text With -->
                    <h3>{{'section4_charages_with' | translate}}</h3>
                    <!-- Line -->
                    <div class="under-color-line-big bor-bo-charge1-cl"></div>
                </div>
            </div>
            <!-- Charge-2 -->
            <div class="col-lg-4">
                <div class="charge">
                    <h1 class="title-charge">DC CHAdeMO</h1>
                    <picture>
                        <img class="image-charge" src="assets/images/chargeType_2.png">
                    </picture>
                    <h2 class="charge-price">0.55€ / kWh</h2>
                    <h3>{{'section4_charages_without' | translate}}</h3>
                    <div class="under-color-line-small bor-bo-charge2-cl"></div>
                    <h2 class="charge-price">0.50€ / kWh</h2>
                    <h3>{{'section4_charages_with' | translate}}</h3>
                    <div class="under-color-line-big bor-bo-charge2-cl"></div>
                </div>
            </div>
            <!-- Charge-3 -->
            <div class="col-lg-4">
                <div class="charge">
                    <h1 class="title-charge">DC CCS</h1>
                    <picture>
                        <img class="image-charge" src="assets/images/chargeType_3.png">
                    </picture>
                    <h2 class="charge-price">0.55€ / kWh</h2>
                    <h3>{{'section4_charages_without' | translate}}</h3>
                    <div class="under-color-line-small bor-bo-charge3-cl"></div>
                    <h2 class="charge-price">0.50€ / kWh</h2>
                    <h3>{{'section4_charages_with' | translate}}</h3>
                    <div class="under-color-line-big bor-bo-charge3-cl"></div>
                </div>
            </div>

            <!-- Valid From Date -->
            <div class="valid-from-date">
                <h3>{{'section4_valid_from_date' | translate}}</h3>
            </div>
        </div>

        <!-- Mobile Mode Chargers -->
        <div class="row ms-3 mt-5" *ngIf="screenWidth<=578">
            <!-- Charge 1 -->
            <div class="mobile-charge bg-chrarge-1">
                <!-- 1o Half Image Charge -->
                <div class="fisrt-div-mobile">
                    <img src="assets/images/mobile/chargeType1Mobile.png" />
                </div>
                <!-- 2o Half Info Content -->
                <div class="text-center">
                    <!-- Title -->
                    <h1 class="title-charge">AC Type2</h1>
                    <!-- Price Without -->
                    <h2 class="charge-price">0.45€/kWh</h2>
                    <!-- Text Without -->
                    <h3>{{'section4_charages_without' | translate}}</h3>
                    <!-- Line -->
                    <div class="under-color-line-small bor-bo-charge1-cl"></div>
                    <!-- Price With -->
                    <h2 class="charge-price">0.40€/kWh</h2>
                    <!-- Text With -->
                    <h3>{{'section4_charages_with' | translate}}</h3>
                </div>
            </div>
            <!-- Charge 2 -->
            <div class="mobile-charge bg-chrarge-2">
                <div class="fisrt-div-mobile">
                    <img src="assets/images/mobile/chargeType2Mobile.png" />
                </div>
                <div class="text-center">
                    <h1 class="title-charge">DC CHAdeMO</h1>
                    <h2 class="charge-price">0.55€/kWh</h2>
                    <h3>{{'section4_charages_without' | translate}}</h3>
                    <div class="under-color-line-small bor-bo-charge1-cl"></div>
                    <h2 class="charge-price">0.50€/kWh</h2>
                    <h3>{{'section4_charages_with' | translate}}</h3>
                </div>
            </div>
            <!-- Charge 3 -->
            <div class="mobile-charge bg-chrarge-3">
                <div class="fisrt-div-mobile">
                    <img src="assets/images/mobile/chargeType3Mobile.png" />
                </div>
                <div class="text-center">
                    <h1 class="title-charge">DC CSS</h1>
                    <h2 class="charge-price">0.55€/kWh</h2>
                    <h3>{{'section4_charages_without' | translate}}</h3>
                    <div class="under-color-line-small bor-bo-charge1-cl"></div>
                    <h2 class="charge-price">0.50€/kWh</h2>
                    <h3>{{'section4_charages_with' | translate}}</h3>
                </div>
            </div>

            <!-- Valid From Date -->
            <div class="valid-from-date">
                <h3>{{'section4_valid_from_date' | translate}}</h3>
            </div>
        </div>
        <!-- Image Spot Bottom Under 991 -->
        <div class="col-12 text-center" *ngIf="screenWidth<=991">
            <img src="assets/images/mobile/spotBootom.jpg" />
        </div>
    </div>

    <!-- Section 5 -->
    <div class="row section-4">
        <!-- Image -->
        <div class="col-6">
            <picture>
                <img class="sellphone" src="assets/images/section4App.png" />
            </picture>
        </div>
        <!-- Content -->
        <div class="col-6 se-4-content-text">
            <!-- Line -->
            <h1><span class="underLine" [innerHTML]="'sec5_title' | translate"></span></h1>
            <!-- Bullets -->
            <p><img src="assets/images/bullet.png" /> {{'sec5_bullet_1' | translate}}</p>
            <p><img src="assets/images/bullet.png" /> {{'sec5_bullet_2' | translate}}</p>
            <p><img src="assets/images/bullet.png" /> {{'sec5_bullet_3' | translate}}</p>

            <div class="d-flex">
                <!-- Application Images Download-->
                <a target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.elpefuture.chargengo.app"><img
                        class="download-app" [src]="'assets/images/android-store-' + currentLang +'.png'" /></a>
                <a target="_blank" href="https://apps.apple.com/gr/app/elpefuture-chargengo/id1577161814"><img
                        class="download-app" [src]="'assets/images/apple-store-' + currentLang +'.png'" /></a>

            </div>
        </div>
    </div>

    <!-- Button Scroll Top -->
    <div [ngClass]="{'scrollEnd-position' : scrollEnd}" class="go-top-scroll" (click)="scrollToTop()"
        *ngIf="pageYoffset > 0">
        <img class="arrowTop" src="assets/images/arrowScrollTop.png">
    </div>
</div>