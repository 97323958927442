import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/dataService/data.service';
import { RequestService } from 'src/app/services/requestService/request.service';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-step2-add-data',
  templateUrl: './step2-add-data.component.html',
  styleUrls: ['./step2-add-data.component.less']
})
export class Step2AddDataComponent implements OnInit {
  fillingDataFormGroup: FormGroup;
  displayLoader: boolean = false;
  errorMassage: any;
  formData: any;
  normalCard: boolean = false;
  volvo: boolean = false;
  peugeuot: boolean = false;
  citroenDS: boolean = false;
  ekoBpCard: boolean = false;
  rfidValid: boolean = false;
  displaySendPhoneNumber: boolean = false;
  disableButton: boolean = false;

  constructor(
    private fillingDataFormBuilder: FormBuilder,
    private dataService: DataService,
    private requestService: RequestService,
    public router: Router,
    private scroll: ViewportScroller) {
    this.fillingDataFormGroup = this.fillingDataFormBuilder.group({
      companyName: ['', [Validators.required]],
      afm: ['', [Validators.required, Validators.maxLength(9), Validators.pattern("^[0-9]*$")]],
      carPlate: ['', [Validators.required, Validators.maxLength(10)]],
      fullName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      addressNumber: ['', [Validators.required, Validators.maxLength(6)]],
      city: ['', [Validators.required]],
      postalCode: ['', [Validators.required, Validators.maxLength(5), Validators.pattern("^[0-9]*$")]],
      rfIdCardGr: ['GR', [Validators.required]],
      rfIdCardGfx: ['HEM', [Validators.required]],
      rfIdCardC: ['C', [Validators.required]],
      rfIdCardNumber: ['', [Validators.required]],
      rfIdCardLastChar: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    //Get Witch Card Is Selected
    this.getSelectCard();

    //Get Subject To Display Component
    this.dataService.step2ComponentMethodCallSourceObservable.subscribe(() => {
      this.displaySendPhoneNumber = false;
    });
  }

  //Read Wicth Card Selected
  getSelectCard() {
    const selectedCardParameter = this.dataService.getSelectedCard();
    if (selectedCardParameter === 'NormalCard') {
      this.normalCard = true;
    }
    else if (selectedCardParameter === 'VolvoCard') {
      this.volvo = true;
    }
    else if (selectedCardParameter === 'PeugeutCard') {
      this.peugeuot = true;
    }
    else if (selectedCardParameter === 'DSCard') {
      this.citroenDS = true;
    }
    else if (selectedCardParameter === 'EkoBpCard') {
      this.ekoBpCard = true;
    }
    // else if (selectedCardParameter == undefined) {
    //   this.router.navigate(['/account/selectCard']);
    // }
  }

  //Validation Function For Volvo Peugeot If RFID Card Is OK
  rfIdCardValidationTrue() {
    this.rfidValid = true;
    this.inputsCheck();
  }

  //Check If Inputs Is Empty Else Set Values In Object
  inputsCheck() {
    this.errorMassage = "";
    const rfidInputs = this.fillingDataFormGroup.controls;
    //All Form Validation
    if (!rfidInputs.afm.valid || !rfidInputs.carPlate.valid || !rfidInputs.fullName.valid || !rfidInputs.address.valid || !rfidInputs.addressNumber.valid || !rfidInputs.city.valid || !rfidInputs.postalCode.valid) {
      this.errorMassage = "register_error_fields";
      this.scroll.scrollToAnchor('errorMassage');
    }
    //Volvo Validation
    else if (this.volvo && !this.rfidValid) {
      if (!rfidInputs.rfIdCardGr.valid || !rfidInputs.rfIdCardGfx.valid || !rfidInputs.rfIdCardC.valid || !rfidInputs.rfIdCardNumber.valid || !rfidInputs.rfIdCardLastChar.valid) {
        this.errorMassage = "volvo_validation_error";
        this.scroll.scrollToAnchor('errorMassage');
      } else {
        this.rfIdCardValidationTrue();
      }
    }
    //Peugeot Validation
    else if (this.peugeuot && !this.rfidValid) {
      if (!rfidInputs.rfIdCardGr.valid || !rfidInputs.rfIdCardGfx.valid || !rfidInputs.rfIdCardC.valid || !rfidInputs.rfIdCardNumber.valid || !rfidInputs.rfIdCardLastChar.valid) {
        this.errorMassage = "peugeuot_validation_error";
        this.scroll.scrollToAnchor('errorMassage');
      } else {
        this.rfIdCardValidationTrue();
      }
    }
    //DS Validation
    else if (this.citroenDS && !this.rfidValid) {
      if (!rfidInputs.rfIdCardGr.valid || !rfidInputs.rfIdCardGfx.valid || !rfidInputs.rfIdCardC.valid || !rfidInputs.rfIdCardNumber.valid || !rfidInputs.rfIdCardLastChar.valid) {
        this.errorMassage = "ds_validation_error";
        this.scroll.scrollToAnchor('errorMassage');
      } else {
        this.rfIdCardValidationTrue();
      }
    }
    // EKO - BP Input Company Name Validation
    else if (this.ekoBpCard && !rfidInputs.companyName.valid) {
      this.errorMassage = "register_error_fields";
      this.scroll.scrollToAnchor('errorMassage');
    }
    else {
      this.displayLoader = true;
      const rfidResult = rfidInputs.rfIdCardGr.value + '-' + rfidInputs.rfIdCardGfx.value + '-' + rfidInputs.rfIdCardC.value + rfidInputs.rfIdCardNumber.value + '-' + rfidInputs.rfIdCardLastChar.value
      if (this.normalCard) {
        this.leabelsAllData(null, null);
      }
      else if (this.volvo) {
        this.sendVolvoPeugeotRfId(rfidResult, 1);
      }
      else if (this.peugeuot) {
        this.sendVolvoPeugeotRfId(rfidResult, 2);
      }
      else if (this.citroenDS) {
        this.sendVolvoPeugeotRfId(rfidResult, 3);
      }
      else if (this.ekoBpCard) {
        this.leabelsAllData(null, null);
      }
    }
  }

  //Request For Send Volvo Or Peugeot Or DS Card
  sendVolvoPeugeotRfId(RfidNumber: string, RfidType: number) {
    this.disableButton = true;
    this.scroll.scrollToAnchor('loaderDiv');
    const sendRfidRequest = {
      url: '/main_service/api/ClientPaymentMethods/validate-rfid-card',
      req: {
        RfidCardId: RfidNumber,
        RfidCardType: RfidType
      }
    };
    this.requestService.mainPostRequest(sendRfidRequest).subscribe((resp) => {
      if (resp._value.Status) {
        const tokenResult = resp._value.Data;
        this.leabelsAllData(RfidNumber, tokenResult);
        this.disableButton = false;
      }
      else {
        const errorResult = resp._value.Error.ErrorCode;
        if (errorResult == 2) {
          this.errorMassage = "volvo_peugeot_error_unauthorized";
        }
        else if (errorResult == 4) {
          this.errorMassage = "volvo_peugeot_error_InvalidPayload";
        }
        else if (errorResult == 7) {
          this.errorMassage = "volvo_peugeot_error_there_is_no_rfid";
        }
        else if (errorResult == 12) {
          this.errorMassage = "volvo_peugeot_error_already_rfid";
        }
        else if (errorResult == 13) {
          this.errorMassage = "volvo_peugeot_error_already_exist";
        }
        this.displayLoader = false;
        this.disableButton = false;
        this.rfidValid = false;
        this.scroll.scrollToAnchor('errorMassage');
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  //Set The Values From Leabels And Go To Step 3
  leabelsAllData(rfIdNumber: any, tokenResultStringRequest: any) {
    this.formData = {
      companyName: this.fillingDataFormGroup.controls.companyName.value,
      afm: this.fillingDataFormGroup.controls.afm.value,
      carPlate: this.fillingDataFormGroup.controls.carPlate.value,
      fullName: this.fillingDataFormGroup.controls.fullName.value,
      address: this.fillingDataFormGroup.controls.address.value,
      addressNum: this.fillingDataFormGroup.controls.addressNumber.value,
      city: this.fillingDataFormGroup.controls.city.value,
      postalCode: this.fillingDataFormGroup.controls.postalCode.value,
      volvoPeugeotRfIdNumber: rfIdNumber,
      tokenVolvoPeugeot: tokenResultStringRequest
    };
    this.displayLoader = false;
    this.displaySendPhoneNumber = true;
  }

  //Function For Focus If Inputs Are Empty Then Focus In GR Input
  listenerForFocus() {
    if (this.fillingDataFormGroup.controls.rfIdCardGfx.value.length == 0 && document.getElementById("gfxId") === document.activeElement) {
      document.getElementById("grId")!.focus();
      this.handleInput1();
      this.handleInput2();
      this.handleInput3();
      this.handleInput4();
    }
    else if (this.fillingDataFormGroup.controls.rfIdCardC.value.length == 0 && document.getElementById("cId") === document.activeElement) {
      document.getElementById("grId")!.focus();
      this.handleInput1();
      this.handleInput2();
      this.handleInput3();
      this.handleInput4();
    }
    else if (this.fillingDataFormGroup.controls.rfIdCardNumber.value.length == 0 && document.getElementById("numberId") === document.activeElement) {
      document.getElementById("grId")!.focus();
      this.handleInput1();
      this.handleInput2();
      this.handleInput3();
      this.handleInput4();
    }
    else if (this.fillingDataFormGroup.controls.rfIdCardLastChar.value.length == 0 && document.getElementById("lastCharId") === document.activeElement) {
      document.getElementById("grId")!.focus();
      this.handleInput1();
      this.handleInput2();
      this.handleInput3();
      this.handleInput4();
    }
  }

  //Handle Input GR
  handleInput1(): void {
    if (this.fillingDataFormGroup.controls.rfIdCardGr.value.length == 2) {
      document.getElementById("gfxId")!.focus();
    }
  }

  //Handle Input GFX
  handleInput2(): void {
    if (this.fillingDataFormGroup.controls.rfIdCardGfx.value.length == 3) {
      document.getElementById("cId")!.focus();
    }
  }
  //KeyDown GFX
  onKeydown2(event: { key: string; }) {
    if (this.fillingDataFormGroup.controls.rfIdCardGfx.value.length == 0 && event.key === "Backspace") {
      document.getElementById("grId")!.focus();
    }
  }

  //Handle Input C
  handleInput3(): void {
    if (this.fillingDataFormGroup.controls.rfIdCardC.value.length == 1) {
      document.getElementById("numberId")!.focus();
    }
  }
  //KeyDown C
  onKeydown3(event: { key: string; }) {
    if (this.fillingDataFormGroup.controls.rfIdCardC.value.length == 0 && event.key === "Backspace") {
      document.getElementById("gfxId")!.focus();
    }
  }

  //Handle Input Card Number
  handleInput4(): void {
    if (this.fillingDataFormGroup.controls.rfIdCardNumber.value.length == 8) {
      document.getElementById("lastCharId")!.focus();
    }
  }
  //KeyDown Card Number
  onKeydown4(event: { key: string; }) {
    if (this.fillingDataFormGroup.controls.rfIdCardNumber.value.length == 0 && event.key === "Backspace") {
      document.getElementById("cId")!.focus();
    }
  }

  //KeyDown Last Char
  onKeydown5(event: { key: string; }) {
    if (this.fillingDataFormGroup.controls.rfIdCardLastChar.value.length == 0 && event.key === "Backspace") {
      document.getElementById("numberId")!.focus();
    }
  }

}