import { BannerState, SessionStorageService } from 'src/app/services/sessionStorage.service.ts/sessionStorage.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { DialogService } from 'src/app/services/dialogService/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/userService/user.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.less']
})
export class LoginDialogComponent implements OnInit,OnDestroy {
  hideLogin = true;
  loginForm: FormGroup;
  loginErrorMassage: any;
  displayLoader: boolean = false;
  disableButton: boolean = false;
  private redirectToMigration:boolean = false;
  dialogConfig:MatDialogConfig = new MatDialogConfig();
  private readonly _routerSubscription:Subscription= new Subscription();
  constructor(
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private loginFormBuilder: FormBuilder,
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService,
    private userService: UserService,
    private dataService:DataService,
    private sessionStorageService:SessionStorageService,
    private dialogService: DialogService) {
        this.loginForm = this.loginFormBuilder.group({
          userName: [''],
          password: [''],
          rememberMe: [false]
        });
  }
  

  ngOnInit(): void {}
  ngOnDestroy(): void {
    if(this._routerSubscription)
      this._routerSubscription.unsubscribe();
  }
  //On Enter Button Call
  onEnter() {
    this.leabelsCheck()
  }

  //Check The Fields Are Full 
  leabelsCheck() {
    this.loginErrorMassage = ""
    if (!this.loginForm.controls.userName.value && !this.loginForm.controls.password.value) {
      this.loginErrorMassage = "login_error_leabels";
    } else {
      this.displayLoader = true;
      this.disableButton = true;
      this.userLogin();
    }
  }

  //Sent Data The to Back
  userLogin() {
    const loginRequest = {
      url: '/main_service/api/AuthClient/sign-in',
      req: {
        "Email": this.loginForm.controls.userName.value,
        "Password": this.loginForm.controls.password.value,
        "RememberMe": this.loginForm.controls.rememberMe.value
      }
    }
    this.requestService.mainPostRequest(loginRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.userService.getUserInfo().then((respInfo: any) => {
          this.userService.getLoggedInStatus.next(true);
          this.displayLoader = false;
          this.disableButton = false;
          this.dialogRef.close();

          //Check The Router Navigate
          if (resp._value.Data.FirstTimeInputCard) {
              this.dataService.setRedirectionRoute(null);
             this.sessionStorageService.determine(BannerState.CLOSE);
             this.router.navigate(['/account/selectCard']);
             return;
          } 
          const redirectionRoute = this.dataService.getRedirectionRoute();
          if(redirectionRoute){
            this.dataService.setRedirectionRoute(null);
            this.sessionStorageService.determine(BannerState.CLOSE);
            this.router.navigate([redirectionRoute.route],redirectionRoute.navigationParams);
            return;
          }
          //else
          this.router.navigate(['/account/myAcount'])
          
        });
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.loginErrorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false; 
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  };

  //Open Forgot Password Dialog
  openForgotDialog() {
    this.dialogService.openForgotPasswordDialogService();
  }

}