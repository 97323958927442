import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/userService/user.service';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';

@Component({
  selector: 'app-pay-with-eko-card-dept-dialog',
  templateUrl: './pay-with-eko-card-dept-dialog.component.html',
  styleUrls: ['./pay-with-eko-card-dept-dialog.component.less']
})
export class PayWithEKOCardDeptDialogComponent implements OnInit {
  displayLoader: boolean = false;
  disableButton: boolean = false;
  userId: any;
  errorMassage: any;
  succsesMassage: any;

  constructor(public dialogRef: MatDialogRef<PayWithEKOCardDeptDialogComponent>,
    private userService: UserService,
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService) { }

  ngOnInit(): void {
    //Get The User Id
    this.userId = this.userService.getUser().UserId;
  }

  //Call Request For Pay
  yes() {
    this.displayLoader = true;
    this.disableButton = true;
    this.errorMassage = '';

    const payWithEKORequest = {
      url: '/main_service/api/ClientPaymentMethods/clear-dept-instant/' + this.userId
    }
    this.requestService.mainPostRequest(payWithEKORequest).subscribe(resp => {
      if (resp._value.Status) {
        //Proccess Response
        if (resp._value.Data.EkoResolveCase) {
          this.succsesMassage = 'pay_debt_with_EKO_proccess';
          setTimeout(() => {
            this.dialogRef.close({
              data: {
                success: true
              }
            });
          }, 2000)
        }
        //Failed Response
        else if (!resp._value.Data.EkoResolveCase) {
          this.errorMassage = 'pay_debt_failed';
          setTimeout(() => {
            this.dialogRef.close();
          }, 2000)
        }
      } else {
        if (resp._value.Error.ErrorCode == 28) {
          this.succsesMassage = 'pay_debt_with_EKO_completed';
          setTimeout(() => {
            this.dialogRef.close();
            window.location.reload();
          }, 2000)
        }
        else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.errorMassage = error?.label;
          }
        }
      }
      this.displayLoader = false;
      this.disableButton = false;
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

}