import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/userService/user.service';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';

@Component({
  selector: 'app-pay-dept-dialog',
  templateUrl: './pay-dept-dialog.component.html',
  styleUrls: ['./pay-dept-dialog.component.less']
})
export class PayDeptDialogComponent implements OnInit {
  userId: any;
  displayLoader: boolean = true;
  errorMassage: any;
  succsesMassage: any;
  deptTotalPrice: any;
  disableButton: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PayDeptDialogComponent>,
    private userService: UserService,
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService) { }

  ngOnInit(): void {
    //Take The User ID From Info Request
    this.userId = this.userService.getUser().UserId;

    //Call The Function 
    this.charginRecordHistoryTotalDept();
  }

  //History Data Request For Take Total Dept Price
  charginRecordHistoryTotalDept() {
    const historyRequest = {
      url: '/main_service/api/UserInfos/history'
    }
    this.requestService.mainGetRequest(historyRequest).subscribe(resp => {
      if (resp._value.Status) {
        this.deptTotalPrice = resp._value.Data.TotalDept;
        this.disableButton = false;
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
      }
      this.displayLoader = false;
      this.disableButton = false;
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  //Call The Request For Pay The Dept Value
  payDept() {
    this.displayLoader = true;
    //Disable Button
    this.disableButton = true;
    this.errorMassage = ''
    const payDebtReq = {
      url: '/main_service/api/ClientPaymentMethods/clear-dept-instant/' + this.userId
    };
    this.requestService.mainPostRequest(payDebtReq).subscribe((resp) => {
      if (resp._value.Status) {
        this.displayLoader = false;
        let data = resp._value.Data;
        //Succses Response
        if (data.SuccessPayments.length > 0 && data.FailedPayments.length == 0 && data.AlreadyInProccess.length == 0) {
          this.succsesMassage = 'pay_debt_succses';
          setTimeout(() => {
            this.dialogRef.close({
              data: {
                success: true
              }
            });
          }, 2000);
        }
        //Failed Response
        else if (data.FailedPayments.length > 0) {
          this.errorMassage = 'pay_debt_failed'
        }
        //Proccess Response
        else if (data.AlreadyInProccess.length > 0) {
          this.errorMassage = 'pay_debt_proccess'
        }
        this.disableButton = false;
      }
      //Error Case
      else {
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

}