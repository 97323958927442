import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnakBarComponent } from '../../components/dialogs/error-snak-bar/error-snak-bar.component';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    private matSnackBar: MatSnackBar) { }

  // Sent GET Request
  mainGetRequest(params: { url: string; }): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "CAPIKEY": "LFDSGSgdfgdfkmialsnlrgmilsndalgASDGDFhadsfhggs"
      }),
      withCredentials: true
      // params: params
    };
    return this.http.get<any>(environment.apiUrl + params.url, httpOptions).pipe(
      map(data => {
        return new BehaviorSubject(data);
      }),
      catchError(this.handleError.bind(this))
    );
  };

  //Send Post Request
  mainPostRequest(params: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "CAPIKEY": "LFDSGSgdfgdfkmialsnlrgmilsndalgASDGDFhadsfhggs"
      }),
      withCredentials: true
      // params: params
    };
    return this.http.post<any>(environment.apiUrl + params.url, params.req, httpOptions).pipe(
      map(data => {
        return new BehaviorSubject(data);
      }),
      catchError(this.handleError.bind(this))
    );
  };

  //Handle Error
  handleError(error: { error: { message: any; }; status: number; message: any; }) {
    if (error.status != 200) {
      if (error.error instanceof ErrorEvent) {
        this.openMatSnackBar();
      }
      else if (error.status) {
        this.openMatSnackBar();
      }
      else if (error.message) {
        this.openMatSnackBar();
      }
    }
    return throwError(this.translate.instant('Exception_error'));
  };

  //Open Mat Snack Bar
  openMatSnackBar() {
    this.matSnackBar.openFromComponent(ErrorSnakBarComponent);
  }

}