<div class="container">
    <div class="row alignment-top">
        <div class="col-12">
            <!-- Title -->
            <h1 class="steps-title">{{'one_time_payment_eko_title' | translate}}</h1>
            <!-- Line -->
            <div class="d-flex">
                <div class="line"></div>
            </div>
            <!-- Loader -->
            <div class="loader-position" *ngIf="displayLoaderFirst || errorMassageFirst">
                <mat-spinner class="loader" [diameter]="70" *ngIf="displayLoaderFirst"></mat-spinner>
                <!-- Error Massage First-->
                <h1 class="error-massage pad-bot" *ngIf="errorMassageFirst">{{errorMassageFirst | translate}}</h1>

                <!-- Succses Massage Error 28 -->
                <h1 class="succses-massage pad-bot" *ngIf="succsesMassageFirst">{{succsesMassageFirst | translate}}</h1>
            </div>
        </div>

        <div *ngIf="displayForm">
            <!-- Stripe Form Leabels -->
            <form id="setup-form" data-secret="clientKey">
                <div class="row inputs-position">
                    <!-- Input Card Number Leabel-->
                    <div class="col-md-5">
                        <p>{{'bank_card_number' | translate}}</p>
                        <div class="card-leabel card-number" id="card-number"></div>
                    </div>
                    <!-- Input Expiration Date Leabel-->
                    <div class="col-md-4 padding-left">
                        <p>{{'bank_card_date' | translate}}</p>
                        <div class="card-leabel small-width" id="card-exp"></div>
                    </div>
                    <!-- Input Card CVV Leabel-->
                    <div class="col-md-3 mobile-pt">
                        <p>{{'bank_card_cvv' | translate}}</p>
                        <div class="card-leabel small-width" id="card-cvc"></div>
                    </div>
                    <!-- Input Client Holder Name Leabel-->
                    <div class="col-md-5 pt-4">
                        <p>{{'bank_card_name' | translate}}</p>
                        <input class="card-leabel-name" type="text" id="cardholder-name">
                    </div>
                </div>
                <!-- Buttons -->
                <div class="button-top">
                    <!-- Button Next -->
                    <div class="text-center">
                        <!-- Loader After Add Or Change Card-->
                        <div id="loaderDiv" class="loader-position" *ngIf="displayLoader">
                            <mat-spinner class="loader" [diameter]="70"></mat-spinner>
                        </div>
                        <!-- Error Massage -->
                        <p id="errorMassage" class="error-massage" *ngIf="errorMassage">{{errorMassage | translate}}</p>
                        <!-- Succses Massage -->
                        <p id="succsesMassage" class="succses-massage" *ngIf="stripeStatusMessage">{{stripeStatusMessage
                            |
                            translate}}</p>
                        <!-- Functionallity Button Name -->
                        <button id="card-button" class="submit" type="submit" [disabled]="disableButton">{{
                            'button_pay' | translate}}</button>
                    </div>
                    <!-- Button Back -->
                    <div class="text-end">
                        <!-- Cancel Button Go Profile For Change Card -->
                        <button type="button" class="back-route-button" [disabled]="disableButton"
                            [routerLink]="'/account/myAcount'">{{'button_cancel' |
                            translate}}</button>
                    </div>
                </div>
            </form>
        </div>

        <!-- Empty Div -->
        <div class="empt-div" *ngIf="!displayForm"></div>
    </div>
</div>