<section class="location-printer-parent" id="printedTable">
  <table
    matSort
    (matSortChange)="onSortChange($event)"
    id="toBePrinted"
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="No">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.number_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">{{ chargePoint.No }}</td>
    </ng-container>

    <ng-container matColumnDef="Title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.title_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">{{ chargePoint.Title }}</td>
    </ng-container>
    <ng-container matColumnDef="City">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.city_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">{{ chargePoint.City }}</td>
    </ng-container>


    <ng-container matColumnDef="Address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.address_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">{{ chargePoint.Address }}</td>
    </ng-container>

    <ng-container matColumnDef="OpeningTimes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.opening_times_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">
        <div *ngIf="chargePoint.DisplayCustomOpeningTimes.TwentyFourSeven;then content else elseBlock"></div>
        <ng-template #content>
          <div>
            {{
              chargePoint.DisplayCustomOpeningTimes.OpeningTimesString
                | openingTimes
                | translate
            }}
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <ul
            *ngFor="let custom of chargePoint.DisplayCustomOpeningTimes"
            class="charge-points-opening-times__list"
          >
            <li>
              <div class="dosth">
                <label
                  >{{ custom.WeekDay | openingTimes | translate }}
                  <!-- {{ custom.OpeningTimesString }} -->
                  </label
                >
                <label>{{ custom.OpeningTimesString}}</label>
              </div>
            </li>
          </ul>
        </ng-template>
      </td>
    </ng-container>
    <!-- Commented out by requirement -->
    <!-- <ng-container matColumnDef="FullTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.full_title_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">
        {{ chargePoint.FullTitle }}
      </td>
    </ng-container> -->

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.description_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">
        <ul class="charge-points-description__list">
          <li *ngFor="let i of chargePoint.Description">
            <label> {{ i }} </label>
          </li>
        </ul>
      </td>
    </ng-container>

    <ng-container matColumnDef="Active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ "location_printer.active_column" | translate }}
      </th>
      <td mat-cell *matCellDef="let chargePoint">
        {{ chargePoint.Active | activityTranslation | translate }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'row-green-color': row.Active }"
    ></tr>
  </table>
  <div
    class="mat-fab-container"
    [ngClass]="{ 'scrollEnd-position': scrollEnd }"
  >
    <a mat-fab id="printerIcon" (click)="invokeExport()">
      <mat-icon class="material-symbols-outlined"> print </mat-icon>
    </a>
  </div>
  <div
    id="arrowTop"
    [ngClass]="{ 'scrollEnd-position': scrollEnd }"
    class="go-top-scroll"
    (click)="scrollToTop()"
    *ngIf="pageYoffset > 0"
  >
    <img class="arrowTop" src="assets/images/arrowScrollTop.png" />
  </div>
</section>
