import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { RequestService } from 'src/app/services/requestService/request.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmedValidator } from '../../validators/ConfirmedValidator';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';

@Component({
  selector: 'app-reset-password-validation',
  templateUrl: './reset-password-validation.component.html',
  styleUrls: ['./reset-password-validation.component.less']
})
export class ResetPasswordValidationComponent implements OnInit {
  newPassForm: FormGroup;
  hide = true;
  hideVerification = true;
  responseLabelText: any;
  responseLabelError: any;
  displayLoader: boolean = false;
  submitButtonDisplay: boolean = true;
  hash:any;
  vk:any;
  strongRegexPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#\$%\^&\*])(?=.{8,})");
  disableButton: boolean = false;
  displayPasswordForm: boolean = false;

  constructor(
    private newPassFormBuilder: FormBuilder, 
    private activatedRoute: ActivatedRoute, 
    private requestService: RequestService, 
    private errorCaseService: ErrorCasesService, 
    public dialog: MatDialog) {
    this.newPassForm = this.newPassFormBuilder.group({
      password: ['', [Validators.required, Validators.maxLength(20)]],
      passwordVerification: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'passwordVerification')
    });
  }

  ngOnInit(): void {
    //Read The hash Parameter From URL And Call The Function
    this.activatedRoute.params.subscribe(params => {
      this.activatedRoute.snapshot.params['hash'];
    });
    this.getKeys();

    //Disable Button
    this.disableButton = true;
  }

  //Send The hash Parameter In Back End And Back End Return 2 Keys
  getKeys() {
    const req = {
      url: `/main_service/api/authclient/reset/password/validate?hash=${this.activatedRoute.snapshot.params['hash']}`,
    };
    this.requestService.mainGetRequest(req).subscribe((resp) => {
      if(resp._value.Data) {
        this.hash = resp._value.Data.Hash;
        this.vk = resp._value.Data.ValidationHash;
        this.disableButton = false;
        this.displayPasswordForm = true;
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.responseLabelError = error?.label;
        }
        this.disableButton = false;
      }
    });
  }

  //Check If The Password Is Validation And Password Is Strong
  checkPassword() {
    this.responseLabelError = '';
    let strongPassowrdCheck = this.strongRegexPassword.test(this.newPassForm.controls.password.value)
    if (!this.newPassForm.valid) {
      this.responseLabelError = 'register_error_fields';
    }
    else if (strongPassowrdCheck == false) {
      this.responseLabelError = 'register_error_password';
    }
    else {
      this.responseLabelError = '';
      this.displayLoader = true;
      this.disableButton = true;
      this.sendNewPasswrod();
    }
  }

  //Send New Password And Keys In Back End
  sendNewPasswrod() {
    const req = {
      url: '/main_service/api/AuthClient/reset/password/action',
      req: {
        "Password": this.newPassForm.controls.password.value,
        "ValidationPassword": this.newPassForm.controls.passwordVerification.value,
        "ValidationHash": this.vk,
        "Hash": this.hash
      }
    };
    this.requestService.mainPostRequest(req).subscribe((resp) => {
      if (resp._value.Status) {
        this.responseLabelText = 'change_password_succes_massage';
        this.submitButtonDisplay = false;
        this.displayLoader = false;
        this.disableButton = false;
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.responseLabelError = error?.label;
        }
        this.submitButtonDisplay = true;
        this.displayLoader = false;
        this.disableButton = false;
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  //Open Login Dialog
  openLoginDialog() {
    const matDialogId = this.dialog.getDialogById('login-dialog');
    //Check If Dialog Is Open
    if (!matDialogId) {
      this.dialog.open(LoginDialogComponent, { id: 'login-dialog' });
    }
  }

}