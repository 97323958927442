import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorCasesService {
  label: string = "";
  constructor() { }

  checkErrorCode(error: any) {
    switch (error) {

      case 0:
        console.log("Error_0");
        return {
          label: "Exception_error",
        }

      case 1:
        console.log("Error_1");
        return {
          label: "Generic_error",
        }

      case 2:
        console.log("Error_2");
        return {
          label: "Unauthorized_error",
        }

      case 3:
        console.log("Error_3");
        return {
          label: "UnauthorizedRole_error",
        }

      case 4:
        console.log("Error_4");
        return {
          label: "InvalidPayload_error",
        }

      case 5:
        console.log("Error_5");
        return {
          label: "InvalidCredentials_error",
        }

      case 6:
        console.log("Error_6");
        return {
          label: "InvalidInternalVerification_error",
        }

      case 7:
        console.log("Error_7");
        return {
          label: "NotExisting_error",
        }

      case 8:
        console.log("Error_8");
        return {
          label: "AlreadyAuthUser_error",
        }

      case 9:
        console.log("Error_9");
        return {
          label: "EmailExists_error",
        }

      case 10:
        console.log("Error_10");
        return {
          label: "GFXCallError_error",
        }

      case 11:
        console.log("Error_11");
        return {
          label: "NotExistingRFIDCard_erro",
        }

      case 12:
        console.log("Error_12");
        return {
          label: "ExistingClientSubscription_error",
        }

      case 13:
        console.log("Error_13");
        return {
          label: "AlreadyExisting_error",
        }

      case 14:
        console.log("Error_14");
        return {
          label: "NotValidRetailPackage_error",
        }

      case 15:
        console.log("Error_15");
        return {
          label: "CDRHandlerErrorCall_error",
        }

      case 16:
        console.log("Error_16");
        return {
          label: "CASEZERO_error",
        }

      case 21:
        console.log("Error_21");
        return {
          label: "AlreadyEmailValidated_error",
        }

      case 24:
        console.log("Error_24");
        return {
          label: "SmsVerificationExist_error",
        }

      case 28:
        console.log("Error_28");
        return {
          label: "pay_debt_with_EKO_completed",
        }

      case 29:
        console.log("Error_29");
        return {
          label: "pay_debt_with_EKO_proccess",
        }

      default: {
        return {
          label: "Error"
        }
      }
    }
  }
}