<div class="logout-button--container">
    <div mat-dialog-title  class="dialog-close--button">
        <!-- Close Button -->
            <button class="close" mat-dialog-close mat-button>
                <img src="assets/images/x.png" />
            </button> 
    </div>
    <!-- Dialog Content -->
    <div mat-dialog-content class="size">
        <h1>{{'logout_text' | translate}}</h1>
        <!-- Buttons -->
        <div class="buttons">
            <button class="logout-btn" mat-button (click)="logoutBtn()">{{'yes' | translate}}</button>
            <button class="logout-btn" mat-dialog-close mat-button>{{'no' | translate}}</button>
        </div>
    </div>
</div>