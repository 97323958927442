import { Subject } from 'rxjs';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Marker } from 'src/app/interfaces/marker';
// import { AgmInfoWindow } from '@agm/core';
// import { RequestService } from 'src/app/services/requestService/request.service';
import { MapSandbox } from './map.sandbox';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less']
})
export class MapComponent implements OnInit,OnDestroy {
  //Declere Defult Map Position
  lat: number = 37.983810;
  lng: number = 23.727539;
  //Google Maps Zoom Level
  zoom: number = 8;
  //Declere For Charging Points
  points: any;
  markers!: any;
  //Set From Parameter
  previous: any;
  map!: google.maps.MouseEvent;
  private readonly $destroy :Subject<void> = new Subject<void>();
  constructor(
    private mapSandBox : MapSandbox,
    private router : Router
    ) {
     }

  ngOnInit(): void {
    //Call Function For Take The Informations
    this.mapSandBox.getPoints();
    

    this.mapSandBox._data
    .pipe(takeUntil(this.$destroy))
    .subscribe((resp:any) => {
      if(!resp) return;
      this.points = resp.ChargingPoints;
      this.markers = this.points.map((point: any) => {
        return {
          lat: point.X,
          lng: point.Y,
          label: {
            text: ' ',//String(point.Fusions),
            color: '#ffffff',
            fontWeight: 'bold',
          },
          draggable: false,
          icon: {
            url: point.Active ? './assets/images/map/pinComingSoon.svg' : './assets/images/map/pinComingSoon.svg',
            scaledSize: {
              width: 40,
              height: 40
            }
          }
        }
      });
    });
  }
  ngOnDestroy(): void { 
    this.$destroy.complete();
  }
  //Request For Get Informations 
  // getPoints() {
  //   const req = {
  //     url: '/main_service/api/infoinit/charging-locations',
  //   };
  //   this.requestService.mainGetRequest(req).subscribe((resp) => {
  //     if (resp._value.Status) {
  //       //Get All Array From Charging Points
  //       this.points = resp._value.Data.ChargingPoints;
  //       //New Array Where Return My Object With Data
  //       this.markers = this.points.map((point: any) => {
  //         return {
  //           lat: point.X,
  //           lng: point.Y,
  //           label: {
  //             text: ' ',//String(point.Fusions),
  //             color: '#ffffff',
  //             fontWeight: 'bold',
  //           },
  //           draggable: false,
  //           icon: {
  //             url: point.Active ? './assets/images/map/pinComingSoon.svg' : './assets/images/map/pinComingSoon.svg',
  //             scaledSize: {
  //               width: 40,
  //               height: 40
  //             }
  //           }
  //         }
  //       });
  //     }
  //   });
  // }

  //All Event Performed From Mouse 
  mapClicked($event: google.maps.MouseEvent) { }

  navigateToPrinter(){
     this.router.navigate(['/location/export']);
  }
  //Open Marker
  clickedMarker(label: string, index: number, infowindow: any) {
    //Check If Other Marker Is Open
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  //Close Div Marker Function
  closeMarker(infoWindow: any): void {
    if (this.previous) {
      this.previous.close();
    }
  }

  //
  markerDragEnd(m: Marker, $event: google.maps.MouseEvent) { }

}