<div class="container-fluid">
    <div class="row">
        <!-- Text -->
        <div class="col-10">
            <h3 *ngIf="currentLang != undefined">{{'Exception_error' | translate}}</h3>
            <h3 *ngIf="currentLang == undefined && browserLang == 'el-GR'">Προέκυψε σφάλμα διακομιστή.</h3>
            <h3 *ngIf="currentLang == undefined && browserLang == 'en-US'">Server error occured.</h3>
            <h3 *ngIf="currentLang == undefined && browserLang != 'en-US' && browserLang != 'el-GR'">Server error occured.</h3>
        </div>
        <!-- Button -->
        <div class="col-2">
            <button (click)="reload()">OK</button>
        </div>
    </div>
</div>