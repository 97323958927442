import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router} from '@angular/router';

@Component({
  selector: 'app-start-over',
  templateUrl: './start-over.component.html',
  styleUrls: ['./start-over.component.less']
})
export class StartOverComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<StartOverComponent>, public router: Router) { }

  ngOnInit(): void { }

  //Navigate To Step-1
  yes() {
    this.router.navigate(['/account/selectCard']);
    this.dialogRef.close();
  }
}