<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <!-- Logo Image -->
            <div>
                <img class="mt-5" src="assets/images/logoSignIn.png">
            </div>
            <!-- Succses Or Error Image -->
            <img class="mt-5 image-size" src="../assets/images/{{errorCodeLogo}}.png">
            <!-- Massages -->
            <h1 class="error-massage">{{ errorMassage | translate}}</h1>
            <p class="succses-massage">{{ succsesMassage | translate}}</p>
            <!-- Buttons -->
            <button class="submit mar-top" [disabled]="disableButton" *ngIf="buttonDisplay" (click)="openLoginDialog()">{{'gen_login' |
                translate}}</button>
            <button class="submit mar-top" [disabled]="disableButton" *ngIf="!buttonDisplay" routerLink="/">{{'button_back' | translate}}</button>
        </div>
    </div>
</div>