import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import {  NavigationEnd, Router } from '@angular/router';
export interface IBannerState{
  OPEN:BannerState;
  CLOSE:BannerState;
}
export const BannerState:IBannerState ={
  OPEN: 100,
  CLOSE:200
};
// export type BannerState = typeof BannerState[keyof typeof BannerState];
export type BannerState = 100 | 200;

@Injectable({
  providedIn: 'root'
})

export class SessionStorageService implements OnDestroy {
private readonly _openMigrationBannerSubject$ = new BehaviorSubject<BannerState>(BannerState.OPEN);
private _routerSub = new Subscription();
private readonly _bannerExclusionUrlParts:Array<string> = ['account','new-rfid','migration','informational'];
constructor(private router:Router) {
  //Checking if user visits by route the migration page then store is at false,
  // for example if he visits the page directly and then progresses to another page 
  // do not show the banner 
  this._routerSub = this.router.events.subscribe((evt)=>{
    if(evt instanceof NavigationEnd){
      this.determine(this._bannerExclusionUrlParts.includes((evt as NavigationEnd).url.split('/')[1]) ? BannerState.CLOSE : BannerState.OPEN);
    }
  });
  const lSB= sessionStorage.getItem('openBanner')
  let bannerState = BannerState.OPEN;
  if(lSB && lSB.length > 0){
    bannerState = lSB === 'true' ? BannerState.OPEN : BannerState.CLOSE
  }
  this.determine(bannerState)
}
  ngOnDestroy(): void {
    this._routerSub.unsubscribe();
  }

  get openMigrationBannerSubject$():Observable<BannerState>{
    return this._openMigrationBannerSubject$.asObservable();
  }

  changeBannerState(bannerState:boolean){
    if(sessionStorage.getItem('openBanner')){
      sessionStorage.removeItem('openBanner');
    }
    sessionStorage.setItem('openBanner',bannerState.toString().toLowerCase());
  }
  private closeBanner(){
    this.changeBannerState(false);
    this._openMigrationBannerSubject$.next(BannerState.CLOSE);
  }
  private openBanner(){
    this.changeBannerState(true);
    this._openMigrationBannerSubject$.next(BannerState.OPEN);
  }
  determine(bannerState : BannerState){

    switch(bannerState){
      case BannerState.OPEN:
        this.openBanner();
        break;
      case BannerState.CLOSE:
        this.closeBanner();
        break;
      default:
        this.openBanner();
        break;
    }
  }

}
