<div class="migration-banner fixed-top">
    <!-- Commented because migration ended -->
    <!-- If you want to revert it just import it on app.module.ts -->
     <!-- If you want to comment it out just comment the element and comment also app.module.ts -->
    <app-migration-banner *ngIf="enabledBannerThroughConfiguration"></app-migration-banner>
    <nav id="navbar" class="navbar navbar-expand-md navbar-dark scroll-color"
        [ngClass]="{'fixed-top-color' : scrollNavBar, 'login-bg' : loggedIn && currentRoute == 'account', 'siteOtherRouters' : currentRouteSite == '/contact' ||  currentRouteSite == '/location' || currentRoute == 'validate-email-client' || currentRoute == 'reset-password-client'}">
        <div class="container">
            <!-- Mobile Navbar Button -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse"
                [ngClass]="{'collapse-login' : loggedIn && screenWidth>=768 && currentRoute == 'account'}"
                id="navbarCollapse">
                <!-- Routes -->
                <ul class="navbar-nav ms-auto mb-2 mb-md-0">
                    <!-- Home Route -->
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" routerLink="" data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show">{{'gen_home' | translate}}</a>
                    </li>
                    <!-- Map Route -->
                    <li class="nav-item">
                        <a class="nav-link active" routerLink="/location" data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show">{{'gen_charging_points' | translate}}</a>
                    </li>
                    <!--Frequently asked questions docs Route -->
                    <li class="nav-item">
                        <a class="nav-link active" href="{{'header_qa_docs' | translate}}"
                            data-bs-target=".navbar-collapse.show" target="_blank">{{'gen_qa_docs' | translate}}</a>
                    </li>
                    <!-- Contact Route -->
                    <li class="nav-item">
                        <a class="nav-link active" routerLink="/contact" data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show">{{'gen_contact' | translate}}</a>
                    </li>
                    <!-- Drop Down User Icon -->
                    <li class="nav-item dropdown" *ngIf="screenWidth>=768">
                        <a class="nav-link dropdown-toggle" id="dropMenu" href="#" role="button"
                            data-bs-toggle="dropdown"></a>
                        <!-- Drop Down Menu -->
                        <ul class="dropdown-menu" [ngClass]="{'dropdown-menu-login' : loggedIn}">
                            <!-- Logout Buttons-->
                            <div *ngIf="loggedIn == false">
                                <!-- Login Dialog -->
                                <li><a class="dropdown-item" (click)="openLoginDialog()">{{'gen_login' | translate}}</a>
                                </li>
                                <!-- Line -->
                                <div class="green-line"></div>
                                <!-- Register Dialog -->
                                <li><a class="dropdown-item" (click)="openRegisterDialog()">{{'gen_register' |
                                        translate}}</a></li>
                            </div>
                            <!-- Login Buttons -->
                            <div *ngIf="loggedIn">
                                <!-- My Profile Route-->
                                <li><a class="dropdown-item" *ngIf="!inputCard"
                                        routerLink="/account/myAcount">{{'gen_profile' | translate}}</a></li>
                                <div class="green-line" *ngIf="!inputCard"></div>
                                <!-- My Charges Records Route-->
                                <li><a class="dropdown-item" *ngIf="!inputCard"
                                        routerLink="/account/myChargingRecords">{{'gen_cdrs_charges' | translate}}</a>
                                </li>
                                <div class="green-line" *ngIf="!inputCard"></div>
                                <!-- Change Personal Info Route-->
                                <li><a class="dropdown-item" *ngIf="!inputCard" routerLink="/account/changePersonalInfo"
                                        [innerHTML]="'gen_change_personal' | translate"></a></li>
                                <div class="green-line" *ngIf="!inputCard && cardEKO != 9"></div>
                                <!-- Change Bank Card Route-->
                                <li *ngIf="cardEKO != 9"><a class="dropdown-item" *ngIf="!inputCard"
                                        routerLink="/account/changeBankCard"
                                        [innerHTML]="'gen_change_bank_card' | translate"></a></li>
                                <!-- Logout Dialog-->
                                <li class="logout-bg" [ngClass]="{'mar-top' : inputCard }"><a
                                        class="dropdown-item logout-bold" (click)="openLogoutDialog()">{{'gen_logout' |
                                        translate}}</a></li>
                            </div>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Change Lang Button -->
        <div class="d-flex padd-bot"
            [ngClass]="{'collapse-login-lang' : loggedIn && screenWidth>=768 && currentRoute == 'account', 'collapse-login-lang-mobile' : loggedIn && screenWidth<=768 }">
            <a class="nav-link lang" (click)="changeLanguage()"> {{'btn_language' | translate}} </a>
            <!-- User Icon Button Mobile  -->
            <ul class="navbar-nav ms-auto mb-2 mb-md-0 mobile-icon" *ngIf="screenWidth<=768">
                <!-- Drop Down User Icon -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="dropMenu" href="#" role="button"
                        data-bs-toggle="dropdown"></a>
                    <ul class="dropdown-menu" [ngClass]="{'dropdown-menu-login' : loggedIn}">
                        <!-- Logout Buttons-->
                        <div *ngIf="loggedIn == false">
                            <!-- Login Dialog -->
                            <li><a class="dropdown-item" (click)="openLoginDialog()">{{'gen_login' | translate}}</a>
                            </li>
                            <!-- Line -->
                            <div class="green-line"></div>
                            <!-- Register Dialog -->
                            <li><a class="dropdown-item" (click)="openRegisterDialog()">{{'gen_register' |
                                    translate}}</a>
                            </li>
                        </div>
                        <!-- Login Buttons -->
                        <div *ngIf="loggedIn">
                            <!-- My Profile Route-->
                            <li><a class="dropdown-item" *ngIf="!inputCard"
                                    routerLink="/account/myAcount">{{'gen_profile' |
                                    translate}}</a></li>
                            <div class="green-line" *ngIf="!inputCard"></div>
                            <!-- My Charges Records Route-->
                            <li><a class="dropdown-item" *ngIf="!inputCard"
                                    routerLink="/account/myChargingRecords">{{'gen_cdrs_charges' | translate}}</a></li>
                            <div class="green-line" *ngIf="!inputCard"></div>
                            <!-- Change Personal Info Route-->
                            <li><a class="dropdown-item" *ngIf="!inputCard" routerLink="/account/changePersonalInfo"
                                    [innerHTML]="'gen_change_personal' | translate"></a></li>
                            <div class="green-line" *ngIf="!inputCard && cardEKO != 9"></div>
                            <!-- Change Bank Card Route-->
                            <li *ngIf="cardEKO != 9"><a class="dropdown-item" *ngIf="!inputCard"
                                    routerLink="/account/changeBankCard"
                                    [innerHTML]="'gen_change_bank_card' | translate"></a></li>
                            <!-- Logout Dialog-->
                            <li class="logout-bg" [ngClass]="{'mar-top' : inputCard }"><a
                                    class="dropdown-item logout-bold" (click)="openLogoutDialog()">{{'gen_logout' |
                                    translate}}</a></li>
                        </div>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</div>