<!-- Section 2 -->
<div class="row section-2" id="aboutUs">
    <div class="col-12">
        <div class="sec-2-content">
            <!-- Title -->
            <h1>{{'migration_title' | translate}}</h1>
            <!-- Text -->
            <p>{{'migration_helleniq_energy_text_second_part' | translate}}</p>
            <p [innerHTML]="'migration_helleniq_energy_text_first_part' | translate"></p>
            <p>{{'migration_helleniq_energy_text_third_part' | translate}}</p>
            <p [innerHTML]="'migration_helleniq_energy_text_fourth_part' | translate"></p>
            <!-- <p>{{'migration_helleniq_energy_text_fourth_part' | translate}}<a type=button style="font-weight: bolder; text-decoration: none; color: blue; font-size: 1vw; line-height: initial;text-align: justify;" (click)="navigateToVerifyDetailsRoute()">{{'migration_helleniq_energy_text_fifth_part'|translate}}</a></p>            -->
            <!-- <p>{{'migration_helleniq_energy_text_fifth_part' | translate}}<a type=button
                    style="font-weight: bolder; text-decoration: none; color: blue; line-height: initial;text-align: justify;"
                    (click)="navigateToProfileAnchor()">{{'migration_helleniq_energy_text_sixth_part'|translate}}</a> -->
            <!-- </p> -->
            <!-- <p>{{'migration_helleniq_energy_text_seventh_part' | translate}}</p>
            <p>{{'migration_helleniq_energy_text_eighth_part' | translate}}</p> -->
            <p [innerHTML]="'migration_helleniq_energy_text_nineth_part' | translate"></p>
             <p [innerHTML]="'migration_helleniq_energy_text_tenth_part' | translate"></p>
            <!--<p>{{'migration_helleniq_energy_text_eleventh_part' | translate}}</p>
            <p>{{'migration_helleniq_energy_text_twelveth_part' | translate}}<a type=button
                    style="font-weight: bolder; text-decoration: none; color: blue; line-height: initial;text-align: justify;"
                    (click)="navigateToVerifyDetailsRoute()">{{'migration_helleniq_energy_text_sixth_part'|translate}}</a> -->
            <!-- </p> -->
            <!-- Web Image -->
            <div class="web-logo">
                <!-- Get-your-new-card-button -->
                <!-- Commented by requirements change -->
                <!-- <div class="new-card--container">
                    <button class="btn" (click)="navigateToVerifyDetailsRoute()">
                        <span>{{'migration_new_card' | translate}}</span>
                    </button>
                </div> -->
                <div class="helleniqenergy-logo">
                    <a href="https://www.helleniqenergy.gr/" target="_blanck">
                        <img
                            src="https://www.helleniqenergy.gr/themes/custom/helpe_theme/images/HELLENiQ_Logo_Standard.svg" />
                    </a>
                </div>
            </div>
            <!-- Mobile Image -->
            <div class="mobile-logo">
                <div class="mobile-logo--container">
                    <a href="https://www.helleniqenergy.gr/" target="_blanck">
                        <img
                            src="https://www.helleniqenergy.gr/themes/custom/helpe_theme/images/HELLENiQ_Logo_Standard.svg" />
                    </a>
                </div>
                <!-- Get-your-new-card-button -->
                <!-- Commented by requirements change -->
                <!-- <div class="new-card--container">
                    <button class="btn" (click)="navigateToVerifyDetailsRoute()">
                        <span>{{'migration_new_card' | translate}}</span>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</div>