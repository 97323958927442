<div class="container">
    <div class="row" *ngIf="displayPasswordForm">
        <!-- Image -->
        <div class="col-12 text-center">
            <img src="assets/images/logoSignIn.png" />
        </div>
        <!-- Empty Div -->
        <div class="col-lg-4"></div>
        <!-- Form Group -->
        <div class="col-lg-8" [formGroup]="newPassForm">
            <!-- Password -->
            <div class="mt-5 password-div">
                <p class="leabel-name">{{'new_password' | translate}}</p>
                <mat-form-field class="leable-size" appearance="fill">
                    <input class="input-size" type="password" matInput [type]="hide ? 'password' : 'text'"
                        formControlName="password">
                    <!-- Eye -->
                    <span class="eye" mat-icon-button matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                </mat-form-field>
                <!-- Strong Password -->
                <div class="bar-width">
                    <mat-password-strength class="leable-size" [password]="newPassForm.controls.password.value">
                    </mat-password-strength>
                </div>
                <!-- Password Massage Text -->
                <p [innerHTML]="'register_massage_strong_password' | translate"></p>
            </div>
            <!-- Password Verification-->
            <div class="mt-5">
                <p class="leabel-name">{{'new_password_verification' | translate}}</p>
                <mat-form-field class="leable-size" appearance="fill">
                    <input class="input-size" type="password" matInput [type]="hideVerification ? 'password' : 'text'"
                        matInput formControlName="passwordVerification">
                    <!-- Eye -->
                    <span class="eye" mat-icon-button matSuffix (click)="hideVerification = !hideVerification"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        <mat-icon>{{hideVerification ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class="col-12 text-center">
            <!-- Massages -->
            <h1 class="error-massage" *ngIf="responseLabelError">{{ responseLabelError | translate}}</h1>
            <p class="succses-massage" *ngIf="responseLabelText">{{ responseLabelText | translate}}</p>
            <!-- Loader -->
            <div class="loader-position">
                <mat-spinner class="loader" [diameter]="70" *ngIf="displayLoader"></mat-spinner>
            </div>
            <!-- Buttons -->
            <button class="submit mt-3" [disabled]="disableButton" *ngIf="submitButtonDisplay"
                (click)="checkPassword()">{{'submit_finish' |
                translate}}</button>
            <button class="submit mt-3" [disabled]="disableButton" *ngIf="!submitButtonDisplay"
                (click)="openLoginDialog()">{{'gen_login' |
                translate}}</button>
        </div>
    </div>

    <div class="row" *ngIf="!displayPasswordForm">
        <div class="col-12 text-center">
            <div>
                <img src="assets/images/logoSignIn.png">
            </div>
            
            <img class="mt-5 error-image" src="../../../assets/images/error.png">
            <h1 class="error-massage" *ngIf="responseLabelError">{{ responseLabelError | translate}}</h1>

            <div class="mt-5">
                <button class="submit" routerLink="/">{{'button_back' | translate}}</button>
            </div>
        </div>
    </div>
</div>