<div class="container">
    <div class="row margin-top">
        <div class="col-12">
            <!-- Functionallity Title -->
            <h1 class="steps-title" *ngIf="inputCard">{{'steps_add_bank_card' | translate}}</h1>
            <h1 class="steps-title" *ngIf="!inputCard">{{'change_bank_card' | translate}}</h1>
            <!-- Number And Line -->
            <div class="d-flex">
                <button *ngIf="inputCard" class="btn circle-step">4</button>
                <div class="line"></div>
            </div>
            <!-- Loader -->
            <div class="loader-position">
                <mat-spinner class="loader" [diameter]="70" *ngIf="displayLoaderFirst"></mat-spinner>
                <!-- Error Massage First-->
                <h1 class="error-massage pad-bot">{{errorMassageFirst | translate}}</h1>
            </div>
        </div>

        <div *ngIf="displayForm">
            <!-- Stripe Form Leabels -->
            <form id="setup-form" data-secret="clientKey">
                <div class="row inputs-position">
                    <!-- Input Card Number Leabel-->
                    <div class="col-md-5">
                        <p>{{'bank_card_number' | translate}}</p>
                        <div class="card-leabel card-number" id="card-number"></div>
                    </div>
                    <!-- Input Expiration Date Leabel-->
                    <div class="col-md-4 padding-left">
                        <p>{{'bank_card_date' | translate}}</p>
                        <div class="card-leabel small-width" id="card-exp"></div>
                    </div>
                    <!-- Input Card CVV Leabel-->
                    <div class="col-md-3 mobile-pt">
                        <p>{{'bank_card_cvv' | translate}}</p>
                        <div class="card-leabel small-width" id="card-cvc"></div>
                    </div>
                    <!-- Input Client Holder Name Leabel-->
                    <div class="col-md-5 pt-4">
                        <p>{{'bank_card_name' | translate}}</p>
                        <input class="card-leabel-name" type="text" id="cardholder-name">
                        <!-- placeholder="{{'bank_card_holder_name_placeholder' | translate}}" -->
                    </div>
                    <!-- Stripe Information Text -->
                    <div class="col-12 mt-5">
                        <p [innerHTML]="'stripe_information_text' | translate"></p>
                    </div>
                </div>
                <!-- Buttons -->
                <div class="button-top">
                    <!-- Button Next -->
                    <div class="text-center">

                        <!-- Error Massage -->
                        <p id="errorMassage" class="error-massage" *ngIf="errorMassage">{{errorMassage | translate}}</p>

                        <!-- Succses Massage -->
                        <p id="succsesMassage" class="succses-massage" *ngIf="stripeStatusMessage">{{stripeStatusMessage
                            | translate}}</p>

                        <!-- Loader After Add Or Change Card-->
                        <div id="loaderDiv" class="loader-position" *ngIf="displayLoader">
                            <mat-spinner class="loader" [diameter]="70"></mat-spinner>
                        </div>

                        <!-- Functionallity Button Name -->
                        <button class="submit" type="submit" [disabled]="disableButton" id="card-button">{{ nameButton |
                            translate}}</button>
                    </div>
                    <!-- Button Back -->
                    <div class="text-end">
                        <!-- Open Start Over Dialog For Add First Time Add Card-->
                        <button type="button" class="back-route-button" *ngIf="inputCard" [disabled]="disableButton"
                            (click)="openStartOverDialog($event)">{{'start_over_button' |
                            translate}}</button>
                        <!-- Cancel Button Go Profile For Change Card -->
                        <button type="button" class="back-route-button" *ngIf="!inputCard" [disabled]="disableButton"
                            [routerLink]="'/account/myAcount'">{{'button_cancel' |
                            translate}}</button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>