<div class="container" style="min-width: 320px !important;" *ngIf="!displaySendPhoneNumber">
    <div class="row">
        <div class="col-12">
            <!-- Title -->
            <h1 class="steps-title">{{'steps_add-data' | translate}}</h1>
            <!-- Number And Line -->
            <div class="d-flex">
                <button class="btn circle-step">2</button>
                <div class="line"></div>
            </div>
        </div>
        <!-- Form -->
        <div class="col-12 left-position" [formGroup]="fillingDataFormGroup">
            <!-- Company Name -->
            <div class="mt-3" *ngIf="ekoBpCard">
                <p class="leabel-name">{{'step_2_company_name' | translate}}</p>
                <mat-form-field class="leable-size" appearance="fill">
                    <input class="input-size" type="text" matInput formControlName="companyName">
                </mat-form-field>
            </div>
            <!-- AFM -->
            <div [ngClass]="{'mt-3' : !ekoBpCard, 'mt-4' : ekoBpCard }">
                <div class="d-flex">
                    <p class="leabel-name" *ngIf="!ekoBpCard">{{'step_2_afm' | translate}}</p>
                    <p class="leabel-name" *ngIf="ekoBpCard">{{'step_2_afm_company' | translate}}</p>
                </div>
                <mat-form-field class="leable-size" appearance="fill">
                    <input class="input-size" type="text" maxlength="9" matInput formControlName="afm">
                </mat-form-field>
            </div>
            <!-- Car Plate -->
            <div class="mt-4">
                <p class="leabel-name">{{'step_2_car_plate' | translate}}</p>
                <mat-form-field class="leable-size" appearance="fill">
                    <input class="input-size" type="text" maxlength="10" matInput formControlName="carPlate">
                </mat-form-field>
            </div>
            <!-- Volvo And Peugeot Input-->
            <div class="mt-4  " *ngIf="peugeuot || volvo || citroenDS">
                <p class="leabel-name">{{'step_2_volvo_peugeot_rfid' | translate}}</p>
                <div class="leable-size volvo-pg-ds-div gap-1 justify-content-start d-flex rfid-bg"
                    (click)="listenerForFocus()">
                    <!-- First GR -->
                    <input class="input-size gr-style" placeholder="GR" type="text" matInput
                        formControlName="rfIdCardGr" maxlength="2" id="grId" (input)="handleInput1()">
                    <span class="example-tel-input-spacer">&ndash;</span>
                    <!-- Second GFX -->
                    <input class="input-size gfx-style" placeholder="HEM" type="text" matInput
                        formControlName="rfIdCardGfx" maxlength="3" id="gfxId" (keydown)="onKeydown2($event)"
                        (input)="handleInput2()">
                    <span class="example-tel-input-spacer">&ndash;</span>
                    <!-- Third C -->
                    <input class="input-size c-style" placeholder="C" type="text" matInput formControlName="rfIdCardC"
                        maxlength="1" id="cId" (keydown)="onKeydown3($event)" (input)="handleInput3()">
                    <!-- Fourth Number -->
                    <input class="input-size number-style" onInput="this.parentNode.dataset.value = this.value"
                        type="text" placeholder="12345678" matInput formControlName="rfIdCardNumber" minlength="8"
                        maxlength="8" id="numberId" (keydown)="onKeydown4($event)" (input)="handleInput4()">
                    <span class="example-tel-input-spacer">&ndash;</span>
                    <!-- Five Last Char -->
                    <input class="input-size last-digit-style" placeholder="1" type="text" matInput
                        formControlName="rfIdCardLastChar" maxlength="1" id="lastCharId" (keydown)="onKeydown5($event)">
                </div>
                <!-- Under Line For Input -->
                <div class="input-line"></div>
            </div>
            <!-- Address Text -->
            <div class="address-text">
                <p>{{'step_2_address_text' | translate}}</p>
            </div>
            <!-- Full Name -->
            <div class="mt-4">
                <p class="leabel-name">{{'step_2_full_name' | translate}}</p>
                <mat-form-field class="leable-size full-name-width" appearance="fill">
                    <input class="input-size" type="text" matInput formControlName="fullName">
                </mat-form-field>
            </div>
            <!-- Address -->
            <div class="double-input">
                <div>
                    <p class="leabel-name">{{'step_2_address' | translate}}</p>
                    <mat-form-field class="leable-size address-width" appearance="fill">
                        <input class="input-size" type="text" matInput formControlName="address">
                    </mat-form-field>
                </div>
                <!-- Addres Number -->
                <div class="margin-left">
                    <p class="leabel-name">{{'step_2_address_number' | translate}}</p>
                    <mat-form-field class="leable-size add-number-width" appearance="fill">
                        <input class="input-size" type="text" maxlength="6" matInput formControlName="addressNumber">
                    </mat-form-field>
                </div>
            </div>
            <!-- City -->
            <div class="double-input">
                <div>
                    <p class="leabel-name">{{'step_2_city' | translate}}</p>
                    <mat-form-field class="leable-size city-width" appearance="fill">
                        <input class="input-size" type="text" matInput formControlName="city">
                    </mat-form-field>
                </div>
                <!-- Postal Code -->
                <div class="margin-left">
                    <p class="leabel-name">{{'step_2_postal_code' | translate}}</p>
                    <mat-form-field class="leable-size add-number-width" appearance="fill">
                        <input class="input-size" type="text" maxlength="5" matInput formControlName="postalCode">
                    </mat-form-field>
                </div>
            </div>
            <!-- Buttons -->
            <div class="button-top">
                <!-- Loader -->
                <div id="loaderDiv" class="loader-position" *ngIf="displayLoader">
                    <mat-spinner class="loader" [diameter]="70"></mat-spinner>
                </div>
                <!-- Error Massage -->
                <div class="text-center" *ngIf="errorMassage">
                    <p id="errorMassage" class="error-massage">{{errorMassage | translate}}</p>
                </div>
                <!-- Button Next -->
                <div class="text-center">
                    <button class="submit" [disabled]="disableButton" (click)="inputsCheck()">{{'submit_next' |
                        translate}}</button>
                </div>
                <!-- Button Back -->
                <div class="text-end">
                    <button class="back-route-button" [disabled]="disableButton"
                        [routerLink]="'/account/selectCard'">{{'button_back' |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Send Phone Number And Sms Verification Component -->
<app-step3-sms-verification *ngIf="displaySendPhoneNumber" [formDataResponse]="formData"></app-step3-sms-verification>