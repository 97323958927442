import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-succes-change-card',
  templateUrl: './succes-change-card.component.html',
  styleUrls: ['./succes-change-card.component.less']
})
export class SuccesChangeCardComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SuccesChangeCardComponent>) { }

  ngOnInit(): void { }

}