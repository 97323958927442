<div class="container">
    <div class="row margin-top">
        <div class="col-12">
            <!-- Title -->
            <h1 class="steps-title">{{'steps_add_EKO_card' | translate}}</h1>
            <!-- Number And Line -->
            <div class="d-flex">
                <button class="btn circle-step">4</button>
                <div class="line"></div>
            </div>
        </div>
        <!-- Form -->
        <div class="col-12 mt-3 left-position" [formGroup]="fillingDataFormGroupEKO">
            <!-- EKO Card Number -->
            <div class="mt-3">
                <p class="leabel-name">{{'step_3_EKO_card_number' | translate}}</p>
                <mat-form-field class="leable-size" appearance="fill">
                    <input class="input-size" type="text" matInput formControlName="ekoCardNumber">
                </mat-form-field>
            </div>
            <!-- Selected Publisher EKO Or BP -->
            <div class="mt-4">
                <p class="leabel-name">{{'step_3_EKO_publisher' | translate}}</p>
                <mat-form-field class="leable-size" appearance="fill">
                    <mat-select class="input-size" [(value)]="selected">
                        <mat-option value="EKO">EKO</mat-option>
                        <mat-option value="BP">BP</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- Information Text -->
            <div class="mt-5">
                <p class="info-text" [innerHTML]="'step_3_insert_fuel_card_information_text' | translate"></p>
            </div>
        </div>
        <!-- Button Submit -->
        <div class="col-12 mt-5 text-center">
            <!-- Loader -->
            <div id="loaderDiv" class="loader-position" *ngIf="displayLoader">
                <mat-spinner class="loader" [diameter]="70"></mat-spinner>
            </div>
            <!-- Error Massage -->
            <p id="errorMassage" class="error-massage" *ngIf="errorMassage">{{errorMassage | translate}}</p>
            <!-- Submit button -->
            <button class="submit" [disabled]="disableButton" (click)="sendCardDataValid()">{{'submit_finish' |
                translate}}</button>
        </div>
        <!-- Button Back -->
        <div class="text-end">
            <button class="back-route-button" [disabled]="disableButton"
                (click)="openStartOverDialog($event)">{{'start_over_button' |
                translate}}</button>
        </div>
    </div>
</div>