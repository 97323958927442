<!-- AGM Map -->
<div class="mat-fab-overlay__container">
  <a mat-fab (click)="navigateToPrinter()">
    <mat-icon class="material-symbols-outlined">
    print
    </mat-icon>
  </a>
</div>
<agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="true" (mapClick)="mapClicked($event)" [minZoom]="6" [maxZoom]="20">
  <!-- AGM Markers (Pinezes)-->
  <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label"
    (markerClick)="clickedMarker(m.label, i,infoWindow)" [markerDraggable]="m.draggable"
    (dragEnd)="markerDragEnd(m, $event)" [iconUrl]="m.icon">
    <!-- AGM Info Window Click -->
    <agm-info-window #infoWindow>
      <div class="m-3">
        <div class="box-size-pop-up">
          <!-- Button (X) Close -->
          <button class="close-pop-up-btn" (click)="closeMarker(infoWindow)">x</button>
          <!-- Header Title -->
          <h3>{{points[i].FullTitle}}</h3>
          <!-- Address -->
          <div>{{points[i].Address}}</div>
          <!-- Content -->
          <div class="mt-4 mb-2" style="margin: 0;" *ngFor="let theItemDetail of points[i].AvailableChargerTypeDetails; let j = index">
            <div class="row kwACDC" style="margin: 0;">
              <div class="col-3">

                <!-- Available -->
                <!-- AC Type 2 -->
                <img *ngIf="theItemDetail.ChargerType==0  && points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/ACType2-ComingSoon.svg">
                <!-- DC CCS2 -->
                <img *ngIf="theItemDetail.ChargerType==2 && points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/DCCCS-ComingSoon.svg">
                <!-- DC CHAdeMO -->
                <img *ngIf="theItemDetail.ChargerType==1 && points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/DCCHAdeMO-ComingSoon.svg">
                <!-- DC OTHER -->
                <img *ngIf="theItemDetail.ChargerType==3 && points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/DCCHAdeMO-ComingSoon.svg">

                  
                <!-- Comming Soon -->
                <!-- AC Type 2 -->
                <img *ngIf="theItemDetail.ChargerType==0 && !points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/ACType2-ComingSoon.svg">
                <!-- DC CCS2 -->
                <img *ngIf="theItemDetail.ChargerType==2 && !points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/DCCCS-ComingSoon.svg">
                <!-- DC CHAdeMO -->
                <img *ngIf="theItemDetail.ChargerType==1 && !points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/DCCHAdeMO-ComingSoon.svg">
                <!-- DC OTHER -->
                <img *ngIf="theItemDetail.ChargerType==3 && !points[i].Active" class="window-chargers-logo"
                  src="assets/images/map/DCCHAdeMO-ComingSoon.svg">
              </div>

              <!-- Details -->
              <div class="col" [ngClass]="{'border-bottom': j < points[i].AvailableChargerTypeDetails.length - 1 }">
                <p class="m-0 p-0" id="serialNumber">{{theItemDetail.Description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </agm-info-window>
  </agm-marker>
</agm-map>