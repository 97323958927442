
<div class="wrapper">
    <!-- Content Wrapper -->
    <div class="content-wrapper">
        <!-- Main Content -->
        <div class="content">
            <!-- Routed views go here -->
            <router-outlet class="m-0 p-0">
                <app-navbar></app-navbar>
            </router-outlet>
            <!-- Footer -->
            <app-footer></app-footer>
        </div>
    </div>
</div>
