<div class="container">
    <div class="row margin-top" [ngStyle]="{ 'margin-bottom': marginBottomPercent + '%' }">

        <!-- Title And Under Line -->
        <div class="col-12">
            <h1 class="title">{{'gen_cdrs_charges' | translate}}</h1>
            <div class="line"></div>

            <!-- Loader -->
            <div class="loader-position">
                <mat-spinner class="loader" [diameter]="70" *ngIf="displayLoader"></mat-spinner>
                <h1 class="error-massage pad-bot">{{errorMassage | translate}}</h1>
            </div>

            
        </div>

        <!-- Mat Accordion -->
        <mat-accordion style="min-height: 930px;" *ngIf="displayTable">

            <!-- Location And Date -->
            <div class="row titles-bg">
                <div class="col-8 location-position" [ngClass]="{'col-8' : screenWidth>=520, 'col-6': screenWidth<520}">
                    {{'my_charger_location' | translate}}</div>
                <div class="col-4" [ngClass]="{'date-position-eko' : cardEKO, 'date-position': !cardEKO}">
                    {{'my_charger_date' | translate}}</div>
            </div>

            <mat-expansion-panel class="mat-elevation-z py-2 mt-2 mar-right"
                *ngFor="let contentPanel of histroyResponse | paginate: config">

                <!-- Header -->
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" class="header-bg">
                    <mat-panel-title class="correct-position">
                        <div class="title-height" [ngClass]="{'col-8' : screenWidth>=520, 'col-6': screenWidth<520}">
                            {{contentPanel.LocationOfCharge.Address}}</div>
                        <div class="col-4 title-height"
                            [ngClass]="{'alignment-date-transaction' : cardEKO && screenWidth >= 1400}">
                            {{contentPanel.Date |momentPipe:'DD/MM/YYYY'}}</div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- Content -->
                <div class="row details-bg">
                    <div
                        [ngClass]="{'col-3' : !cardEKO || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460}">
                        {{'my_charger_power' | translate}}</div>
                    <div
                        [ngClass]="{'col-3' : !cardEKO || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460}">
                        {{'my_charger_time' | translate}}</div>
                    <div
                        [ngClass]="{'col-3' : !cardEKO && screenWidth < 560 || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460 || !cardEKO && screenWidth > 560}">
                        {{'my_charger_price' | translate}}</div>
                    <div
                        [ngClass]="{'col-3' : !cardEKO || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460 , 'text-center' : cardEKO || !cardEKO && screenWidth < 560}">
                        {{'my_charger_evidance' | translate}}</div>
                    <div class="col-3 text-center" *ngIf="cardEKO && screenWidth > 460">
                        {{'my_charger_transaction_receipt' | translate}}</div>
                </div>

                <!-- Content Values -->
                <div class="row details-info-bg">
                    <div
                        [ngClass]="{'col-3' : !cardEKO || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460}">
                        {{contentPanel.KWh}}</div>
                    <div
                        [ngClass]="{'col-3' : !cardEKO || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460}">
                        {{contentPanel.TotalTime}}</div>
                    <div
                        [ngClass]="{'col-3' : !cardEKO && screenWidth < 560 || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460 || !cardEKO && screenWidth > 560}">
                        {{contentPanel.Price}}€</div>
                    <div
                        [ngClass]="{'col-3' : !cardEKO || cardEKO && screenWidth < 460, 'col-2': cardEKO && screenWidth > 460, 'text-center' : cardEKO || !cardEKO && screenWidth < 560, 'pad-left' : !cardEKO && screenWidth > 560,  '': contentPanel.InvoiceUrl == null && contentPanel.NoValidForPayment == false && screenWidth>992, 'mobile-display': contentPanel.InvoiceUrl == null && contentPanel.NoValidForPayment == false && screenWidth<992}">
                        <!-- Invoice Url -->
                        <a *ngIf="contentPanel.InvoiceUrl" href={{contentPanel.InvoiceUrl}} target="blank">
                            <img class="pdf-width" *ngIf="contentPanel.InvoiceUrl"
                                src="assets/images/pdf-icon.png" /></a>

                        <!-- Display Invoice Massage -->
                        <p [ngClass]="{'mobile-left' : screenWidth<992, '': screenWidth>992}"
                            *ngIf="contentPanel.InvoiceUrl == null && contentPanel.NoValidForPayment == false">
                            {{'my_charger_invoice_null_massage' | translate}}</p>

                        <!-- Display Empty Invoice Url -->
                        <a class="cursor-no"
                            *ngIf="contentPanel.InvoiceUrl == null && contentPanel.NoValidForPayment == true">
                            <img class="pdf-width" src="assets/images/pdf-icon.png" /></a>
                    </div>

                    <!-- EKO Transaction Receipt Over 460 -->
                    <div class="col-3 text-center" *ngIf="cardEKO && screenWidth > 460">
                        <a *ngIf="contentPanel.InvoiceProofUrl" href={{contentPanel.InvoiceProofUrl}} target="blank">
                            <img class="pdf-width" *ngIf="contentPanel.InvoiceProofUrl"
                                src="assets/images/pdf-icon.png" /></a>

                        <!-- Display Empty EKO Transaction Receipt -->
                        <a class="cursor-no" *ngIf="contentPanel.InvoiceProofUrl == null">
                            <img class="pdf-width" src="assets/images/pdf-icon.png" /></a>
                    </div>

                    <!-- EKO Transaction Receipt For Mobile Under 460-->
                    <div class="col-6 mt-4 text-center" *ngIf="cardEKO && screenWidth < 460">
                        <h4 class="transaction-receipt-mobile">{{'my_charger_transaction_receipt' | translate}}</h4>

                        <!-- Display EKO Transaction Receipt -->
                        <a *ngIf="contentPanel.InvoiceProofUrl" href={{contentPanel.InvoiceProofUrl}} target="blank">
                            <img class="pdf-width" *ngIf="contentPanel.InvoiceProofUrl"
                                src="assets/images/pdf-icon.png" /></a>

                        <!-- Display Empty EKO Transaction Receipt -->
                        <a class="cursor-no" *ngIf="contentPanel.InvoiceProofUrl == null">
                            <img class="pdf-width" src="assets/images/pdf-icon.png" /></a>
                    </div>
                </div>
            </mat-expansion-panel>

            <div class="pagination-div">
                <pagination-controls [responsive]="responsive"
                    [previousLabel]="labels.previousLabel" [nextLabel]="labels.nextLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </mat-accordion>

        <!-- Empty Massage -->
        <div class="col-12 epty-style" *ngIf="!displayTable && !displayLoader">
            <h1 *ngIf="displayEmptyMassage">{{'my_chargers_empty_text' | translate}}</h1>
        </div>
    </div>
</div>