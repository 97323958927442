import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/userService/user.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoogedInAuthGuardFirstTimeInputCard implements CanActivate {
    constructor(private userService: UserService, public router: Router) { }
    canActivate() {
        if (this.userService.isLoggedIn() == true && this.userService.getUser().FirstTimeInputCard == false) {
            this.router.navigate(['/account/myAcount']);
            return false;
        }
        else if (this.userService.isLoggedIn() == false && this.userService.getUser() == null) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}