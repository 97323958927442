import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';

@Component({
  selector: 'app-register-validation',
  templateUrl: './register-validation.component.html',
  styleUrls: ['./register-validation.component.less']
})
export class RegisterValidationComponent implements OnInit {
  errorCodeLogo: string = "";
  succsesMassage: string = "";
  errorMassage: string = "";
  buttonDisplay: boolean = false;
  disableButton: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    //With activatedRoute.params Read The hash and uk Values From URL And Call Function
    this.activatedRoute.params.subscribe(params => {
      this.activatedRoute.snapshot.params['hash'];
      this.activatedRoute.snapshot.params['uk'];
      this.sendParams();
      //Disable Button
      this.disableButton = true;
    });
  }

  //Send Hash And UKey Values In Back End
  sendParams() {
    const req = {
      url: '/main_service/api/AuthClient/validate/email',
      req: {
        "Hash": this.activatedRoute.snapshot.params['hash'],
        "Key": this.activatedRoute.snapshot.params['uk']
      }
    };
    this.requestService.mainPostRequest(req).subscribe((resp) => {
      if (resp._value.Status) {
        this.errorCodeLogo = 'succses';
        this.succsesMassage = 'valid_register_parag';
        this.buttonDisplay = true;
        this.disableButton = false;
      } else {
        this.errorCodeLogo = 'error';
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.buttonDisplay = false;
        this.disableButton = false;
      }
    });
  }

  //Open Login Dialog
  openLoginDialog() {
    const matDialogId = this.dialog.getDialogById('login-dialog');
    //Check If Dialog Is Open
    if (!matDialogId) {
      this.dialog.open(LoginDialogComponent, { id: 'login-dialog' });
    }
  }

}