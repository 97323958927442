import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmedValidator } from '../../../validators/ConfirmedValidator';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgRecaptcha3Service } from 'ng-recaptcha3'

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.less']
})
export class RegisterDialogComponent implements OnInit {
  registerFormGroup: FormGroup;
  hide = true;
  hideVerification = true;
  errorMassage: any;
  succsesMassageHead: any;
  succsesMassageParag: any;
  strongRegexPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#\$%\^&\*])(?=.{8,})");
  displayLoader: boolean = false;
  language: any;
  private siteKey = environment.recapchaKey;
  disableButton: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogComponent>,
    private registerFormBuilder: FormBuilder,
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService,
    private translate: TranslateService,
    public router: Router,
    private recaptcha3: NgRecaptcha3Service) {

    this.registerFormGroup = this.registerFormBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(25)]],
      lastName: ['', [Validators.required, Validators.maxLength(25)]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.maxLength(16), Validators.minLength(8)]],
      passwordVerification: ['', [Validators.required, Validators.maxLength(16), Validators.minLength(8)]],
      newsLatter: [''],
      terms: ['', [Validators.required]],
      currentLang: []
    }, {
      validator: ConfirmedValidator('password', 'passwordVerification')
    });

    //Close Dialog Then Router Change
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.dialogRef.close();
      }
    });
  }

  ngOnInit(): void {
    this.recaptcha3.init(this.siteKey).then(status => { });
  }

  public ngOnDestroy() {
    this.recaptcha3.destroy();
  }

  //Check Input And Password Strong
  registerCheck() {

    this.errorMassage = "";
    this.language = this.translate.currentLang
    let strongPassowrdCheck = this.strongRegexPassword.test(this.registerFormGroup.controls.password.value);
    const first = this.registerFormGroup.controls;
    if (!first.firstName.valid || !first.lastName.valid || !first.email.valid || !first.password.valid || !first.terms.valid) {
        this.errorMassage = "register_error_fields";
    }
    else if (strongPassowrdCheck == false) {
      this.errorMassage = "register_error_password";
    }
    else if (!first.passwordVerification.valid) {
      this.errorMassage = "register_error_password_verification";
    }
    else {
      this.errorMassage = "";
      this.displayLoader = true;
      this.disableButton = true;
      this.sendRegister();
    }
  }

  //Send Data In Back End
  sendRegister() {
    this.recaptcha3.getToken().then(token => {
      const registerRequest = {
        url: '/main_service/api/AuthClient/register',
        req: {
          "FirstName": this.registerFormGroup.controls.firstName.value,
          "LastName": this.registerFormGroup.controls.lastName.value,
          "Email": this.registerFormGroup.controls.email.value,
          "Password": this.registerFormGroup.controls.password.value,
          "ValidationPassword": this.registerFormGroup.controls.passwordVerification.value,
          "Terms": this.registerFormGroup.controls.terms.value,
          "Newsletter": this.registerFormGroup.controls.newsLatter.value,
          "Language": this.language,
          "Token": token
        }
      };
      this.requestService.mainPostRequest(registerRequest).subscribe((resp) => {
        if (resp._value.Status) {
          //Clear Leabels
          this.registerFormGroup.patchValue({
            name: null,
            lastName: null,
            email: null,
            password: null,
            passwordVerification: null,
            terms: null,
            newsLatter: null
          })
          this.registerFormGroup.reset();
          this.displayLoader = false;
          this.disableButton = false;
          this.succsesMassageHead = 'register_successfully_header';
          this.succsesMassageParag = 'register_successfully_parag';
        } else {
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.errorMassage = error?.label;
          }
          this.displayLoader = false;
          this.disableButton = false;
        }
      }, throwError => {
        if (throwError) {
          this.displayLoader = false;
        }
      });

    })
  }

}