import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/requestService/request.service';
import { DataService } from 'src/app/services/dataService/data.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { Router } from '@angular/router';
import { timer } from 'rxjs'
import { takeWhile } from 'rxjs/operators'
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-step3-sms-verification',
  templateUrl: './step3-sms-verification.component.html',
  styleUrls: ['./step3-sms-verification.component.less']
})
export class Step3SmsVerificationComponent implements OnInit {
  phoneNumberFormGroup: FormGroup;
  @Input() formDataResponse: any;
  errorMassage: any;
  displayInputSMS: boolean = false;
  displayStripe: boolean = false;
  displayEKO: boolean = false;
  displayLoader: boolean = false;
  displayLoaderSms: boolean = false;
  selectedCardParameter: any;
  phoneNumberKeyValToken: any;
  sentPhoneNumber: any;
  alive: boolean = true;
  disableButton: boolean = false;
 
  constructor(
    private phoneNumberFormBuilder: FormBuilder,
    private requestService: RequestService,
    private dataService: DataService,
    private errorCaseService: ErrorCasesService,
    private scroll: ViewportScroller,
    public router: Router) {
    this.phoneNumberFormGroup = this.phoneNumberFormBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_1: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_2: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_3: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_4: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_5: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
      smsVerificationNumber_6: ['', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]],
    });
  }

  ngOnInit(): void {
    //Read Which Card Is Selected
    this.selectedCardParameter = this.dataService.getSelectedCard();
  }

  //Send Phone Number
  sendNumber(resendParam: boolean) {
    this.errorMassage = "";
    if (!this.phoneNumberFormGroup.controls.phoneNumber.valid) {
      this.errorMassage = "step_3_error_phone_number_field";
    }
    else {
      //If Resend Sms Then Reset Input Values
      if (resendParam) {
        this.phoneNumberFormGroup.get('smsVerificationNumber_1')?.reset();
        this.phoneNumberFormGroup.get('smsVerificationNumber_2')?.reset();
        this.phoneNumberFormGroup.get('smsVerificationNumber_3')?.reset();
        this.phoneNumberFormGroup.get('smsVerificationNumber_4')?.reset();
        this.phoneNumberFormGroup.get('smsVerificationNumber_5')?.reset();
        this.phoneNumberFormGroup.get('smsVerificationNumber_6')?.reset();
      }
      this.disableButton = true;
      this.displayLoader = true;
      this.scroll.scrollToAnchor('loaderDiv');
      const phoneNumberRequest = {
        url: '/main_service/api/ClientPaymentMethods/verify-sms',
        req: {
          "Mobile": this.phoneNumberFormGroup.controls.phoneNumber.value
        }
      }
      this.requestService.mainPostRequest(phoneNumberRequest).subscribe((resp) => {
        if (resp._value.Status) {
          //Check If AlreadyValidated Is True
          if (!resp._value.Data.AlreadyValidated) {
            // Token When Send In Next Request
            this.phoneNumberKeyValToken = resp._value.Data.KeyVal;

            //Set Phone Number To Display Inside SMS Div
            this.sentPhoneNumber = this.phoneNumberFormGroup.controls.phoneNumber.value;
            this.errorMassage = "";
            this.displayLoader = false;
            this.disableButton = false;
            this.displayInputSMS = true;
            this.scroll.scrollToPosition([0, 0]);

            //For Focus The First Input From Sms Verification Div
            setTimeout(() => {
              document.getElementById("number_1")!.focus();
            }, 100);
          }
          else if (resp._value.Data.AlreadyValidated) {
            //Set In Token The KeyVal
            this.formDataResponse.smsToken = resp._value.Data.KeyVal;

            //Set Phone Number In Object
            this.formDataResponse.phoneNumber = this.phoneNumberFormGroup.controls.phoneNumber.value;

            //Read Withd Card Is Selected
            const typeCard = this.selectedCardParameter;
              if (typeCard == 'NormalCard' || typeCard == 'VolvoCard' || typeCard == 'PeugeutCard' || typeCard == 'DSCard') {
                this.displayStripe = true;
              }
              else if (typeCard == 'EkoBpCard') {
                this.displayEKO = true;
              }
              else if (typeCard == undefined) {
                this.router.navigate(['/account/selectCard']);
              }
          }
          else {
            this.errorMassage = 'alreadyValidated_verify_sms_false_error';
            this.displayLoader = false;
            this.disableButton = false;
          }
        } else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.errorMassage = error?.label;
          }
          //Scroll To Error Massage
          if (!resendParam) {
            this.scroll.scrollToAnchor('errorMassage');
          }
          this.displayLoader = false;
          this.disableButton = false;
        }
      }, throwError => {
        if (throwError) {
          this.displayLoader = false;
        }
      });
    }
  }

  //Send Sms Code
  sendSmsCode() {
    this.errorMassage = "";
    if (!this.phoneNumberFormGroup.valid) {
      this.errorMassage = "sms_verification_error_digit_code";
    }
    else {
      this.displayLoaderSms = true;
      this.disableButton = true;
      this.scroll.scrollToAnchor('loaderDiv');
      const smsVerificationCode = this.phoneNumberFormGroup.controls.smsVerificationNumber_1.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_2.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_3.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_4.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_5.value + this.phoneNumberFormGroup.controls.smsVerificationNumber_6.value;
      const smsCodeRequest = {
        url: '/main_service/api/ClientPaymentMethods/verify-sms-final',
        req: {
          "Mobile": this.sentPhoneNumber,
          "Code": smsVerificationCode,
          "Part": this.phoneNumberKeyValToken
        }
      }
      this.requestService.mainPostRequest(smsCodeRequest).subscribe((resp) => {
        if (resp._value.Status) {
          //Check If Data Is Null
          if (resp._value.Data == null) {
            this.errorMassage = 'alreadyValidated_verify_sms_false_error';
          }
          else {
            //Set Token In Object
            this.formDataResponse.smsToken = resp._value.Data;

            //Set Phone Number In Object
            this.formDataResponse.phoneNumber = this.phoneNumberFormGroup.controls.phoneNumber.value;
           
            //After 4 Seconds Display Step 4 Component Depending On The Type Of Card
            timer(1500).pipe(takeWhile(() => this.alive)).subscribe(_ => {
              this.displayLoaderSms = false;
              this.disableButton = false;
              this.errorMassage = "";

              //Read Withd Card Is Selected
              const typeCard = this.selectedCardParameter;
              if (typeCard == 'NormalCard' || typeCard == 'VolvoCard' || typeCard == 'PeugeutCard' || typeCard == 'DSCard') {
                this.displayStripe = true;
              }
              else if (typeCard == 'EkoBpCard') {
                this.displayEKO = true;
              }
              else if (typeCard == undefined) {
                this.router.navigate(['/account/selectCard']);
              }
            })
          }
        } else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.errorMassage = error?.label;

            //Focus The Last Input 
            setTimeout(() => {
              document.getElementById("number_4")!.focus();
            }, 100);
          }
          this.displayLoaderSms = false;
          this.disableButton = false;
        }
      }, throwError => {
        if (throwError) {
          this.displayLoaderSms = false;
        }
      });
    }
  }

  //Listener Input 1
  numberInput1(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_1.value.length == 1) {
      document.getElementById("number_2")!.focus();
    }
  }

  //Listener Input 2
  numberInput2(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_2.value.length == 1) {
      document.getElementById("number_3")!.focus();
    }
  }

  //Listener Input 3
  numberInput3(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_3.value.length == 1) {
      document.getElementById("number_4")!.focus();
    }
  }

  //Listener Input 4
  numberInput4(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_4.value.length == 1) {
      document.getElementById("number_5")!.focus();
    }
  }

  //Listener Input 5
  numberInput5(): void {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_5.value.length == 1) {
      document.getElementById("number_6")!.focus();
    }
  }

  //onKeyDown Listener 1
  onKeydown1(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_2.value == 0 && event.key === "Backspace") {
      document.getElementById("number_1")!.focus();
    }
  }

  //onKeyDown Listener 2
  onKeydown2(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_3.value == 0 && event.key === "Backspace") {
      document.getElementById("number_2")!.focus();
    }
  }

  //onKeyDown Listener 3
  onKeydown3(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_4.value == 0 && event.key === "Backspace") {
      document.getElementById("number_3")!.focus();
    }
  }

   //onKeyDown Listener 4
   onKeydown4(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_5.value == 0 && event.key === "Backspace") {
      document.getElementById("number_4")!.focus();
    }
  }

  //onKeyDown Listener 5
  onKeydown5(event: { key: string; }) {
    if (this.phoneNumberFormGroup.controls.smsVerificationNumber_6.value == 0 && event.key === "Backspace") {
      document.getElementById("number_5")!.focus();
    }
  }

  //Back Button Display Step 2 Component
  backButton() {
    this.dataService.displayStep2ComponentMethod()
  }

}