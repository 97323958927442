import { Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'activityTranslation'
})
export class ActivityTranslationPipe implements PipeTransform {
  constructor() { 

  }
  transform(value: boolean, args?: any): string {
    return `location_printer.active_field_${value}`;
    //return this.translatePipe.instant(`location_printer`)[`active_field_${value}`];
  }

}
