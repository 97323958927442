import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '../services/userService/user.service';
import { LoginDialogComponent } from '../components/dialogs/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from '../services/dialogService/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class LoogedInAuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public router: Router,
    private dialogService:DialogService
  ) {}
  async canActivate() {
    if (this.userService.isLoggedIn() == true) {
      return true;
    }
    //console.log("passed again with",this.router.url);
    //await this.delay(1000);
    setTimeout(() => {
      this.dialog.open(LoginDialogComponent);
      // this.dialogService.openLoginDialogService(
      // // {
      // //   migrated:true
      // // }
      // );
    }, 1000);
    this.router.navigate(['/']);
    return false;
  }

  async delay(ms: number):Promise<void> {
    return await new Promise((resolve) => setTimeout(resolve, ms));
  }
}
