import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { RegisterDialogComponent } from '../../components/dialogs/register-dialog/register-dialog.component';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';
import { ForgotPasswordDialogComponent } from '../../components/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { LogoutDialogComponent } from '../../components/dialogs/logout-dialog/logout-dialog.component';
import { StartOverComponent } from '../../components/dialogs/start-over/start-over.component';
import { SuccesChangeCardComponent } from '../../components/dialogs/succes-change-card/succes-change-card.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogRef: any;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private scroll: ViewportScroller) { }

  //Open Register Dialog
  openRegisterDialogService() {
    if (this.dialogRef?.id != "register-dialog" || this.dialogRef?.id == "register-dialog") {
      if (this.dialogRef?.id != "register-dialog") {
        this.dialogRef?.close();
      }
      this.dialogRef = this.dialog.open(RegisterDialogComponent, { id: 'register-dialog', disableClose: true });
    }
  }

  //Open Login Dialog
  openLoginDialogService(params:any = null) {
    //Check Dialog Id
    if (this.dialogRef?.id != "login-dialog" || this.dialogRef?.id == "login-dialog") {
      //Close Previous Dialog
      if (this.dialogRef?.id != "login-dialog") {
        this.dialogRef?.close();
      }
      //Open Dialog
      const dialogConfig = { id: 'login-dialog', disableClose: true,data:params };
      console.log('dialogConfig',dialogConfig);
      //this.dialogRef = this.dialog.open(LoginDialogComponent, { id: 'login-dialog', disableClose: true });
      this.dialogRef = this.dialog.open(LoginDialogComponent);
      this.dialogRef.componentInstance.dialogConfig = dialogConfig;
    }
  }

  //Open Forgot Password Dialog
  openForgotPasswordDialogService() {
    this.dialogRef = this.dialog.open(ForgotPasswordDialogComponent, { id: 'forgot-dialog', disableClose: true });
  }

  //Open LogOut Dialog
  openLogOutDialogService() {
    this.dialogRef = this.dialog.open(LogoutDialogComponent, { id: 'logout-dialog', disableClose: true, panelClass:"logout--dialog" });
  }

  //Open Start Over Dialog
  openStartOverDialogService() {
    this.dialogRef = this.dialog.open(StartOverComponent, { id: 'startOver-dialog', disableClose: true });
  }

  //Open Succes Change Card Dialog
  openSuccesChangeCardDialogService() {
    this.dialog.open(SuccesChangeCardComponent).afterClosed().subscribe(result => {
      this.router.navigate(['/account/myAcount']);
      this.scroll.scrollToPosition([50, 50]);
    });
  }

}