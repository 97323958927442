import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { DialogService } from 'src/app/services/dialogService/dialog.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-step4-add-eko-bp-card',
  templateUrl: './step4-add-eko-bp-card.component.html',
  styleUrls: ['./step4-add-eko-bp-card.component.less']
})
export class Step4AddEkoBpCardComponent implements OnInit {
  @Input() formDataResponseEKO: any;
  fillingDataFormGroupEKO: FormGroup;
  selected = '';
  errorMassage: any;
  displayLoader: boolean = false;
  disableButton: boolean = false;
  publisher: number = 0;
  language: any;

  constructor(
    private fillingDataFormBuilderEKO: FormBuilder,
    private requestService: RequestService,
    private errorCaseService: ErrorCasesService,
    public router: Router,
    private translate: TranslateService,
    private scroll: ViewportScroller,
    private dialogService: DialogService) {
    this.fillingDataFormGroupEKO = this.fillingDataFormBuilderEKO.group({
      ekoCardNumber: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    //Get The Current Language And Get On Change Lang
    this.language = this.translate.currentLang;
    this.translate.onLangChange.subscribe(obj => {
      this.language = obj.lang;
    });
  }

  //Check Inputs And Send Data Request
  sendCardDataValid() {
    if (!this.fillingDataFormGroupEKO.controls.ekoCardNumber.valid || this.selected == '') {
      this.errorMassage = 'register_error_fields';
      this.scroll.scrollToAnchor('errorMassage');
    }
    else { 
      this.errorMassage = "";
      this.displayLoader = true;
      this.disableButton = true;
      this.scroll.scrollToAnchor('loaderDiv');

      //Check If Publisher Selected For Set Numbers
      if (this.selected == 'EKO') {
        this.publisher = 0;
      }
      else if (this.selected == 'BP') {
        this.publisher = 1;
      }
      const ekoBpValidDataRequest = {
        url: '/main_service/api/ClientPaymentMethods/validate-external-card',
        req: {
          Code: this.fillingDataFormGroupEKO.controls.ekoCardNumber.value,
          ExtCardDEF: this.publisher,
          Mobile: this.formDataResponseEKO.phoneNumber
        }
      }
      this.requestService.mainPostRequest(ekoBpValidDataRequest).subscribe((resp) => {
        if (resp._value.Status) {
          //Check If Data Response Is Null
          if (resp._value.Data == null) {
            this.errorMassage = 'alreadyValidated_verify_sms_false_error';
            this.displayLoader = false;
            this.disableButton = false;
          }
          else {
            this.sendFinalData(resp._value.Data);
          }
        }
        else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
          if (error?.label) {
            this.errorMassage = error?.label;
          }
          this.displayLoader = false;
          this.disableButton = false;
          this.scroll.scrollToAnchor('errorMassage');
        }
      }, throwError => {
        if (throwError) {
          this.displayLoader = false;
        }
      });
    }
  }

  //Send All Data Final
  sendFinalData(cardValidationToken: any) {
    const data = this.formDataResponseEKO;
    const ekoDataRequest = {
      url: '/main_service/api/ClientPaymentMethods/init-setup-insta-final',
      req: {
        ExternalCardBandle: {
          ExternalCode: this.fillingDataFormGroupEKO.controls.ekoCardNumber.value,
          ExternalCardToken: cardValidationToken,
        },
        Language: this.language,
        SetupCardDetails: {
          TIN: data.afm,
          CarPlate: data.carPlate,
          Mobile: data.phoneNumber,
          CompanyName: data.companyName,
          MobileVer: data.smsToken,
          SendDetails: {
            FullName: data.fullName,
            Address: data.address,
            AddressNumber: data.addressNum,
            City: data.city,
            PostalCode: data.postalCode,
          }
        }
      }
    }
    this.requestService.mainPostRequest(ekoDataRequest).subscribe((resp) => {
      if (resp._value.Status) {
        this.displayLoader = false;
        this.disableButton = false;
        this.router.navigate(['/account/welcome']);
      } else {
        //Error Case
        let error = this.errorCaseService.checkErrorCode(resp._value.Error.ErrorCode);
        if (error?.label) {
          this.errorMassage = error?.label;
        }
        this.displayLoader = false;
        this.disableButton = false;
        this.scroll.scrollToAnchor('errorMassage');
      }
    }, throwError => {
      if (throwError) {
        this.displayLoader = false;
      }
    });
  }

  //Open Start Over Dialog
  openStartOverDialog(event: Event) {
    event.preventDefault();
    this.dialogService.openStartOverDialogService();
  }

}