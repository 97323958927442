<div class="container-fluid">
    <!-- Section 1 -->
    <div class="row section-1"></div>
</div>
<!-- Section 2 Form -->
<div class="container-xl">
    <div class="row contact py-5 mx-auto" [formGroup]="contactFormGroup">
        <!-- Title -->
        <div class="col-md-12">
            <h1>{{'contact_form_title' | translate}}</h1>
            <div class="under-line"></div>
            <p class="service-number-word">{{'footer_service_number' | translate}}</p>
            <p class="service-number-numb">(+30) 2106302300</p>
        </div>
        <!-- Name -->
        <div class="col-md-5 mt-5">
            <p>{{'contact_form_first_name' | translate}}</p>
            <mat-form-field appearance="fill">
                <input type="text" [maxLength]="25" matInput formControlName="firstName">
            </mat-form-field>
        </div>
        <div class="col-md-2 mt-5" *ngIf="screenWidth>=768"></div>
        <!-- Last Name -->
        <div class="col-md-5 mt-5">
            <p>{{'contact_form_last_name' | translate}}</p>
            <mat-form-field appearance="fill">
                <input type="text" [maxLength]="30" matInput formControlName="lastName">
            </mat-form-field>
        </div>
        <!-- Phone Number -->
        <div class="col-md-5 mt-5">
            <p>{{'contact_form_phone_number' | translate}}</p>
            <mat-form-field appearance="fill">
                <input type="text" [maxLength]="15" matInput formControlName="phoneNumber">
            </mat-form-field>
        </div>
        <div class="col-md-2 mt-5" *ngIf="screenWidth>=768"></div>
        <!-- Email -->
        <div class="col-md-5 mt-5">
            <p>{{'contact_form_email' | translate}}</p>
            <mat-form-field appearance="fill">
                <input type="email" matInput formControlName="email">
            </mat-form-field>
        </div>
        <!-- Selected -->
        <div class="col-md-12 mt-5">
            <p>{{'contact_form_theme' | translate}}</p>
            <mat-form-field class="aligned-width" appearance="fill">
                <input type="text" matInput formControlName="subject">
            </mat-form-field>
        </div>
        <!-- Message -->
        <div class="col-md-12 mt-5">
            <p>{{'contact_form_massege' | translate}}</p>
            <mat-form-field class="aligned-width" appearance="fill">
                <textarea matInput rows="10" cols="40" [maxLength]="2000" formControlName="massage"></textarea>
            </mat-form-field>
        </div>
        <!-- Check Box -->
        <div class="col-md-12 mt-5 text-center">
            <mat-checkbox formControlName="checkTerms">
                <p [innerHTML]="'contact_form_terms' | translate"></p>
            </mat-checkbox>
        </div>
        <!-- Massage -->
        <div class="col-md-12 mt-5 text-center massage-loader-div" *ngIf="submitMassage || displayLoader">
            <h4 class="submit-massage" *ngIf="submitMassage">{{submitMassage | translate}}</h4>

            <!-- Loader -->
            <div class="loader-position mb-5" *ngIf="displayLoader">
                <mat-spinner class="loader" [diameter]="70" ></mat-spinner>
            </div>
        </div>
        <!-- Submit Button -->
        <div class="col-md-12 mt-5 mb-5 text-center">
            <button type="button" class="btn btn-md" [disabled]="disableButton" (click)="checkForm()">{{'contact_form_button' | translate}}</button>
        </div>
        
    </div>
</div>