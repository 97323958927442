<!-- Dialog Image Bacground -->
<div mat-dialog-title>
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
    <!-- Logo Image -->
    <div class="position-logoImage">
        <img class="mb-3" src="assets/images/logoSignIn.png" />
        <!-- Text -->
        <p class="text">{{'forgot_password_text' | translate}}</p>
    </div>
</div>
<div mat-dialog-content>
    <div class="leable-position" [formGroup]="forgotForm">
        <!-- User Name Input -->
        <mat-form-field class="leable-width" appearance="fill">
            <!-- Input Leabel -->
            <input class="input-size" type="email" matInput placeholder="Email" formControlName="forgotEmail">
        </mat-form-field>
        <!-- Actions -->
        <div class="mt-4">
            <!-- Error Massage -->
            <div class="scroll">
                <p class="error-massage" *ngIf="errorMassage">{{errorMassage | translate}}</p>
                <p class="succses-massage" *ngIf="succsesMassage">{{succsesMassage | translate}}</p>
                <!-- Loader -->
                <div class="loader-position scroll" *ngIf="displayLoader">
                    <mat-spinner class="loader" [diameter]="70"></mat-spinner>
                </div>
                <!-- Submit Button -->
                <button class="submit" [disabled]="disableButton" (click)="checkEmail()">{{'forgot_password_button' | translate}}</button>
            </div>
        </div>
    </div>
</div>