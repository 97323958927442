import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/services/userService/user.service';
import { RequestService } from 'src/app/services/requestService/request.service';
import { ErrorCasesService } from 'src/app/services/errorCaseService/error-cases.service';
import { MatDialog } from '@angular/material/dialog';
import { PayDeptDialogComponent } from '../../../components/dialogs/deptDialogs/pay-dept-dialog/pay-dept-dialog.component';
import { SelectPayEKOCardDeptDialogComponent } from '../../../components/dialogs/deptDialogs/select-pay-eko-card-dept-dialog/select-pay-eko-card-dept-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/dataService/data.service';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';
import { ClientSignInResponse, SubscriptionTypeEnum } from 'src/app/interfaces/Responses/ClientSignInResponse';
import { OldRfidCardResponse } from 'src/app/interfaces/Responses/OldRfidCardResponse';
interface internalOldRfidCardMapping{
  visualCardNumber:string;
  subscriptionType:SubscriptionTypeEnum | null;
  deactivatedDate:Date;
  subId:string;
  typeCard:string;
  isCardInactive : boolean;
  cardVerbal:string;
  dynamicClassNameForDeprecatedCard : string;
}
@Component({
  selector: 'app-my-acount',
  templateUrl: './my-acount.component.html',
  styleUrls: ['./my-acount.component.less'],
})
export class MyAcountComponent implements OnInit, AfterViewInit, OnDestroy {
  dataUserInfos: ClientSignInResponse | null = null;
  activeUser: boolean = false;
  userId: any;
  activeUserString: any;
  activeUserIcon: any;
  cardDetailLast4: any;
  displayLoader: boolean = true;
  errorMassage: any;
  typeCard: any;
  totalDeptCdrs: any;
  cardEKO: boolean = false;
  private _routerSub = new Subscription();
  //Old RFID Cards Implementation
  hasOldCards: boolean = false;
  oldRfidCards: Array<internalOldRfidCardMapping> = new Array<internalOldRfidCardMapping>();

  constructor(
    private userService: UserService,
    private requestService: RequestService,
    public dialog: MatDialog,
    private errorCaseService: ErrorCasesService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private scroller: ViewportScroller,
    private dataService: DataService
  ) {}
  ngOnDestroy(): void {
    this._routerSub.unsubscribe();
  }
  ngAfterViewInit(): void {
    this._routerSub = this.activatedRoute.queryParams.subscribe((params) => {
      setTimeout(() => {
        if (params && params['link']) {
          this.scroller.scrollToAnchor('bankCard');
        }
      }, 100);
    });
  }

  ngOnInit(): void {
    //Get The Parameter Value From Welcome Page

    if (this.dataService.getParamButtons()) {
      this.getInfos();
      this.getRFIDSendCardDetails();
    } else if (this.userService.getUser().FirstTimeInputCard) {
      this.router.navigate(['/account/selectCard']);
    } else {
      //On Init Call The Function
      this.getInfos();
      this.getRFIDSendCardDetails();
    }
  }

  //Get Informations From Info
  getInfos() {
    this.userService.getUserInfo().then(
      (resp: any) => {
        if (resp._value.Status) {
          this.dataUserInfos = resp._value.Data.ClientDetails as ClientSignInResponse;
          if (!this.dataUserInfos){
            this.displayLoader = false;
            this.errorMassage = 'Generic_error';
            return;
          } 
          this.activeUser = this.dataUserInfos.IsActiveSub;
          this.userId = this.dataUserInfos.UserId;
          

          //Type Card

          const rfIdCardType = this.dataUserInfos.SubscriptionType;
          if (this.dataUserInfos.FirstTimeInputCard) {
            this.typeCard = 'cardColleague';
          } else if (rfIdCardType == null) {
            this.typeCard = 'cardColleague';
          } else {
            this.typeCard = this.getTheTypeOfCardFromSubType(rfIdCardType,this.dataUserInfos.IsActiveSub);
            this.cardEKO = rfIdCardType === SubscriptionTypeEnum.EKOCARD;
            // switch (rfIdCardType) {
            //   case SubscriptionTypeEnum.ADHOC:
            //     break;
            //   case SubscriptionTypeEnum.INSTAPAYMENT:
            //     this.typeCard = 'cardELPE';
            //     break;
            //   case SubscriptionTypeEnum.INSTAPAYMENTVOLVO:
            //     this.typeCard = 'cardVovlo';
            //     break;
            //   case SubscriptionTypeEnum.INSTAPAYMENTPEUGEOT:
            //     this.typeCard = 'cardPeugeot';
            //     break;
            //   case SubscriptionTypeEnum.INSTAPAYMENTDS:
            //     this.typeCard = 'cardDS';
            //     break;
            //   case SubscriptionTypeEnum.EKOCARD:
            //     this.typeCard = 'cardELPE';
            //     this.cardEKO = true;
            //     break;
            // }
          }

          //Check The Stats Form User
          this.activeUserString = this.activeUser
            ? 'my_profile_active'
            : 'my_profile_inactive';
          this.activeUserIcon = this.activeUser
            ? 'check_circle_outline'
            : 'highlight_off';

          //Check When CardType Is It And Call Function Form Get Bank Card Details
          if (
            rfIdCardType &&
            (rfIdCardType == SubscriptionTypeEnum.INSTAPAYMENT ||
              rfIdCardType == SubscriptionTypeEnum.INSTAPAYMENTVOLVO ||
              rfIdCardType == SubscriptionTypeEnum.INSTAPAYMENTPEUGEOT ||
              rfIdCardType == SubscriptionTypeEnum.INSTAPAYMENTDS)
          ) {
            this.displayLoader = true;
            this.cardDetails();
          }

          //Check If User Is Inactive And If Have Dept Record
          if (!this.dataUserInfos.FirstTimeInputCard &&
              !this.dataUserInfos.IsActiveSub) {
            this.displayLoader = true;
            this.getTotalDept();
          }

          //Check If User Is Inactive And Have Normal Card Display Gray Image
          if (!this.dataUserInfos.IsActiveSub && ( rfIdCardType == SubscriptionTypeEnum.INSTAPAYMENT || rfIdCardType == SubscriptionTypeEnum.EKOCARD)) {
            this.typeCard = 'cardColleague';
          } 

          //Old Rfid Cards Implementation
          this.hasOldCards = this.dataUserInfos.HasOldCards ?? false;
          if (this.hasOldCards && this.dataUserInfos.DeprecatedCards?.length) {
            this.oldRfidCards = this.dataUserInfos.DeprecatedCards.map(
              (card: OldRfidCardResponse) => {
                const isCurrentCardDeactivated  = !!((card && card.DeactivationDate) ?? false);
                const typeOfCardDisplay = this.getTheTypeOfCardFromSubType(card.SubscriptionType,!isCurrentCardDeactivated)
                return {
                  visualCardNumber: card.VisualCardNumber,
                  subscriptionType: card.SubscriptionType,
                  deactivatedDate: card.DeactivationDate ?? new Date(),
                  subId: card.SubID ?? 'unknown_subscription_id',
                  typeCard: typeOfCardDisplay,
                  isCardInactive: isCurrentCardDeactivated,
                  cardVerbal: isCurrentCardDeactivated ? 
                                  'my_profile_inactive'
                                  : 'my_profile_active',
                  dynamicClassNameForDeprecatedCard : typeOfCardDisplay
                };
              }
            ) ?? new Array<internalOldRfidCardMapping>();
          }
          this.displayLoader = false;
        } else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(
            resp._value.Error.ErrorCode
          );
          if (error?.label) {
            this.errorMassage = error?.label;
          }
          this.displayLoader = false;
        }
      },
      (throwError) => {
        if (throwError) {
          this.displayLoader = false;
        }
      }
    );
  }

  getTheTypeOfCardFromSubType(subType: SubscriptionTypeEnum | null,isSubscriptionActive:boolean = true) {
    if (!subType) return 'cardColleague';
    switch (subType) {
      case SubscriptionTypeEnum.ADHOC:
        return isSubscriptionActive ? 'cardColleague': 'cardInactive';
      case SubscriptionTypeEnum.INSTAPAYMENT:
        return isSubscriptionActive ? 'cardELPE' : 'cardInactive' ;
      case SubscriptionTypeEnum.INSTAPAYMENTVOLVO:
        return isSubscriptionActive ? 'cardVovlo' : 'cardVovlo';
      case SubscriptionTypeEnum.INSTAPAYMENTPEUGEOT:
        return isSubscriptionActive ? 'cardPeugeot' : 'cardPeugeot';
      case SubscriptionTypeEnum.INSTAPAYMENTDS:
        return isSubscriptionActive ? 'cardDS' : 'cardDS';
      case SubscriptionTypeEnum.EKOCARD:
        return isSubscriptionActive ? 'EKO_Card' : 'EKO_Card' ;
      default:
        return isSubscriptionActive ? 'cardColleague': 'cardColleague';
    }
  }
  getRFIDSendCardDetails() {
    this.userService
      .getRFIDSendCardInformation()
      .then((response) => {
        //console.log("Response getRFIDSendCardDetails",response.Data);
      })
      .catch((error) => {
        console.log('Error getRFIDSendCardDetails', error);
      });
  }
  //Bank Card Request Take Informations
  cardDetails() {
    const cardDetailsRequest = {
      url: '/main_service/api/UserInfos/card-details/' + this.userId,
    };
    this.requestService.mainGetRequest(cardDetailsRequest).subscribe(
      (resp) => {
        if (resp._value.Status) {
          this.cardDetailLast4 = resp._value.Data.Last4;
          this.displayLoader = false;
        } else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(
            resp._value.Error.ErrorCode
          );
          if (error?.label) {
            this.errorMassage = error?.label;
          }
          this.displayLoader = false;
        }
      },
      (throwError) => {
        if (throwError) {
          this.displayLoader = false;
        }
      }
    );
  }

  //Request For Take Total Dept
  getTotalDept() {
    const historyRequest = {
      url: '/main_service/api/UserInfos/history',
    };
    this.requestService.mainGetRequest(historyRequest).subscribe(
      (resp) => {
        if (resp._value.Status) {
          this.totalDeptCdrs = resp._value.Data.DEPTCDRS.length;
        } else {
          //Error Case
          let error = this.errorCaseService.checkErrorCode(
            resp._value.Error.ErrorCode
          );
          if (error?.label) {
            this.errorMassage = error?.label;
          }
        }
        this.displayLoader = false;
      },
      (throwError) => {
        if (throwError) {
          this.displayLoader = false;
        }
      }
    );
  }

  //Open Dept Dialog And After Close Call Function
  payDeptCheck() {
    //Check If Dept Is Over From 0 And The Subscription Type Is Different From EKO Card
    if (this.dataUserInfos && this.dataUserInfos.SubscriptionType) {
      if (
        this.totalDeptCdrs > 0 &&
        this.dataUserInfos.SubscriptionType != SubscriptionTypeEnum.EKOCARD
      ) {
        const matDialogId = this.dialog.getDialogById('payDept-dialog');
        if (!matDialogId) {
          const dialogRef = this.dialog.open(PayDeptDialogComponent, {
            id: 'payDept-dialog',
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result.data.success) {
              this.getInfos();
            }
          });
        }
      }
      //Check If Dept Is Over From 0 And The Subscription Type Is Same With EKO Card
      else if (
        this.totalDeptCdrs > 0 &&
        this.dataUserInfos.SubscriptionType == SubscriptionTypeEnum.EKOCARD
      ) {
        //Open Selected Pay Method For EKO Card
        const matDialogId = this.dialog.getDialogById('selected-EKO-dialog');
        if (!matDialogId) {
          const dialogRef = this.dialog.open(
            SelectPayEKOCardDeptDialogComponent,
            { id: 'selected-EKO-dialog', disableClose: true }
          );
        }
      }
    } else {
      this.errorMassage = 'Generic_error';
    }
  }
}